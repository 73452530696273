import { Component, OnInit, Injector, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

import { NewLovRequestComponent } from '../new-lov-request/new-lov-request.component';
import { EditLovRequestComponent } from '../edit-lov-request/edit-lov-request.component';
import { NewUserRequestComponent } from '../new-user-request/new-user-request.component';
import { EditUserRequestComponent } from '../edit-user-request/edit-user-request.component';
import { NewMiscRequestComponent } from '../new-misc-request/new-misc-request.component';

import { createInjector } from '../../../container-data';
import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { StorageService } from '../../../services/storage.service';
import { Router } from '@angular/router';
import {BulkExportComponent} from '../bulk-export/bulk-export.component';

interface MenuItem {
    icon: string;
    name: string;
    component: ComponentType<any>;
}

@Component({
    selector: 'app-new-request-menu',
    templateUrl: './new-request-menu.component.html',
    styleUrls: ['./new-request-menu.component.sass']
})
export class NewRequestMenuComponent implements OnInit {

    @Input() open = false;
    @Input() top;
    @Input() left;
    isCreativeUser: boolean;
    isAdminUser: boolean;
    userInfo;

    @Output() closeEmitter = new EventEmitter();

    menuItems: MenuItem[] = [
        { icon: 'assets/icons/manage-requests/Icon-LOVs-v1.0.svg', name: 'New LOV', component: NewLovRequestComponent },
        { icon: 'assets/icons/manage-requests/Icon-EditLOV-v1.0.svg', name: 'Edit LOV', component: EditLovRequestComponent },
        { icon: 'assets/icons/manage-requests/Icon-User-v1.0.svg', name: 'New User', component: NewUserRequestComponent },
        { icon: 'assets/icons/manage-requests/Icon-EditUser-v1.0.svg', name: 'Edit User', component: EditUserRequestComponent },
        // { icon: 'assets/icons/manage-requests/Icon-Misc-v1.0.svg', name: 'New Misc', component: NewMiscRequestComponent },
         { icon: 'assets/icons/taskbar/download.svg', name: 'Bulk Export', component: BulkExportComponent }
    ];

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private service: FetchApiDataService,
        private storage: StorageService,
        private router: Router
    ) {
        if(this.router.url.includes('cmaps')) {
            this.menuItems = [];
            this.menuItems = [
                { icon: 'assets/icons/manage-requests/Icon-User-v1.0.svg', name: 'New User', component: NewUserRequestComponent },
                { icon: 'assets/icons/manage-requests/Icon-EditUser-v1.0.svg', name: 'Edit User', component: EditUserRequestComponent }
            ];
        }
    }

    ngOnInit() {
        // load all lov values which the popups will use. This is done here to avoid repeating
        // this in all model component. In those, just get the values from StorageService.
        this.userInfo = this.storage.getStorage('userdata');
        if (this.userInfo !== null) {
            //    User roles length
            this.isCreativeUser = this.userInfo['allRolesId'].indexOf(3) != -1;    // 3 is Creative User
            this.isAdminUser = this.userInfo['allRolesId'].indexOf(2) != -1;  // 2 is Admin
        }

        if (this.isCreativeUser) {
            this.menuItems.splice(2, 2);
        }

        if (!this.storage.getStorage('lovs')) {
            this.service.getAllLovs().subscribe(
                (data) => {
                    this.storage.setStorage('lovs', data['lovs']);
                }
            );
        }
    }

    // function purpose is undetermined
    openDialog(component: ComponentType<any>) {
        const positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });
        const dialog = new ComponentPortal(component, null, createInjector({ dialogRef: overlayRef }, this.injector));
        overlayRef.attach(dialog);
        overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());
    }

    /** Close Menu */
    closeMenu() {
        this.closeEmitter.emit(true);
        this.open = false;
    }

    /** Escape key  */
    @HostListener('document:keyup', ['$event'])
    escKeyEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.closeMenu();
        }
    }

    /** Outside Click */
    @HostListener('document:click', ['$event'])
    clickout(event) {
        const classList = event.target.parentNode.classList ? event.target.parentNode.classList.contains('request-lov') : false;
        if (!(classList|| event.target.className === 'request-lov')) {
            this.closeMenu();
        }
    }
}
