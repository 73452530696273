import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { StorageService } from 'src/app/metadata-view/utilities/storage.service';
import { dropdownSorter } from '../../../validators';

@Component({
  selector: 'app-edit-multiple-lov',
  templateUrl: './edit-multiple-lov.component.html',
  styleUrls: ['./edit-multiple-lov.component.scss']
})
export class EditMultipleLovComponent implements OnInit {

  @Input() lovType: any;


  constructor(
    private storage: StorageService,
    private fb: FormBuilder
  ) { }


  lovs: any[];
  message: string;
  bindLabel: string;
  type: any;
  lovMapping;
  lovLabel;
  lovLabelId;
  values;
  addedLovVal;
  lovMultipleMapping = [];
  isMultipleMapping;
  multipleMapKeys;
  form: FormGroup;

  ngOnInit(): void {
    this.lovs = this.storage.getStorage('lovs');
    this.isMultipleMapping = this.lovType['is_mapping'];
    
      let testMap = [];
      let lovLabel1;
      let lovId1;
      let lovMapping1;
      let selectedSplitLov = this.lovType.lov_name.split("/");
      for (let i = 0; i <= selectedSplitLov.length - 1; i++) {
        for (const [key, val] of Object.entries(this.lovs)) {
          let currentLovKey = key.toUpperCase().substring(3);
          let testStr = selectedSplitLov[i].split(" ").join("");
          if (testStr === 'INDICATION') {
            testStr = testStr.concat('/DISEASESTATE');
          }



          if (testStr === currentLovKey) {

            for (const [key1, val1] of Object.entries(val[0])) {
              if (key1.includes('_name')) {
                lovLabel1 = key1;
                lovMapping1 = val.sort(dropdownSorter(key1));
              }
              if (key1.includes('_id')) {
                lovId1 = key1;
              }

            }
          }
        }
        let obj = {
          name: selectedSplitLov[i],
          lovLabel: lovLabel1,
          lovId: lovId1,
          mapping: lovMapping1
          //   map: val.sort(dropdownSorter(key1))
        }
        this.lovMultipleMapping.push(obj);
      }
      if (this.isMultipleMapping) {
        this.setDynamicFormControls();
    }

     this.setDynamicFormControls();

    
  }
  setDynamicFormControls() {
    let controls = {}
    this.lovMultipleMapping.forEach(element => {
      controls[element.name] = [null, Validators.required];
    })
    this.form = this.fb.group(controls);
  }

  get value() {
    const multipleLov = [];
    let multipleMapKey;
    let multipleMapKeys = [];
    for (let i = 0; i <= this.lovMultipleMapping.length - 1; i++) {
      multipleMapKeys.push(this.form.get(this.lovMultipleMapping[i].name).value);
    }

    multipleMapKeys.map(mapKeys => {
      for (let key in mapKeys) {
        if (key.includes('id')) {
          multipleMapKey = key;
        }
      }
      let multipleMapPayload = {};
      multipleMapPayload[multipleMapKey] = mapKeys[multipleMapKey]
      multipleLov.push(multipleMapPayload);
    });
    return multipleLov;


  }
  editMultipleLov(lov, index) {
    let cascadingMap = this.lovs[this.lovType.alias_name];
    let cascadingKey;
    for (let item in lov) {
      if (item.includes('id')) {
        cascadingKey = item;
      }
    }
    let filterData = cascadingMap.filter(element => element[cascadingKey] === parseInt(lov[cascadingKey], 10));
    let currentLovKey = this.lovMultipleMapping[index + 1].lovId;
    this.lovMultipleMapping[index + 1]['mapping'] = this.lovMultipleMapping[index + 1]['mapping'].filter(ele =>
      ele[currentLovKey] === filterData[0][currentLovKey]
    );
  }

}
