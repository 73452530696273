import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-common-modal',
    templateUrl: './common-modal.component.html',
    styleUrls: ['./common-modal.component.sass']
})
export class CommonModalComponent implements OnInit {

    @Input() message: string;
    @Output() closeModal = new EventEmitter();


    constructor() {
    }

    ngOnInit() {
    }


    closeOpenModal() {
        this.closeModal.emit();
    }

}
