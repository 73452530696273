import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMyDpOptions} from 'mydatepicker';
import {HttpService} from '../../services/http.service';
import {StorageService} from '../../services/storage.service';
import {dropdownSorter} from '../../validators';
import {Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchApiDataService } from '../../../metadata-view/utilities/fetch-api-data.service';

import {Router} from '@angular/router';
import {SharedStore} from '../../services/shared-store';

@Component({
    selector: 'app-mdp-advance-search',
    templateUrl: './advance-search.component.html',
    styleUrls: ['./advance-search.component.sass']
})
export class AdvanceSearchComponent implements OnInit {

    @Input() show = false;
    @Output() closeEmitter = new EventEmitter();
    @Output() searchEmitter = new EventEmitter();
    isLoading;
    geography_list;
    indication_list;
    vendor_list;
    business_unit_list;
    therapy_area_list;
    customer_type_list;
    dept_list;
    customer_seg_list;
    brand_list;
    userInfo = {};
    dataTypeArray = [];
    searchInfoJson;
    userData;
    userName;
    tactic_status_list;
    execution_path_list;
    lov_section_list = ['LOV Section 1', 'LOV Section 2', 'LOV Section 3', 'LOV Section 4'];

    wave_to_date;
    wave_from_date;
    selectedStatus;
    noFilterSelected;
    programFilter = true;
    campaignFilter = true;
    tacticFilter = true;

    // Advanced Search modal dropdown selections
    selectedGeo = undefined;
    selectedGbu = undefined;
    selectedCustomer = undefined;
    selectedExecutionPath = undefined;
    selectedCustomerSegment = undefined;
    selectedBrand = undefined;
    selectedIndication = undefined;
    selectedDept = undefined;
    selectedVendor = undefined;
    selectedTherapy = undefined;
    selectedTacticId = undefined;
    selectedJobId = undefined;

    tactic_statuses = [];
    tactic_id_list = [];
    job_id_list = [];

    searchError;
    OuterModel;
    InnerModel;
    bodyTag;
    fromDate;
    message;
    modal;
    errorMessage;
    flag = 0;
    searchText;
    searchNames = {};
    toDate;

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'mm-dd-yyyy',
    };

    private ngUnsubscribe = new Subject<boolean>();

    constructor(private http_service: HttpService,
                private localStorage: StorageService,
                private _sharedStore: SharedStore,
                public router: Router,
                public _apiService : FetchApiDataService) {
    }

    ngOnInit() {
        this.userInfo = this.localStorage.getStorage('userdata');
        // this.genericService.currentData.takeUntil(this.ngUnsubscribe).subscribe(filterType => {
        //   // this.filterType = row;
        //   this.syncAdvSearchModal(filterType);
        // });
    }


    // Not using this function
    syncAdvSearchModal(filterType) {
        switch (filterType) {
            case 'geography':
                this.selectedGeo = undefined;
                break;
            case 'gbu':
                this.selectedGbu = undefined;
                break;
            case 'therapy_area':
                this.selectedTherapy = undefined;
                break;
            case 'status':
                this.selectedStatus = undefined;
                break;
            case 'brand':
                this.selectedBrand = undefined;
                break;
            case 'customer':
                this.selectedCustomer = undefined;
                break;
            case 'execution path':
                this.selectedExecutionPath = undefined;
                break;
            case 'customer_segment':
                this.selectedCustomerSegment = undefined;
                break;
            case 'vendor':
                this.selectedVendor = undefined;
                break;
            case 'indication':
                this.selectedIndication = undefined;
                break;
            case 'department':
                this.selectedDept = undefined;
                break;
            case 'all':
                this.clearFields();
                break;
        }
    }


    // function to clear fields
    clearFields() {
        this.selectedGeo = undefined;
        this.selectedGbu = undefined;
        this.selectedBrand = undefined;
        this.selectedIndication = undefined;
        this.selectedDept = undefined;
        this.selectedTherapy = undefined;
        this.selectedCustomer = undefined;
        this.selectedExecutionPath = undefined;
        this.selectedCustomerSegment = undefined;
        this.selectedStatus = undefined;
        this.fromDate = undefined;
        this.toDate = undefined;
        this.errorMessage = undefined;
    }

    // Not using this function
    ngAfterViewInit() {
        if (this.userInfo !== null) {
            if (Object.keys(this.userInfo).length !== 0) {
                this.userData = {
                    'userRole': this.userInfo['allRolesId'][0],
                    'geo_id': this.userInfo['allGeoId'],
                    'brand_id': this.userInfo['allBrandId'],
                    'indication_id': this.userInfo['allIndicationId'],
                    'profile_id': this.userInfo['profile_id']
                };

                // Prepare searchInfoJson with default values
                this.searchInfoJson = {
                    'type': this.dataTypeArray,
                    'geo_id': this.userInfo['allGeoId'],
                    // 'gbu_id': this.userInfo['allGbuId'][0],
                    'brand_id': this.userInfo['allBrandId'],
                    'indication_id': this.userInfo['allIndicationId'],
                    'vendor_id': this.userInfo['allVendorId'][0],
                    'customer_type': this.selectedCustomer,
                    'execution_path' : this.selectedExecutionPath,
                    'customer_segment': this.selectedCustomerSegment,
                    'status': this.selectedStatus,
                    'wave_send_from_date': this.wave_from_date,
                    'wave_send_to_date': this.wave_to_date,
                    'userRole': this.userInfo['allRolesId'][0],
                    'department': this.selectedDept
                };
            }
        }
        this.loadAdvDropDown();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    // load the dropdown data in the advanced search modal
    loadAdvDropDown() {
      let dropwDownList =   this.http_service.post('loadDropdownList', this.userData).
        pipe(takeUntil(this.ngUnsubscribe));
        dropwDownList.subscribe(
            d => {
                this.geography_list = d['data'].geo;
                this.brand_list = d['data'].brand.sort(dropdownSorter('brand_name'));
                this.indication_list = d['data'].indication.sort(dropdownSorter('indication_name'));
                this.therapy_area_list = d['data'].therapy;
                this.vendor_list = d['data'].vendor.sort(dropdownSorter('vendor_name'));
                this.business_unit_list = d['data'].gbu.sort(dropdownSorter('gbu_name'));
                this.dept_list = d['data'].department.sort(dropdownSorter('department_name'));
                this.customer_type_list = d['data'].customertype.sort(dropdownSorter('customertype_name'));
                this.execution_path_list = d['data'].execution_path.sort(dropdownSorter('execution_path_name'));
                this.tactic_status_list = d['data'].status.sort(dropdownSorter('status_name'));
                // this.tactic_id_list = d['data'].tactic_ids.sort(dropdownSorter('tactic_description'));
                this.job_id_list = d['data'].job_ids;
                this.tactic_statuses = this.tactic_status_list;
                setTimeout(() => {
                    this.isLoading = false;
                });
            }
        );
    }

    convertToArrayObj(arrayArray) {
        let JobIdList = [];
        for (let j = 0; j < arrayArray.length; j++) {
            const item = arrayArray[j];
            if (item && item[0] && item[1]) {
                let obj = {};
                obj = {
                    'job_name': item[0],
                    'job_id': item[1]
                };
                JobIdList.push(obj);
            }
        }
        return JobIdList;
    }

    fetchName(lists, name, id, model) {
        for (const list of lists) {
            if (list[id] === model) {
                this.searchNames[name] = list[name];
            }
            if(name == 'exp_code' && list === model){
                this.searchNames[name] = list;
            }
        }
    }

    // function to close modal
    closeModal() {
        this.show = false;
        this.closeEmitter.emit(true)
    }

    // function to validate Date
    validateDate() {
        if (this.fromDate >= this.toDate) {
            this.flag = 1;
            this.errorMessage = 'Please enter valid date end date.';
        } else if (this.fromDate === undefined) {
            this.flag = 1;
            this.errorMessage = 'Please select the Start Date first.';
        } else {
            this.flag = 0;
            if (this.errorMessage !== undefined) {
                this.errorMessage = undefined;
            }
        }
    }

    toDte(date) {
        this.wave_to_date = date;
        this.toDate = date.epoc;
        this.validateDate();
    }

    fromDte(date) {
        this.fromDate = date.epoc;
        this.wave_from_date = date;
        this.validateDate();
    }

    // function to format Date
    formatDate(date) {
        if (date) {
            let day;
            let month;
            let year;
            day = date.day;
            month = date.month;
            year = date.year;
            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = '0' + month;
            }
            let d = '';
            d = year + '-' + month + '-' + day;
            return d;
        }
    }

    fetchAdvancedSearchValue(str) {
        // this.genericService.fetchAdvancedSearchValue(str);
    }

    // search functionality
    searchByValues() {
        this.searchEmitter.emit(true);
        // Data type filter
        this.dataTypeArray = [];

        function remove(arr, what) {
            let found = arr.indexOf(what);

            while (found !== -1) {
                arr.splice(found, 1);
                found = arr.indexOf(what);
            }
        }

        if (this.programFilter) {
            this.dataTypeArray.push('program');
        } else {
            remove(this.dataTypeArray, 'program');
        }
        if (this.campaignFilter) {
            this.dataTypeArray.push('campaign');
        } else {
            remove(this.dataTypeArray, 'campaign');
        }
        if (this.tacticFilter) {
            this.dataTypeArray.push('tactic');
        } else {
            remove(this.dataTypeArray, 'tactic');
        }

        this.fetchAdvancedSearchValue('search-button');

        this.searchInfoJson['type'] = this.dataTypeArray;

        // Save the names from the dropdowns
        // Geography
        if (this.selectedGeo !== undefined) {
            this.searchInfoJson['geo_id'] = [this.selectedGeo];
        }
        // GBU
        if (this.selectedGbu !== undefined) {
            this.searchInfoJson['gbu_id'] = this.selectedGbu;
        }
        // Brand
        if (this.selectedBrand !== undefined) {
            this.searchInfoJson['brand_id'] = [this.selectedBrand];
        }
        // Indication
        if (this.selectedIndication !== undefined) {
            this.searchInfoJson['indication_id'] = [this.selectedIndication];
        }
        // Therapy Area
        if (this.selectedTherapy !== undefined) {
            this.searchInfoJson['therapy_id'] = this.selectedTherapy;
        }
        // Customer Type
        if (this.selectedCustomer !== undefined) {
            this.searchInfoJson['customer_type'] = this.selectedCustomer;
        }
        // Execution Path
        if (this.selectedExecutionPath !== undefined) {
            this.searchInfoJson['execution_path_name'] = this.selectedExecutionPath;
        }
        // Customer Segment
        if (this.selectedCustomerSegment !== undefined) {
            this.searchInfoJson['customer_segment'] = this.selectedCustomerSegment;
        }
        // Tactic Status
        if (this.selectedStatus !== undefined) {
            this.searchInfoJson['status'] = this.selectedStatus;
        }
        // Start Date
        if (this.wave_from_date !== undefined) {
            this.searchInfoJson['wave_send_from_date'] = this.formatDate(this.wave_from_date.date);
            this.searchNames['wave_send_from_date'] = this.formatDate(this.wave_from_date.date);
        }
        // To Date
        if (this.wave_to_date !== undefined) {
            this.searchInfoJson['wave_send_to_date'] = this.formatDate(this.wave_to_date.date);
            this.searchNames['wave_send_to_date'] = this.formatDate(this.wave_to_date.date);
        }
        // Vendor
        if (this.selectedVendor !== undefined) {
            this.searchInfoJson['vendor_id'] = this.selectedVendor;
        }
        // Department
        if (this.selectedDept !== undefined) {
            this.searchInfoJson['department_id'] = this.selectedDept;
        }
        this.searchInfoJson['job_id'] = this.selectedJobId;
        // After clicking on the cross icon in the ng select the ng Model variable becomes null.
        // Following is to handle the same
        // Geography
        if (this.selectedGeo === null) {
            this.searchInfoJson['geo_id'] = this.userInfo['allGeoId'];
            this.selectedGeo = undefined;
            this.searchNames['geo_name'] = undefined;
        }
        // GBU
        if (this.selectedGbu === null) {
            this.searchInfoJson['gbu_id'] = ( this.userInfo['allGbuId'] != undefined ) ? this.userInfo['allGbuId'][0] : '';
            this.selectedGbu = undefined;
            this.searchNames['gbu_name'] = undefined;
        }
        // Brand
        if (this.selectedBrand === null) {
            this.searchInfoJson['brand_id'] = this.userInfo['allBrandId'];
            this.selectedBrand = undefined;
            this.searchNames['brand_name'] = undefined;
        }
        // Indication
        if (this.selectedIndication === null) {
            this.searchInfoJson['indication_id'] = undefined;
            this.selectedIndication = undefined;
            this.searchNames['indication_name'] = undefined;
        }
        // Status
        if (this.selectedStatus === null) {
            this.searchInfoJson['status'] = undefined;
            this.selectedStatus = undefined;
            this.searchNames['status'] = undefined;
        }
        // Customer
        if (this.selectedCustomer === null) {
            this.searchInfoJson['customer_type'] = undefined;
            this.selectedCustomer = undefined;
            this.searchNames['customertype_name'] = undefined;
        }
         // Execution Path
        if (this.selectedExecutionPath === null) {
            this.searchInfoJson['execution_path_name'] = undefined;
            this.selectedExecutionPath = undefined;
            this.searchNames['execution_path_name'] = undefined;
        }
        // Customer Segment
        if (this.selectedCustomerSegment === null) {
            this.searchInfoJson['customer_segment'] = undefined;
            this.selectedCustomerSegment = undefined;
            this.searchNames['customersegment_name'] = undefined;
        }
        // Therapy
        if (this.selectedTherapy === null) {
            this.searchInfoJson['therapy_id'] = undefined;
            this.selectedTherapy = undefined;
            this.searchNames['therapy_name'] = undefined;
        }
        // Vendor
        if (!this.selectedVendor) {
            this.searchInfoJson['vendor_id'] = undefined;
            this.selectedVendor = undefined;
            this.searchNames['vendor_name'] = undefined;
        }
        // Department
        if (this.selectedDept === null) {
            this.searchInfoJson['department_id'] = undefined;
            this.selectedDept = undefined;
            this.searchNames['department_name'] = undefined;
        }

        if (this.selectedJobId === null) {
            this.searchInfoJson['job_id'] = undefined;
            this.selectedJobId = undefined;
            this.searchNames['job_name'] = undefined;
        }

        if (this.searchInfoJson['vendor_id'] == null) {
            this.searchInfoJson['vendor_id'] = undefined;
        }

        // Start Date
        if (this.wave_from_date === undefined) {
            this.searchInfoJson['wave_send_from_date'] = undefined;
            this.wave_from_date = undefined;
            this.searchNames['wave_send_from_date'] = undefined;
        }
        // To Date
        if (this.wave_to_date === undefined) {
            this.searchInfoJson['wave_send_to_date'] = undefined;
            this.searchNames['wave_send_to_date'] = undefined;
        }
        this.searchInfoJson['exp_code'] =( this.selectedTacticId != undefined ) ? this.selectedTacticId : null;
        this.closeEmitter.emit(true);
        SharedStore.IfAdvanceClicked = true;
        if (!this.router.url.includes('/metadata-view/search-results')) {
            this.router.navigate(['/metadata-view/search-results']).then(result => {
                setTimeout(() => {
                    this._sharedStore.passAdvanceSearchJson(this.searchInfoJson, this.searchNames, 'advancedClicked')
                }, 1000);
            });
        } else {
            this._sharedStore.passAdvanceSearchJson(this.searchInfoJson, this.searchNames, 'advancedClicked');
        }
    }
    getTacticCodes(){
        if(this.selectedJobId){
            let i = this.job_id_list.findIndex(dta => dta?.approved_job_id == this.selectedJobId)
            let payload = this.job_id_list[i];
            this._apiService.getTacticCodes(payload).subscribe(result => {
                this.tactic_id_list = result?.exp_code.filter(dt => dt != null );
            });
        }else{
            this.selectedTacticId = undefined;
        }
    }
}
