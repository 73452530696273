/** All API calls will come here */
import {Injectable} from '@angular/core';
import {HttpService} from '../../../services/http.service';

/**  Import all models */

/**  */

@Injectable({
    providedIn: 'root'
})
export class FetchApiDataService {

    constructor(private _http: HttpService) {

    }

    /**
     * Create User Add Request
     * @param payload - payload
     */
    createUserAddRequest(payload) {
        return this._http.post('createUserAddRequest', payload)
    }

    /**
     * New Request Notification
     * @param payload - payload
     */
    newRequestNotification(payload) {
        return this._http.post('newRequestNotification', payload)
    }

    /**
     * Create Lov Add Request
     * @param payload - payload
     */
    createLovAddRequest(payload) {
        return this._http.post('createLovAddRequest', payload);
    }

    /**
     * Getting all lovs
     */
    getAllLovs() {
        return this._http.get('getAllLovs');
    }

    /**
     * createLovEditRequest
     * @param payload - payload
     */
    createLovEditRequest(payload) {
        return this._http.post('createLovEditRequest', payload);
    }

    /**
     * get user details
     */
    getUserDetails(profile_id) {
        return this._http.get('getUserDetails', profile_id);
    }

    /**
     * Create User Edit REquest
     * @param payload - payload
     */
    createUserEditRequest(payload) {
        return this._http.post('createUserEditRequest', payload);
    }

    /**
     * Get All users
     */
    getAllUsers(payload) {
        return this._http.post('allUsers', payload);
    }

    /** Get all campaigns */
    getAllCampaigns(payload = {}) {
        return this._http.post('getCampaignsWithProgramDetails', payload);
    }

    /** createMiscRequest */
    createMiscRequest(payload) {
        return this._http.post('miscAddRequest', payload);
    }

    /** createMiscRequest */
    ApproveMiscRequest(payload) {
        return this._http.post('miscApproveRequest', payload);
    }
}
