import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject,Observable} from 'rxjs';


@Injectable()
export class SharedStore {

    public static IfAdvanceClicked = false;
    public static searchString = new BehaviorSubject('');
    public static infoJSON = new Subject();
    public static advSearch = new Subject();

    // Search String
    getSearchString():Observable<any> {
        return SharedStore.searchString.asObservable();
    }

    setSearchString(value) {
        SharedStore.searchString.next(value);
    }

    getPassAdvanceSearchJson():Observable<any> {
        return  SharedStore.advSearch.asObservable();
        // return  SharedStore.infoJSON.asObservable();
    }

    passAdvanceSearchJson(infoJSON, searchNames ,type) {
        SharedStore.advSearch.next({searchNames: searchNames,infoJSON:infoJSON,type:type});
        // SharedStore.searchNames.next({infoJSON: infoJSON});
    }
}
