<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-container">
                    <header>
                        <img class="icon" src="" alt="">
                        <h1>Request Restore</h1>
                        <img class="btn-close" src="assets/icons/shared/close.svg" alt="close" (click)="close()">
                    </header>
                
                    <main>
                        <p>Please select Campaign/Time deleted in past 30 days</p>
                
                        <form [formGroup]="form">
                            <div class="form-row">
                                <div class="form-field-container">
                                    <label for="">Select Program:</label>
                                    <ng-select [items]="programs" placeholder="Select LOV Type" appendTo="body" clearable="false" closeOnSelect="true" bindLabel="" bindValue="" formControlName="program"></ng-select>
                                </div>
                
                                <div class="form-field-container">
                                    <label for="">Select Campaign:</label>
                                    <ng-select [items]="campaigns" placeholder="Select LOV Type" appendTo="body" clearable="false" closeOnSelect="true" bindLabel="" bindValue="" formControlName="campaign"></ng-select>
                                </div>
                            </div>
                        </form>
                
                        <div style="text-align: center">
                            <button type="button" (click)="sendRequest()">Send Request</button>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>
