<div class="form-field-container">
    <ng-container [formGroup]="form">
        <div *ngIf="isMultipleMapping;else singleLov"
            style="display:flex;flex-wrap: wrap;justify-content: space-between;margin: 0 20px;">
            <div *ngFor="let item of lovMultipleMapping" style="width: 40%;margin-top:10px">
                <label>{{ item.name | titlecase}}:</label>
                <ng-select [placeholder]="item.name" [items]="item.mapping" [addTag]="true"[selectOnTab]="true" [bindLabel]="item.lovLabel" [formControlName]="item.name"></ng-select>
                <ng-container
                    *ngIf="form.get(item.name).invalid && (form.get(item.name).dirty || form.get(item.name).touched) && !message">
                    <span class="form-error-message" *ngIf="form.get(item.name).errors.required">This field is
                        required.</span>
                </ng-container>
            </div>
        </div>

        <ng-template #singleLov>
            <label>{{ lovType?.lov_name | titlecase}}:</label>
            <ng-select placeholder="Enter only new values" [items]="lovMapping" [addTag]="true" [multiple]="true" [selectOnTab]="true" [bindLabel]="lovLabel" formControlName="control"
                (add)="checkValue($event)"></ng-select>
            <span class="duplicate-message" *ngIf="message">{{ message }}</span>
            <ng-container
                *ngIf="form.get('control').invalid && (form.get('control').dirty || form.get('control').touched) && !message">
                <span class="form-error-message" *ngIf="form.get('control').errors.required">This field is
                    required.</span>
            </ng-container>
        </ng-template>
    </ng-container>
</div>