import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as PptxGenJS from 'pptxgenjs-angular';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const pptx = new PptxGenJS();
const slide = pptx.addNewSlide();

@Injectable({providedIn: 'root'})
export class CommonMethods {

    modal;
    body;
    value;
    userInfo;
    private dataSource = new BehaviorSubject<string>('');
    currentData = this.dataSource.asObservable();
    emitUpdate = new EventEmitter();
    searchJSON: Observable<{}>;
    searchNames: Observable<{}>;
    isAdvancedSearchModal;

    // date
    setDate = {date: {year: 1970, month: 1, day: 1}};

    constructor() {
    }

    /** download pdf */
    dowwnloadPdf() {
        try {
            const elem = document.getElementById('month-view-snap-days');
            html2canvas(elem, {allowTaint: false, useCORS: false}).then((canvas) => {
                // Few necessary setting options
                const imgWidth = 208;
                const imgHeight = canvas.height * imgWidth / canvas.width;

                const contentDataURL = canvas.toDataURL('image/jpeg', 1.0);
                const pdf = new jspdf('p', 'mm', 'a4', true); // A4 size page of PD
                const position = 0;

                /** saving to pdf  */
                pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight);
                pdf.save('snapshot.pdf'); // Generated PDF
            });
        } catch (error) {
            console.log(error);
        }
    }

    /** download ppt  */
    downloadPPT() {
        try {
            const elem = document.getElementById('month-view-snap-days');
            html2canvas(elem, {allowTaint: false}).then((canvas) => {
                //const imgWidth = 208;
                //const imgHeight = canvas.height * imgWidth / canvas.width;
                const contentDataURL = canvas.toDataURL('image/png', 1.0);

                /** saving in ppt form  */
                slide.addText('Calendar View ', {x: 0, y: 0, w: '100%', h: 1, align: 'c', color: '0088CC', fill: 'F1F1F1', fontSize: 24});
                slide.addImage({data: contentDataURL, x: 0, y: 1, w: 10.0, h: 5.0});
                pptx.save('Demo-Images');
            });
        } catch (error) {
            console.log(error);
        }
    }

    /** format date in different formats */
    formatDate(type, inputDate) {
        try {
            const date = new Date(inputDate);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let mn = month + '', dt = date.getDate() + '';
            month < 10 ? mn = '0' + month : '';
            day < 10 ? dt = '0' + day : '';
            if (type === 'mm-dd-yyyy') {
                return mn + '-' + dt + '-' + date.getFullYear();
            }
            if (type === 'dd-mm-yyyy') {
                return dt + '-' + mn + '-' + date.getFullYear();
            }
            if (type === 'yyyy-mm-dd') {
                return date.getFullYear() + '-' + mn + '-' + dt;
            }
            if (type === 'dd-mon-yyyy') {
                const mlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                return day + ' ' + mlist[month - 1] + ' ' + date.getFullYear();
            }
        } catch (error) {
            console.log(error);
        }
    }


    setValue(row: string) {
        this.dataSource.next(row);
    }

    // common method for sort a list by key
    commonSortByKey(array, key, str) {
        return array.sort(function (a, b) {
            let x;
            let y;
            // numerical data
            if (typeof (a[key]) === 'number') {
                x = a[key];
                y = b[key];
            } else if (typeof (a[key] === 'string')) {
                x = a[key].trim().toLowerCase();
                y = b[key].trim().toLowerCase();
            } else if (str === 'date') {
                x = new Date(a[key]);
                x /= 1000 + 900 + 330 * 60;
                y = new Date(b[key]);
                y /= 1000 + 900 + 330 * 60;
            }
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    // common method for reverse sort a list by key
    commonReverseSortByKey(array, key, str) {

        return array.sort(function (b, a) {
            let x;
            let y;
            // numerical data
            if (typeof (a[key]) === 'number') {
                x = a[key];
                y = b[key];
            } else if (typeof (a[key] === 'string')) {
                x = a[key].toLowerCase();
                y = b[key].toLowerCase();
            } else if (str === 'date') {
                x = new Date(a[key]);
                x /= 1000 + 900 + 330 * 60;
                y = new Date(b[key]);
                y /= 1000 + 900 + 330 * 60;
            }

            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    fetchInputValue(str) {
        this.value = str;
    }

    passSearchJSON(searchIds, searchNames) {
        this.searchJSON = searchIds;
        this.searchNames = searchNames;
        setTimeout(() => {
            this.emitUpdate.emit({updated: true});
        }, 2000);
    }

    fetchAdvancedSearchValue(str) {
        if (str === 'search-button') {
            this.value = '';
        } else {
            this.emitUpdate.emit({updated: false, value: this.value});
        }
        this.isAdvancedSearchModal = str;
    }


    mapDate(input) {
        const d: Date = new Date(input);
        return {date: {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()}};
    }

    reduceDate(input) {
        let day;
        let month;
        let year;

        if (input['date']) {
            day = input['date']['day'];
            month = input['date']['month'];
            year = input['date']['year'];
        } else {
            day = input['day'];
            month = input['month'];
            year = input['year'];
        }
        // contcatenate 0 with the day if day < 9
        if (day <= 9) {
            day = '0' + day;
        }
        // contenate 0 with the month if month < 9
        if (month <= 9) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + day;
    }

    setDatetoReturn(chosen_year, chosen_month, chosen_date) {
        this.setDate['date']['year'] = chosen_year;
        this.setDate['date']['month'] = chosen_month;
        this.setDate['date']['day'] = chosen_date;
    }

    // validate Date
    // validateDate(chosen_event, date_to_compare_with) {
    //
    //     // extract year, month and date
    //     const start_year = date_to_compare_with['date']['year'];
    //     const start_month = date_to_compare_with['date']['month'];
    //     const start_date = date_to_compare_with['date']['day'];
    //
    //     const chosen_year = chosen_event['date']['year'];
    //     const chosen_month = chosen_event['date']['month'];
    //     const chosen_date = chosen_event['date']['day'];
    //
    //     // check if year is equal to start year
    //     if (chosen_year === start_year) {
    //         // check if month is  equal to start month
    //         if (chosen_month === start_month) {
    //             if (chosen_date >= start_date) {
    //                 this.setDatetoReturn(chosen_year, chosen_month, chosen_date);
    //                 return this.setDate;
    //             }
    //             if (chosen_date < start_date) {
    //                 return false;
    //             }
    //         }
    //         // check if month is greater than start month
    //         if (chosen_month > start_month) {
    //             this.setDatetoReturn(chosen_year, chosen_month, chosen_date);
    //             return this.setDate;
    //         }
    //         // check if month is less than start month throw error
    //         if (chosen_month < start_month) {
    //             return false;
    //         }
    //     }
    //     // check if year is greater than the start year
    //     if (chosen_year > start_year) {
    //         this.setDatetoReturn(chosen_year, chosen_month, chosen_date);
    //         return this.setDate;
    //     }
    //     // else throw error
    //     if (chosen_year < start_year) {
    //         return false;
    //     }
    // }

    checkForLeapYear(year): boolean {
        if (year % 4 === 0) {
            if (year % 100 === 0) {
                if (year % 400 === 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    // Start Date Options
    checkDayforStartDate(date, month, year): number {

        if (date === 1) {

            // months for which previous months have 31 days
            if (month === 1 || month === 2 || month === 4 || month === 6 ||
                month === 8 || month === 9 || month === 11) {
                if (date === 1) {
                    return 31;
                } else {
                    return date - 1;
                }
            }
            // months for which previous months have 30 days
            if (month === 5 || month === 7 || month === 10 || month === 12) {
                if (date === 1) {
                    return 30;
                } else {
                    return date - 1;
                }
            }
            if (month === 3) {
                const isLeapYear = this.checkForLeapYear(year);
                if (isLeapYear) {
                    return 29;
                } else if (!isLeapYear) {
                    return 28;
                }
            }
        } else {
            return date - 1;
        }
    }

    checkYearforStartDate(date, month, year) {
        if (month === 1) {
            if (date === 1) {
                return year - 1;
            } else {
                return year;
            }
        } else {
            return year;
        }
    }

    checkMonthforStartDate(month, date) {
        if (date === 1) {
            if (month === 1) {
                return 12;
            } else {
                return month - 1;
            }
        } else {
            return month;
        }
    }

    // End Date Options

    checkYearforEndDate(date, month, year) {
        if (month === 12 && date === 31) {
            return year + 1;
        } else {
            return year;
        }
    }

    checkMonthforEndDate(month, date) {

        if ((date === 28 || date === 29) && month === 2) {
            return month + 1;
        } else if ((date === 30 || date === 31) && month < 12) {
            return month + 1;
        } else if (month === 12 && (date === 31 || date === 30)) {
            return 1;
        } else {
            return month;
        }
    }

    checkDayforEndDate(date, month) {
        if ((date === 28 || date === 29) && month === 2) {
            return 1;
        } else if (date === 30 || date === 31) {
            return 1;
        } else {
            return date + 1;
        }
    }

    // set the Date Options for start date and end date
    setOptions(startDate, endDate) {
        let startDateOptions = {};
        let endDateOptions = {};


        if (startDate['jsdate']) {
            startDate = startDate['jsdate'];
        }
        if (endDate['jsdate']) {
            endDate = endDate['jsdate'];
        }

        const dateOptions = {};

        startDateOptions = {
            dateFormat: 'mm-dd-yyyy',
            disableUntil: {
                year: this.checkYearforStartDate(
                    new Date(startDate).getDate(),
                    new Date(startDate).getMonth() + 1,
                    new Date(startDate).getFullYear()
                ),
                month: this.checkMonthforStartDate(
                    new Date(startDate).getMonth() + 1,
                    new Date(startDate).getDate()
                ),
                day: this.checkDayforStartDate(
                    new Date(startDate).getDate(),
                    new Date(startDate).getMonth() + 1,
                    new Date(startDate).getFullYear()
                )
            },
            disableDateRanges: [
                {
                    begin: {
                        year: this.checkYearforEndDate(
                            new Date(endDate).getDate(),
                            new Date(endDate).getMonth() + 1,
                            new Date(endDate).getFullYear()
                        ),
                        month: this.checkMonthforEndDate(
                            new Date(endDate).getMonth() + 1,
                            new Date(endDate).getDate()
                        ),
                        day: this.checkDayforEndDate(
                            new Date(endDate).getDate(),
                            new Date(endDate).getMonth() + 1,
                        )
                    },
                    end: {
                        year: new Date().getFullYear() + 40,
                        month: 1,
                        day: 1
                    }
                }
            ],
            minYear: new Date().getFullYear(),
            maxYear: new Date().getFullYear() + 40,
        };


        endDateOptions = {
            dateFormat: 'mm-dd-yyyy',
            disableUntil: {
                year: this.checkYearforStartDate(
                    new Date(startDate).getDate(),
                    new Date(startDate).getMonth() + 1,
                    new Date(startDate).getFullYear()
                ),
                month: this.checkMonthforStartDate(
                    new Date(startDate).getMonth() + 1,
                    new Date(startDate).getDate()
                ),
                day: this.checkDayforStartDate(
                    new Date(startDate).getDate(),
                    new Date(startDate).getMonth() + 1,
                    new Date(startDate).getFullYear()
                )
            },
            disableDateRanges: [
                {
                    begin: {
                        year: this.checkYearforEndDate(
                            new Date(endDate).getDate(),
                            new Date(endDate).getMonth() + 1,
                            new Date(endDate).getFullYear()
                        ),
                        month: this.checkMonthforEndDate(
                            new Date(endDate).getMonth() + 1,
                            new Date(endDate).getDate()
                        ),
                        day: this.checkDayforEndDate(
                            new Date(endDate).getDate(),
                            new Date(endDate).getMonth() + 1,
                        )
                    },
                    end: {
                        year: new Date().getFullYear() + 40,
                        month: 1,
                        day: 1
                    }
                }
            ],
            minYear: new Date().getFullYear(),
            maxYear: new Date().getFullYear() + 40,
        };

        dateOptions['startDateOptions'] = startDateOptions;
        dateOptions['endDateOptions'] = endDateOptions;

        return dateOptions;
    }
}

