<div class="popup-container">
    <header>
        <h3>Need Help?</h3>
        <span (click)="close()"> X </span>
    </header>

    <main>
        <p>The User Guide is available for download from
            <a href="assets/docs/CAP User Guide 3.0.pdf" target="_blank" style="cursor: pointer;">here</a>.</p>
        <p> You can reach out to us at <a href="mailto:verso_lillysupport@zs.com?subject=Query" style="cursor: pointer;">verso_lillysupport@zs.com</a> for other queries.</p>
    </main>
</div>

