<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-container">
                    <header>
                        <img class="icon" src="assets/icons/manage-requests/Icon-LOVs-White-v1.0.svg" alt="Misc Request">
                        <div>
                            <h1>New Misc</h1>
                            <img class="btn-close" src="assets/icons/shared/close.svg" alt="close" (click)="close()">
                        </div>
                    </header>
                
                    <main>
                        <p>Enter the following details to create a misc request</p>
                
                        <p class="response-message" [class.success-message]="isRequestSucccess" *ngIf="message">{{message}}</p>
                        
                        <!-- Misc Request Form -->
                        <app-misc-request-form #miscRequestForm (populateDataEmitter)="populateData($event)"></app-misc-request-form>
                        <!-- <app-user-form  #userForm></app-user-form> -->
                      
                        <div style="align-items: center;text-align: center;">
                            <button type="button" (click)="sendRequest()">Send Request</button>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>
