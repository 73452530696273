import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { CONTAINER_DATA } from '../../../container-data';
import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { validateAllFormFields } from '../../../validators';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
    selector: 'app-new-user-request',
    templateUrl: './new-user-request.component.html',
    styleUrls: ['./new-user-request.component.sass']
})
export class NewUserRequestComponent implements OnInit {

    @ViewChild('userForm') userForm: any;

    message: string;
    isRequestSucccess: boolean;
    cmaps = {
        geo: [],
        brand: []
    }
    mdp = {
        geo: [],
        brand: []
    }
    lov = {
        geo: [],
        brand: []
    }
    brandAsPerRole = false;
    geoAsPerRole = false;
    brandClicked = false;
    geoClicked = false;

    constructor(
        @Inject(CONTAINER_DATA) private data: any,
        private service: FetchApiDataService,
        private localstorage: StorageService
    ) { }

    ngOnInit() { 
        const lovs = this.localstorage.getStorage('lovs');
        this.transformCmapsToVeevaName(lovs.allGeo, 'Geographies');
        this.transformCmapsToVeevaName(lovs.allProduct, 'Brands');
        this.lov.geo = lovs.allGeo;
        this.lov.brand = lovs.allProduct;
    }

    // changes cmaps source name to Veeva
    transformCmapsToVeevaName(entity, label) {
        if(entity != undefined){
            for (let i = 0; i < entity.length; i++) {
                if(label == 'Geographies') { 
                    if (entity[i].geo_source == 'CMAPS') {
                        entity[i].geo_source = 'Veeva ' + label;
                    }
                 } else if(label == 'Brands') {
                    if (entity[i].brand_source == 'CMAPS') {
                        entity[i].brand_source = 'Veeva ' + label;
                    }
                }
            }
            return entity;
        }
    }

    // Segregate Veeva geographies from MDP geographies
    seperateBrand(data) {
        this.cmaps.brand = [];
        this.mdp.brand = [];
        for (let i = 0; i < this.lov.brand.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data[j] == this.lov.brand[i].brand_id) {
                    if (this.lov.brand[i].brand_source == 'Veeva Brands') {
                        this.cmaps.brand.push(data[j]);
                    } else {
                        this.mdp.brand.push(data[j]);
                    }
                }
            }
        }
    }

    // Segregate Veeva Brands from MDP brands
    seperateGeo(data) {
        this.cmaps.geo = [];
        this.mdp.geo = [];
        for (let i = 0; i < this.lov.geo.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data[j] == this.lov.geo[i].geo_id) {
                    if (this.lov.geo[i].geo_source == 'Veeva Geographies') {
                        this.cmaps.geo.push(data[j]);
                    } else {
                        this.mdp.geo.push(data[j]);
                    }
                }
            }
        }
    }

    geoValues(event) {
        this.geoAsPerRole = event.geoAsPerRole;
        this.geoClicked = event.geoClicked;
    }

    brandValues(event) {
        this.brandAsPerRole = event.brandAsPerRole;
        this.brandClicked = event.brandClicked;
    }

    // function to create new user
    sendRequest() {
        const form = this.userForm.form;
        if( form.value.role == 2){
            this.brandAsPerRole = true;
            this.geoAsPerRole = true;
        }
        if (form.invalid || (!this.brandAsPerRole && this.brandClicked) || (!this.geoAsPerRole && this.geoClicked)) {
            validateAllFormFields(form);
            return;
        }

        if(form.value.role != 2 ){
            this.seperateGeo(form.value.geographies);
            this.seperateBrand(form.value.brands); 
        }

        let userData = JSON.parse(localStorage.getItem('userdata'));
        const payload = {
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            email: form.value.email,
            role: form.value.role,
            mdp_geo: this.mdp.geo,
            mdp_brand: this.mdp.brand,
            cmaps_brand: this.cmaps.brand,
            cmaps_geo: this.cmaps.geo,
            indications: form.value.indications,
            profile_id: userData.profileId,
            name: userData.name
        }
        if(form.value.role == 2){
            payload.mdp_brand = userData['allBrandId'];
            payload.mdp_geo = userData['allGeoId'];
        }
        this.service.createUserAddRequest(payload).subscribe((resp: any) => {

            this.isRequestSucccess = resp.success;
            if (resp.success) {
                this.message = 'Your request has been successfully submitted.';
                // this.service.newRequestNotification({ 'id': resp['result'], 'targetRole': StringConstants.ADMIN_ROLE, 'description': 'New User' }).subscribe();
                form.reset();
            } else {
                this.message = 'Something went wrong. Please try again after some time';
            }
            setTimeout(() => this.message = undefined, 3000);
        });
    }

    // function to close modal
    close() {
        this.data.dialogRef.dispose();
    }

    // function to close using Escape key 
    @HostListener('document:keyup', ['$event.key'])
    escHandler(key: string) {
        if (key === 'Escape') {
            this.close();
        }
    }
    convertToString(array) {
        console.log(array);
        for (let i = 0; i < array.length; i++) {
            array[i] = array[i].toString();
        }
        return array;
    }
}
