<!--ADVANCED SEARCH MODAL POP UP-->
<div class="outer-advanced-modal" *ngIf="show">
    <div id="advanced-modal" class="advanced-search-pop-up slideInDown animated">
        <!--Modal Header-->
        <div class="modal-header">
            <header>Advanced Search</header>
            <img class="cross" src="assets/icons/shared/close.svg" (click)="closeModal();$event.stopPropagation()" />
        </div>
        <!--Modal-body-->
        <div class="modal-body">
            <!--CHECKBOXES-->
            <div class="checkboxes">
                <!--Program checkbox-->
                <div class="program-checkbox">
                    <input id="program" [(ngModel)]="programFilter" type="checkbox" />
                    <label for="program">Program</label>
                </div>
                <!--Campaign checkbox-->
                <div class="campaign-checkbox">
                    <input id="campaign" [(ngModel)]="campaignFilter" type="checkbox" />
                    <label for="campaign">Campaign</label>
                </div>
                <!--Tactic checkbox-->
                <div class="tactic-checkbox">
                    <input id="tactic" [(ngModel)]="tacticFilter" type="checkbox" />
                    <label for="tactic">Tactic</label>
                </div>
                <div *ngIf="noFilterSelected" class="error-message">
                    <p>Please select either of the filters.</p>
                </div>

            </div>
            <!--Loading for Dropdown-->
            <div class="loading-circle" *ngIf="isLoading">
                <img src="assets/icons/grid/loading_circle.gif" />
            </div>
            <!--DROPDOWNS-->
            <div *ngIf="!isLoading" class="dropdowns">
                <!--Dropdown Sub block 1-->
                <div class="dropdown-block">

                    <!--Product-->
                    <div class="dropdown-label">
                        <label>Product: </label>
                        <ng-select [(ngModel)]="selectedBrand" [items]="brand_list" placeholder="Please Select"
                            bindLabel="brand_name" bindValue="brand_id" [clearable]="true"
                            (change)="fetchName(brand_list, 'brand_name', 'brand_id', selectedBrand)"
                            [searchable]="false">
                        </ng-select>
                    </div>
                     <!--Tactic Status-->
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label>Tactic Status: </label>
                        <ng-select [(ngModel)]="selectedStatus" [items]="tactic_statuses" [clearable]="true"
                            placeholder="Please Select" bindLabel="status_name" bindValue="status_id"
                            (change)="fetchName(tactic_statuses, 'status_name', 'status_id', selectedStatus)"
                            [searchable]="false">
                        </ng-select>
                    </div>
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label>Approved Job ID : </label>
                        <ng-select [(ngModel)]="selectedJobId" [items]="job_id_list" [clearable]="true"
                            placeholder="Please Select" bindLabel="approved_job_id" bindValue="approved_job_id" (change)="getTacticCodes();fetchName(job_id_list, 'approved_job_id', 'approved_job_id', selectedJobId)">
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <p [title]="item.approved_job_id"> {{item.approved_job_id}}
                                </p>
                            </ng-template>
                        </ng-select>
                    </div>
                    <!--Vendor-->
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label>Vendor: </label>
                        <ng-select [(ngModel)]="selectedVendor" [items]="vendor_list" placeholder="Please Select"
                            bindLabel="vendor_name" bindValue="vendor_id" [clearable]="true"
                            (change)="fetchName(vendor_list, 'vendor_name', 'vendor_id', selectedVendor)"
                            [disabled]="isCreativeUser" [searchable]="false">
                        </ng-select>
                    </div>
                </div>
                <!--Dropdown Sub block 2-->
                <div class="dropdown-block">
                     <!--Department-->
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label style="width:150px">Department: </label>
                        <ng-select [(ngModel)]="selectedDept" [items]="dept_list" placeholder="Please Select"
                            bindLabel="department_name" bindValue="department_id" [clearable]="true"
                            (change)="fetchName(dept_list, 'department_name', 'department_id', selectedDept)">
                        </ng-select>
                    </div>
                    <!--Indication-->
                    <div class="dropdown-label">
                        <label style="width:150px">Indication/Disease State: </label>
                        <ng-select [(ngModel)]="selectedIndication" [items]="indication_list"
                            placeholder="Please Select" bindLabel="indication_name" bindValue="indication_id"
                            [clearable]="true"
                            (change)="fetchName(indication_list, 'indication_name', 'indication_id', selectedIndication)"
                            [searchable]="false">
                        </ng-select>
                    </div>
                    <!-- tactic Id -->
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label style="width:150px">Tactic ID : </label>
                        <ng-select [(ngModel)]="selectedTacticId" [items]="tactic_id_list" [clearable]="true" placeholder="Please Select" (change)="fetchName(tactic_id_list, 'exp_code', 'exp_code', selectedTacticId)">
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <p [title]="item">{{item}}</p>
                            </ng-template>
                        </ng-select>
                    </div>
                    <!--Tactic Status-->
                    <div class="dropdown-label" *ngIf="isCreativeUser">
                        <label>Tactic Status: </label>
                        <ng-select [(ngModel)]="selectedStatus" [items]="tactic_statuses" [clearable]="true"
                            placeholder="Please Select" bindLabel="status_name" bindValue="status_id"
                            (change)="fetchName(tactic_statuses, 'status_name', 'status_id', selectedStatus)">
                        </ng-select>
                    </div>
                </div>
                <!--Dropdown Sub block 3-->
                <div class="dropdown-block">
                    <!--Customer Segment-->
                    <!-- <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label>Customer Segment: </label>
                        <ng-select [(ngModel)]="selectedCustomerSegment" [items]="customer_seg_list" placeholder="Please Select" bindLabel="customersegment_name" bindValue="customersegment_id" [clearable]="true" (change)="fetchName(customer_seg_list, 'customersegment_name', 'customersegment_id', selectedCustomerSegment)"  [searchable]="false">
                        </ng-select>
                    </div> -->
                     <!--Business Unit-->
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label style="width: 120px">Business Unit: </label>
                        <ng-select [(ngModel)]="selectedGbu" [items]="business_unit_list" placeholder="Please Select"
                            bindLabel="gbu_name" bindValue="gbu_id" [clearable]="true"
                            (change)="fetchName(business_unit_list, 'gbu_name', 'gbu_id', selectedGbu)">
                        </ng-select>
                    </div>
                     <!--Customer Type-->
                    <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label style="width: 120px">Customer Type: </label>
                        <ng-select [(ngModel)]="selectedCustomer" [items]="customer_type_list"
                            placeholder="Please Select" bindLabel="customertype_name" bindValue="customertype_id"
                            [clearable]="true"
                            (change)="fetchName(customer_type_list, 'customertype_name', 'customertype_id', selectedCustomer)"
                            [searchable]="false">
                        </ng-select>
                    </div>

                      <!--Execution Path-->
                       <div class="dropdown-label" *ngIf="!isCreativeUser">
                        <label style="width:120px">Execution Path: </label>
                        <ng-select [(ngModel)]="selectedExecutionPath" [items]="execution_path_list" placeholder="Please Select"
                            bindLabel="execution_path_name" bindValue="execution_path_id" [clearable]="true"
                            (change)="fetchName(execution_path_list, 'execution_path_name', 'execution_path_name', selectedExecutionPath)">
                        </ng-select>
                    </div>
                </div>
            </div>
            <!--DATES-->
            <div class="dates">
                <label style="width: 100px;">Tactic Send Date:</label>
                <!--FROM DATE-->
                <my-date-picker id="from-date" name="mydate" [options]="myDatePickerOptions" placeholder="From"
                    (dateChanged)="fromDte($event)"></my-date-picker>
                <!--TO DATE-->
                <my-date-picker id="to-date" name="mydate" [options]="myDatePickerOptions" (dateChanged)="toDte($event)"
                    placeholder="To"></my-date-picker>
                <p id="errorMess" *ngIf="flag === 1;">{{errorMessage}}</p>
            </div>
            <!--SEARCH BUTTON-->
            <button (click)="closeModal(); searchByValues();" class="search-button">Search
            </button>
        </div>
    </div>
</div>
