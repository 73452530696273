import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';

import { CONTAINER_DATA } from '../../../container-data';
import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { StringConstants } from '../../../../../environments/StringConstants';

@Component({
  selector: 'app-new-misc-request',
  templateUrl: './new-misc-request.component.html',
  styleUrls: ['./new-misc-request.component.sass']
})
export class NewMiscRequestComponent implements OnInit {

  @ViewChild('miscRequestForm') miscRequestForm: any;
  message: string;
  miscRequestData
  isRequestSucccess: boolean;

  constructor(
      @Inject(CONTAINER_DATA) private data: any,
      private service: FetchApiDataService,
  ) { }

  /**
   * populate data
   * @param event - event
   */
  
  populateData(event){
    let userData = JSON.parse(localStorage.getItem('userdata'));
    this.miscRequestData = event.formData;
    this.miscRequestData.profile_id = userData.profileId;
    this.miscRequestData.name = userData.name;
  }

  ngOnInit() { }

  // function to create new request
  sendRequest() {
    this.service.createMiscRequest(this.miscRequestData ).subscribe(resp => {
        this.isRequestSucccess = resp.success;
        if (resp.success) {
            this.message = 'Your request has been successfully submitted.';
            this.service.newRequestNotification({ 'id': resp['result'], 'targetRole': StringConstants.ADMIN_ROLE, 'description': 'New Misc' }).subscribe();
        } else {
            this.message = 'Something went wrong. Please try again after some time';
        }
        setTimeout(() => {
            this.message = undefined;
            this.close();
        }, 3000);
    });
  }

  // function to close modal
  close() {
      this.data.dialogRef.dispose();
  }

  // function to close modal onclick escape key
  @HostListener('document:keyup', ['$event.key'])
  escHandler(key: string) {
      if (key === 'Escape') {
          this.close();
      }
  }
}
