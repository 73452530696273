import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router) {
    }

    canActivate() {
        return true;
        let userdata = localStorage.getItem('userdata');
        let userinfo = JSON.parse(userdata);
        if (userdata !== null && userdata !== undefined) {
            try {
                userdata = JSON.parse(userdata);
                const currdate = new Date();
                const expidate = new Date(userdata['expiration']);

                const diffseconds = Math.abs((currdate.getTime() - expidate.getTime()) / 1000);

                if (diffseconds > 86400) {
                    this._router.navigateByUrl('/auth/session-timeout');
                    return false;
                }
                if(userinfo.allRolesId.includes(4) && !userinfo.allRolesId.includes(1)) {
                    if(window.location.hash.includes('metadata-view') || window.location.hash.includes('calendar-view')) {
                        return false;
                    }
                } else if(userinfo.allRolesId.includes(1) && !userinfo.allRolesId.includes(4)) {
                    if(window.location.hash.includes('cmaps')) {
                        return false;
                    }
                }

                return true;

            } catch (e) {
                this._router.navigateByUrl('/auth/session-timeout');
                return false;
            }
        } else {
            this._router.navigateByUrl('/auth/session-timeout');
            return false;
        }
    }
}
