import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CONTAINER_DATA } from '../../../container-data';

@Component({
    selector: 'app-restore-deleted-request',
    templateUrl: './restore-deleted-request.component.html',
    styleUrls: ['./restore-deleted-request.component.sass']
})
export class RestoreDeletedRequestComponent implements OnInit {

    form: FormGroup;
    programs: any[];
    campaigns: any[];

    constructor(
        private fb: FormBuilder,
        @Inject(CONTAINER_DATA) private data: any
    ) {
        this.form = this.fb.group({
            type: ['', Validators.required],
            category: ['', Validators.required],
            name: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    sendRequest() {
        this.close();
    }

    close() {
        this.data.dialogRef.dispose();
    }

    @HostListener('document:keyup', ['$event.key'])
    escHandler(key: string) {
        if (key === 'Escape') {
            this.close();
        }
    }

}
