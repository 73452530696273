import {
    Directive,
    Component,
    OnInit,
    Inject,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentRef,
    HostListener
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { CONTAINER_DATA } from '../../../container-data';
import { LOV_COMPONENTS } from './lov-fields';
import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { StorageService } from '../../../services/storage.service'
import { Lovs } from '../../../../models/lov';
import { validateAllFormFields, dropdownSorter } from '../../../validators';
import { StringConstants } from '../../../../../environments/StringConstants';
import { SingleLovRequestComponent } from './single-lov-request/single-lov-request.component';

@Directive({
    selector: '[appFormHost]'
})
export class FormHostDirective {

    constructor(
        public viewContainerRef: ViewContainerRef
    ) { }

}

@Component({
    selector: 'app-new-lov-request',
    templateUrl: './new-lov-request.component.html',
    styleUrls: ['./new-lov-request.component.sass']
})
export class NewLovRequestComponent implements OnInit {

    lovs: Lovs;
   // lovTypes = LOV_COMPONENTS.sort(dropdownSorter('displayValue'));
    lovTypes;
    lovTypeControl = new FormControl();
    @ViewChild(FormHostDirective) formHost: FormHostDirective;
    @ViewChild('singleLovContainer', { read: ViewContainerRef , static: false})
    private viewRef: ViewContainerRef;
    currentFormRef: ComponentRef<any>;
    selectedLovType: string;
    message: string;
    isRequestSucccess: boolean;

    constructor(
        @Inject(CONTAINER_DATA) private data: any,
        private crf: ComponentFactoryResolver,
        private service: FetchApiDataService,
        private storage: StorageService
    ) { }

    ngOnInit() {
        this.lovTypeControl.valueChanges.subscribe(lov => this.loadComponent(lov));
        if (!this.storage.getStorage('lovs')) {
            this.service.getAllLovs().subscribe(
                (data) => {
                    this.storage.setStorage('lovs', data['lovs']);
                }
            );
        }
        this.lovs = this.storage.getStorage('lovs');
        this.lovTypes =  this.lovs.allLovTypes
        .sort(dropdownSorter('lov_name'));
    }

    // function to load component
    loadComponent(lov) {
        this.formHost.viewContainerRef.clear();
        if (lov) {
           this.selectedLovType = lov.lov_code;
            this.viewRef.clear();
            this.currentFormRef = this.viewRef.createComponent(SingleLovRequestComponent);
            this.currentFormRef.instance.lovType = this.lovTypeControl.value;
        }
    }

    // Function to request
    sendRequest() {
        const component = this.currentFormRef.instance;
        if (component.form.invalid) {
            validateAllFormFields(component.form);
            return;
        }
        if (!this.lovs && !this.lovs.allLovTypes) {
            if (!this.storage.getStorage('lovs')) {
                this.service.getAllLovs().subscribe(
                    (data) => {
                        this.storage.setStorage('lovs', data['lovs']);
                    }
                );
            }
            this.lovs = this.storage.getStorage('lovs');
        }

        let userData = JSON.parse(localStorage.getItem('userdata'));
        let lovValues: any;
        if (!this.lovTypeControl.value.is_mapping) {
            lovValues = component.value;
        } else {
            lovValues = {};
            component.value.map(v => {
                let key = Object.keys(v);
                lovValues[key[0]] = parseInt( v[key[0]] , 10);
            });
        }

        const payload = {
            lovType: this.lovs.allLovTypes.find(l => l.lov_code === this.selectedLovType),
            values: lovValues,
            profile_id: userData.profileId,
            name: userData.name
        };
        this.service.createLovAddRequest(payload).subscribe(resp => {
            this.isRequestSucccess = resp['success'];
            if (resp['success']) {
                this.message = 'Your request has been successfully submitted.';
                this.service.newRequestNotification({ 'id': resp['result'], 'targetRole': StringConstants.ADMIN_ROLE, 'description': 'New LOV' }).subscribe((res) => {
                });
                component.clear();
            } else {
                if(resp.message === 'Duplicate Entry, failed to create request'){
                   
                    this.message = 'The mapping aleady exists.Please try again'
                }
                else{
                    this.message = 'There was some error. Please try again after some time';

                }
               
            }
            setTimeout(() => this.message = undefined, 3000);
        });
    }

    close() {
        this.data.dialogRef.dispose();
    }

    @HostListener('document:keyup', ['$event.key'])
    escHandler(key: string) {
        if (key === 'Escape') {
            this.close();
        }
    }

}
