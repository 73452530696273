<form [formGroup]="form"  style="height: 260px;
overflow: auto;">
    <ng-container >
        <div >
            <div class="form-field-container">
                <p class="errorBlock">The mapping aleady exists.Please try again</p>
                <div  style="display:flex;flex-wrap: wrap;justify-content: space-between;margin: 0 20px;">
                    <div *ngFor="let item of lovMultipleMapping;let i =index"  style="width: 40%;margin-top:10px">    
                        <label>{{ item.name  | titlecase}}:</label>
                        <ng-select [placeholder] ="item.name" [items]="item.mapping" [addTag]="true" 
                        appendTo="body"  [selectOnTab]="true" [bindLabel]="item.lovLabel" [formControlName]="item.name" (change)="editMultipleLov($event , i)"></ng-select>     
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>