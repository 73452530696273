import { InjectionToken } from '@angular/core';
import { PortalInjector } from '@angular/cdk/portal';

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

export function createInjector(dataToPass, injector): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(injector, injectorTokens);
}
