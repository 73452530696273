<!-- Tool tip on Hover -->
<div class="Tooltip-box" *ngIf="openTooltip">
    <div class="tooltip-cont" [style.left.px]="clientX" [style.top.px]="clientY" [style.width.px]="tooltipWidth" [style.height.px]="tooltipHeight">
        <div>
            <div class="title">
                <b><span><label>{{tooltipInfo.title}} </label></span></b>
            </div>
            <div class="journey_name">
                <b style="float: left"><span><label> Campaign Name : </label></span></b> <span> <label style="width: 267px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap;"> &nbsp; {{tooltipInfo.campaign_name}}</label> </span>
            </div>
            <div class="dates">
                <span><label><b>Start Date : </b></label> <label> &nbsp; {{_commonMethods.formatDate('dd-mon-yyyy', tooltipInfo.start)}} </label></span>
                <br/>
                <!-- <span><label><b>End Date : </b></label> <label> {{_commonMethods.formatDate('dd-mon-yyyy',tooltipInfo.start)}} </label></span>             -->
            </div>
            <br/>
            <div class="config-overview" style="display: flex; justify-content: space-between !important;">
                <span class="left_icon_status">
                <span *ngIf="tooltipInfo.cssClass == 'ready_class'">
                        <img src="assets/icons/calendar-view/Ready.svg" height="16px" width="16px"/> <label> &nbsp;Ready</label>
                </span>
                <span *ngIf="tooltipInfo.cssClass == 'draft_class'">
                        <img src="assets/icons/calendar-view/Draft.svg" height="16px" width="16px"/> <label> &nbsp;In Draft</label>
                </span>
                <span *ngIf="tooltipInfo.cssClass == 'active_class'">
                        <img src="assets/icons/calendar-view/Active.svg" height="16px" width="16px"/> <label> &nbsp;Active</label>     
                </span>
                <span *ngIf="tooltipInfo.cssClass == 'expired_class'">
                        <img src="assets/icons/calendar-view/expired.svg" height="16px" width="16px"/> <label> &nbsp;Completed</label>     
                </span>
                </span>
                <span class="attribute_icon" style="padding-right:1%">
                    <img src="assets/icons/calendar-view/Single Instance.svg" width="16px" height="16px"/> <label> Single Instance</label> 
                </span>

                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'speaker program'">
                    <img src="assets/icons/taskbar/tactictype/Speaker Program.svg" height="16px" width="16px"/> <label> Speaker Program</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'mobile alerts'">
                    <img src="assets/icons/taskbar/tactictype/Mobile Alert.svg" height="16px" width="16px"/> <label> Mobile Alerts</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'hq emails'">
                    <img src="assets/icons/taskbar/tactictype/HQ Email.svg" height="16px" width="16px"/> <label> HQ Emails</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == '3rd party email'">
                    <img src="assets/icons/taskbar/tactictype/Third Party Vendor Email.svg" height="16px" width="16px"/><label> 3rd Party Email</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'direct mail'">
                    <img src="assets/icons/taskbar/tactictype/Direct Mail.svg" height="16px" width="16px"/><label> Direct Mail</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'attendee news channel'">
                    <img src="assets/icons/taskbar/tactictype/Third Party Vendor Email.svg" height="16px" width="16px"/><label> Attendee News Channel</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'banner ads'">
                    <img src="assets/icons/taskbar/tactictype/Banner.svg" height="16px" width="16px"/><label> Banner Ads </label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'web registration'">
                    <img src="assets/icons/taskbar/tactictype/Webinar.svg" height="16px" width="16px"/><label> Web registration</label>
                </span>
                <span *ngIf="tooltipInfo.tactic_name.toLowerCase() == 'alerts'">
                    <img src="assets/icons/taskbar/tactictype/Mobile Alert.svg" height="16px" width="16px"/><label> Alerts </label>
                </span>
            </div>
        </div>
    </div>
    <!-- after separating this component , Arrow part is commented for now Need to fix the y pposition -->
    <!-- <div class="arrow-box" *ngIf="openTooltip" [style.left.px]="clientX" [style.top.px]="arrowClientY" [style.width.px]="tooltipWidth">
        <span class="arrow"></span>
    </div> -->
</div>
<!-- Tool tip Ends -->