<!-- The Modal -->
<div class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <div class="content">
            <header>{{message}}</header>
            <div class="close-button">
                <span class="close" (click)="closeOpenModal()">&times;</span>
            </div>
        </div>


    </div>

</div>
