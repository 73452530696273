import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isThursday } from 'date-fns';
import { element } from 'protractor';
import { sample } from 'rxjs';
import { StorageService } from '../../../../../metadata-view/utilities/storage.service';
import { dropdownSorter } from '../../../../validators';

@Component({
    selector: 'app-single-lov-request',
    templateUrl: './single-lov-request.component.html',
    styleUrls: ['./single-lov-request.component.sass']
})
export class SingleLovRequestComponent implements OnInit {

    @Input() lovType: any;


    form: FormGroup;
    lovs: any[];
    message: string;
    bindLabel: string;
    type: any;
    lovMapping;
    lovLabel;
    lovLabelId;
    values;
    addedLovVal;
    lovMultipleMapping = [];
    isMultipleMapping;
    multipleMapKeys;



    constructor(
        private storage: StorageService,
        private fb: FormBuilder
    ) { }

    ngOnInit() {

        this.lovs = this.storage.getStorage('lovs');
        this.isMultipleMapping = this.lovType['is_mapping'];
        if (this.isMultipleMapping) {
            let testMap = [];
            let lovLabel1;
            let lovId1;
            let lovMapping1;
            let selectedSplitLov = this.lovType.lov_name.split("/");
            for (let i = 0; i <= selectedSplitLov.length - 1; i++) {
                for (const [key, val] of Object.entries(this.lovs)) {
                    let currentLovKey = key.toUpperCase().substring(3);
                   
                    let testStr = selectedSplitLov[i].split(" ").join("");
                    if (testStr === 'INDICATION') {
                        testStr = testStr.concat('/DISEASESTATE');
                    }

                    if (testStr === currentLovKey) {
                        for (const [key1, val1] of Object.entries(val[0])) {
                            if (key1.includes('_name')) {
                                lovLabel1 = key1;
                                lovMapping1 = val.sort(dropdownSorter(key1));
                            }
                            if (key1.includes('_id')) {
                                lovId1 = key1;
                            }

                        }
                    }
                }
                let obj = {
                    name: selectedSplitLov[i],
                    lovLabel: lovLabel1,
                    lovId: lovId1,
                    mapping: lovMapping1
                    //   map: val.sort(dropdownSorter(key1))
                }
                this.lovMultipleMapping.push(obj);
            }

          

        }
        else {
            for (const [key, val] of Object.entries(this.lovs)) {
                let currentLovKey = key.toUpperCase().substring(3);
                let selectedLovKey = this.lovType.lov_name.split(" ").join("");
                if(selectedLovKey.toUpperCase() == 'GEOGRAPHY'){
                    selectedLovKey = 'GEO';
                }
                if (selectedLovKey === currentLovKey) {
                    for (const [key1, val1] of Object.entries(val[0])) {
                        if (key1.includes('_name')) {
                            this.lovLabel = key1;
                            this.lovMapping = val.sort(dropdownSorter(key1));
                        }
                        if (key1.includes('_id')) {
                            this.lovLabelId = key1;
                        }
                    }
                }
            }
        }

        if (this.isMultipleMapping) {
            this.setDynamicFormControls();
        } else {
            this.form = this.fb.group({
                control: [null, Validators.required]
            });
        }


    }
    setDynamicFormControls() {
        let controls = {}
        this.lovMultipleMapping.forEach(element => {
            controls[element.name] = [null, Validators.required];
        })
        this.form = this.fb.group(controls);
    }
    get value() {
        const multipleLov = [];
        const singleLov = [];
        let multipleMapKey;
        if (this.isMultipleMapping) {
            let multipleMapKeys = [];
            for (let i = 0; i <= this.lovMultipleMapping.length - 1; i++) {
                multipleMapKeys.push(this.form.get(this.lovMultipleMapping[i].name).value);
            }

            multipleMapKeys.map(mapKeys => {
                for (let key in mapKeys) {
                    if (key.includes('id')) {
                        multipleMapKey = key;
                    }
                }
                let multipleMapPayload = {};                                    
                multipleMapPayload[multipleMapKey] = mapKeys[multipleMapKey]
                multipleLov.push(multipleMapPayload);
            });
            return multipleLov;
        }
        else {
            this.form.get('control').value.map(v => {
                singleLov.push(v[this.lovLabel]);
            });
            return singleLov;
        }

    }

    // function to clear form fields
    clear() {
        this.form.reset();
    }

    //function to check entered value is valid or not
    checkValue(lov) {
        if (lov[this.lovLabelId]) {
            this.message = 'Enter only new values';
            setTimeout(() => this.message = undefined, 2000);
            setTimeout(() => {
                this.form.get('control').value.pop();
                this.form.setValue({ control: this.form.get('control').value });
            }, 0);
        }
    }

}
