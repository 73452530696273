import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.sass']
})
export class DeleteModalComponent implements OnInit {

    @Input() show = false;
    @Input() deletetype: string;
    @Output() isAffirmative = new EventEmitter();
    @Output() closeModal = new EventEmitter();

    constructor() {

    }

    ngOnInit() {

        if (!this.deletetype) {
            this.deletetype = 'Entity';
        }

    }


    // function to check whether continue to delete or cancel
    setAffirmation(isdelete) {
        this.isAffirmative.emit(isdelete);
    }

}
