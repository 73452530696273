import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import * as $ from 'jquery';

@Component({
    selector: 'mdp-navigator',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.sass']
})
export class NavigatorComponent implements OnInit, OnDestroy {

    @Input() showSubHeader = true;
    @Input() notificationCount = 0;
    className;
    position;
    top;
    left;
    requestMenuOpened = false;
    private interval: any;
    public isViewOnly = false;

    constructor(public router: Router, private _http: HttpService) {
        try {
            if (!this.router.url.includes('manage-requests')) {
                this.interval = setInterval(() => {
                    this._http.get('getNotificationCount').subscribe((res) => {
                        this.notificationCount = res['count'];
                    });
                }, 1000 * 5);
            }
        } catch (error) {
            console.log(error)
        }
    }

    ngOnInit() {
         this.isViewOnly = localStorage.getItem('isViewOnly') === 'true';
    }

    ngOnDestroy() {
        try {
            clearInterval(this.interval);
        } catch (error) {
            console.log(error)
        }
    }

    quadratic(duration, range, current) {
        return ((duration * 3) / Math.pow(range, 3)) * Math.pow(current, 2);
    }

    /** Toggle Request Menu */
    toggleRequestMenu(event) {
        this.className = event.target.className;
        this.requestMenuOpened = !this.requestMenuOpened;
        // this.className=event.path[1].className;
        if (this.className) {
            this.position = $('.' + this.className).position();
            this.top = this.position.top + 40;
            this.left = this.position.left;
        }
    }

    /** Close Request Menu */
    closeMenu() {
        this.requestMenuOpened = !this.requestMenuOpened;
    }
}
