import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Lovs, Brand, Indication, Franchise } from '../../../../../models/lov';
import { StorageService } from '../../../../../metadata-view/utilities/storage.service';
import { LovTypeComponent } from '../lov-fields';
import { dropdownSorter } from '../../../../validators';

@Component({
    selector: 'app-fbi-lov-request',
    templateUrl: './fbi-lov-request.component.html',
    styleUrls: ['./fbi-lov-request.component.sass']
})
export class FbiLovRequestComponent implements OnInit, LovTypeComponent {

    @Input() lovType: any;

    form: FormGroup;
    franchises: Franchise[];
    brands: Brand[];
    indications: Indication[];
    items: FormArray;

    get value() {
        return this.form.value.items.map(item => ({
            brand: item.brand.brand_id || item.brand.brand_name,
            indication: item.indication.indication_id || item.indication.indication_name,
            franchise: item.franchise.gbu_id || item.franchise.gbu_name
        }));
    }

    constructor(
        private fb: FormBuilder,
        private storage: StorageService
    ) {
        this.items = this.fb.array([this.createFbiInput()]);
        this.form = this.fb.group({ items: this.items });
    }

    ngOnInit() {
        const lovData: Lovs = this.storage.getStorage('lovs');
        this.brands = lovData.allBrands.sort(dropdownSorter('brand_name'));
        this.indications = lovData.allIndications.sort(dropdownSorter('indication_name'));
        this.franchises = lovData.allFranchises.sort(dropdownSorter('gbu_name'));
    }

     //function to create input fields
    createFbiInput(): FormGroup {
        return this.fb.group({
            franchise: [null, Validators.required],
            brand: [null, Validators.required],
            indication: [null, Validators.required]
        });
    }

     //function purpose is undetermined
    getControl(index: number, controlName: string) {
        return this.form.get(`items.${index}.${controlName}`);
    }

    clear() {   // called from NewLovRequestComponent
        this.form.reset();
        setTimeout(() => {
            for (let i = this.items.length - 1; i > 0; i--) {
                this.items.removeAt(i);
            }
        }, 0);
    }

     //function is to add items to an array
    addItem(index: number) {
        this.items.insert(index + 1, this.createFbiInput());
    }

     //function is to add items to an array
    removeItem(index: number) {
        this.items.removeAt(index);
    }

}
