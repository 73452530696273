<!--Menus and Request LOV-->
<div class="menu-header" *ngIf="showSubHeader">

    <!--Menu Items-->
    <div class="menu" *ngIf="!router.url.includes('manage-requests')">
        <a [routerLink]="['/metadata-view/activity/home']" routerLinkActive="active" class="menu-items">Home</a>
        <a [routerLink]="['/metadata-view/activity/my-campaigns']" [ngClass]="{'active': router.url.includes('/activity/my-campaigns') || router.url.includes('/create-metadata/')}" class="menu-items">My Programs</a>
        <a class="menu-items" [ngClass]="{'active': router.url.includes('/activity/archived-programs')}" [routerLink]="['/metadata-view/activity/archived-programs']">Archived Programs</a>
<!--        <a [routerLink]="['/metadata-view/activity/my-drafts']" routerLinkActive="active" class="menu-items">My Drafts</a>-->
        <a [routerLink]="['/metadata-view/activity/notification']" routerLinkActive="active" class="menu-items">Notifications<div class="notification-indicator">{{notificationCount}}</div></a>
    </div>

    <div class="menu" *ngIf="router.url.includes('manage-requests')">
        <a class="menu-items" routerLink="/manage-requests/home" routerLinkActive="active">Home</a>
        <a class="menu-items" routerLink="/manage-requests/manage" routerLinkActive="active">Manage</a>
    </div>

    <!--Request LOV-->
    <div class="request-lov" [ngClass]="isViewOnly? 'disable-item' : ''" *ngIf="!router.url.includes('manage-requests')" (click)="toggleRequestMenu($event);flag = 3;">
        <img src="assets/icons/campaign/add_row.svg" />
        <p>New Request</p>
        <app-new-request-menu *ngIf="requestMenuOpened" [open]="requestMenuOpened" [top]="top" [left]="left" (closeEmitter)="closeMenu()"></app-new-request-menu>
    </div>
</div>
