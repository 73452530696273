<div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-container">
                    <header>
                        <img
                            class="icon"
                            src="assets/icons/shared/icon_download.svg"
                            alt="Misc Request"
                        />
                        <div>
                            <h1>Bulk Export</h1>
                            <img
                                class="btn-close"
                                src="assets/icons/shared/close.svg"
                                alt="close"
                                (click)="close()"
                            />
                        </div>
                    </header>
                    <main>
                        <p
                            fxFlex
                            fxFlexAlign="center"
                            class="success-message-misc"
                            *ngIf="showSuccessMessage"
                        >
                            File downloaded successfully

                            <span (click)="showSuccessMessage = false">X</span>
                        </p>
                        <p
                            fxFlex
                            fxFlexAlign="center"
                            class="response-message-misc"
                            *ngIf="showErrorMessage"
                        >
                            Something went wrong
                            <span (click)="showErrorMessage = false">X</span>
                        </p>
                        <div class="export-filters">
                            <span class="filter-options">
                                <label>Department</label>
                                <ng-select class="filter-dropdown"
                                           [(ngModel)]="selectedDept" [items]="department_list"
                                           placeholder="Please Select" [multiple]="true" [clearable]="true"
                                           (change)="filterList( 'department', selectedDept)" [searchable]="false"
                                           [bindLabel]="'department_name'"
                                           [bindValue]="'department_id'"
                                           [disabled]="showNext"
                                ></ng-select>
                            </span>
                            <span class="filter-options">
                                <label>Product</label>
                                <ng-select class="filter-dropdown"
                                           [(ngModel)]="selectedBrand"  placeholder="Please Select"
                                           [items]="brandList"
                                           [multiple]="true" [clearable]="true"
                                           (change)="filterList('brand_list', selectedBrand)" [searchable]="false"
                                           [bindLabel]="'brandName'"
                                           [bindValue]="'brandId'"
                                           [disabled]="showNext"
                                ></ng-select>
                            </span>
                            <span class="filter-options">
                                <label>Indication/Disease State</label>
                                <ng-select class="filter-dropdown"
                                           [(ngModel)]="selectedIndication"
                                            [items]="indicationList"
                                           [bindLabel]="'indicationName'"
                                           [bindValue]="'indicationId'"
                                           placeholder="Please Select" [multiple]="true" [clearable]="true"
                                           (change)="filterList( 'indication_list', selectedIndication)"
                                           [searchable]="false" [disabled]="showNext"
                                ></ng-select>
                            </span>
                            <span *ngIf="!showNext" class="filter-options">
                                <label></label>
                                <button (click)="exportData()" [disabled]="!bulkExportRowSelection"
                                        class="search-button">Next</button>
                            </span>
                            <span *ngIf="showNext" class="filter-options">
                                <label></label>
                                <button (click)="showNext = false; showErrorMessage = false; showSuccessMessage = false"
                                        class="search-button">Previous</button>
                            </span>
                            <span *ngIf="showNext" class="filter-options">
                                <label></label>
                                <button (click)="exportData(true)" class="search-button">Export</button>
                            </span>
                            <!--                            <div class="filter-options-date">-->
                            <!--                                <label>Send Date Range</label>-->
                            <!--                                &lt;!&ndash;FROM DATE&ndash;&gt;-->
                            <!--                                <div class="dates-wrapper">-->
                            <!--                                    <my-date-picker id="from-date" name="mydate" [options]="myDatePickerOptions"-->
                            <!--                                                    placeholder="From"-->
                            <!--                                                    (dateChanged)="filterList('from_date', $event)"-->
                            <!--                                                    [disabled]="showNext"></my-date-picker>-->
                            <!--                                    &lt;!&ndash;TO DATE&ndash;&gt;-->
                            <!--                                    <my-date-picker id="to-date" name="mydate" [options]="myDatePickerOptions"-->
                            <!--                                                    (dateChanged)="filterList('to_date', $event)"-->
                            <!--                                                    placeholder="To" [disabled]="showNext"></my-date-picker>-->
                            <!--                                </div>-->
                            <!--                                <p id="errorMess" *ngIf="false">Error Message here</p>-->
                            <!--                            </div>-->
                            <div style="margin:8px 0 0 5px">
                                  <span class="filter-options">
                                <label>Vendor</label>
                                <ng-select class="filter-dropdown"
                                           [(ngModel)]="selectedVendor" [items]="vendor_list"
                                           placeholder="Please Select" [multiple]="true" [clearable]="true"
                                           (change)="filterList( 'vendor_list', selectedVendor)" [searchable]="false"
                                           [bindLabel]="'vendor_name'"
                                           [bindValue]="'vendor_id'"
                                           [disabled]="showNext"
                                ></ng-select>
                            </span>
                            </div>

                        </div>
                        <div *ngIf="showNext" style="display: flex;margin: 10px 0 20px 20px">
                            <div style="margin-right: 35px">
                                <label style="margin-bottom: 5px">
                                    Choose file format
                                </label>
                                <div class="selection-box">
                                    <div
                                        class="radio"
                                    >
                                        <input
                                            type="radio"
                                            name="file_format"
                                            value="xlsx"
                                            [(ngModel)]="fileSelectFormat"

                                        />
                                        Excel
                                        <input
                                            type="radio"

                                            name="file_format"
                                            value="txt"
                                            [(ngModel)]="fileSelectFormat"
                                        />Text
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>

                        </div>
                        <div class="export-list">
                            <div *ngIf="showNext" class="my-campaigns" style="height: 100%">
                                <div class="my-campaigns-body">
                                    <div class="column-list all">
                                        <input type="checkbox" (click)="selectAll($event)" [(ngModel)]="allSelected">
                                        <span> Select All </span>
                                    </div>
                                    <ng-container *ngFor="let item of columnList">
                                        <div class="column-list" *ngIf="isMandatoryColumn(item); else nonMandate">
                                            <input type="checkbox" [checked]="true" [disabled]="true">
                                            <span> {{ item }} </span>
                                        </div>
                                        <ng-template #nonMandate>
                                            <div class="column-list">
                                                <input type="checkbox" [ngModel]="selectedColumns.indexOf(item) !== -1"
                                                       (click)="columnSelected($event, item)">
                                                <span> {{ item }} </span>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="!showNext" class="my-campaigns" style="height: 100%">
                                <div class="my-campaigns-body">

                                    <!-- show loading circle when the program data is still in receiving mode-->
                                    <div *ngIf="isLoadingPrograms" class="loading-circle">
                                        <img class="circle-gif" src="assets/icons/grid/loading_circle.gif">
                                    </div>

                                    <!-- Is Loading Programs Circle -->
                                    <div class="loading-circle" *ngIf="!isLoadingPrograms && allPrograms?.length === 0">
                                        <header>No data found</header>
                                    </div>

                                    <div class="loading-circle" *ngIf="!isLoadingPrograms && !allPrograms">
                                        <header>No data found.</header>
                                    </div>

                                    <!--Program Body-->
                                    <perfect-scrollbar class="programs-list" *ngIf="!isLoadingPrograms && allPrograms.length > 0">
                                        <div class="program-section" *ngFor="let data of allPrograms; let i = index;">
                                            <!--Program Row-->
                                            <div class="program-data">
                                                <div class="left">
                                                    <!--Radio button and count-->
                                                    <div class="selection-box">
                                                        <div class="radio">
                                                            <input type="checkbox" [id]="data?.program_id" name="program"
                                                                   [value]="data?.program_id"
                                                                   [(ngModel)]="data.isSelected"
                                                                   (ngModelChange)="isSelected()"
                                                                   (click)="selectRow('program', data?.program_id)"
                                                            />
                                                            <i class="fa fa-caret-right px-2 py-1 pointer-cursor"
                                                               *ngIf="!data.isVisible"
                                                               (click)="expandProgram(data, data?.program_id)"></i>
                                                            <i class="fa fa-caret-down px-2 py-1 pointer-cursor"
                                                               *ngIf="data.isVisible"
                                                               (click)="collapse(data)"></i>
                                                        </div>
                                                    </div>

                                                    <!--Program Name, Program Description, Geo-->
                                                    <span class="program-details p-1"
                                                          (click)="expandProgram(data, data.program_id)">
                                                        {{data.program_name}}</span>


                                                </div>
                                            </div>

                                            <!--Journey Body-->
                                            <div class="journey-container" *ngIf="data['isVisible']">

                                                <!-- when campaign starts loading -->
                                                <div class="loading-circle-inside" *ngIf="data.isCampaignLoading">
                                                    <img class="circle-gif" src="assets/icons/grid/loading_circle.gif">
                                                </div>

                                                <div class="no-campaign"
                                                     *ngIf="!data.isCampaignLoading && data.campaigns?.length === 0">
                                                    No Campaigns Present
                                                </div>

                                                <div class="no-campaign"
                                                     *ngIf="!data.isCampaignLoading && !data.campaigns">No data found</div>

                                                <div class="campaign-section"
                                                     *ngFor="let c of data.campaigns; let ci=index;">

                                                    <!--Campaign Text-->
                                                    <div class="campaign-data">

                                                        <div class="left">
                                                            <!--Radio button and count-->
                                                            <div class="selection-box">
                                                                <div class="radio">
                                                                    <input type="checkbox" name="radio"
                                                                           [(ngModel)]="c.isSelected"
                                                                           (ngModelChange)="isCampSelected(data.campaigns)"
                                                                           (click)="selectRow('campaign', c.campaign_id, data.program_id)">
                                                                    <i class="fa fa-caret-right px-2 py-1 pointer-cursor"
                                                                       *ngIf="!c.isVisible"
                                                                       (click)="expandCampaign(c, c.campaign_id, ci, data.program_id)"></i>
                                                                    <i class="fa fa-caret-down px-2 py-1 pointer-cursor"
                                                                       *ngIf="c.isVisible"
                                                                       (click)="collapse(c)"></i>
                                                                </div>
                                                            </div>

                                                            <!--Program Name, Program Description, Geo-->
                                                            <span class="program-details p-1"
                                                                  (click)="expandCampaign(c, c.campaign_id, ci, data.program_id)">
                                                                {{c.campaign_name}}</span>
                                                        </div>
                                                    </div>

                                                    <!--Tactic Body-->
                                                    <div class="tactic-body-section" *ngIf="c.isVisible">

                                                        <!-- Loading Circle Inside -->
                                                        <div class="loading-circle-inside" *ngIf="c['isLoading']">
                                                            <img class="circle-gif"
                                                                 src="assets/icons/grid/loading_circle.gif">
                                                        </div>

                                                        <div class="table" *ngIf="!c['isLoading']">
                                                            <div class="table-header">
                                                                <div class="col-extra-small">
                                                                </div>
                                                                <div class="col-small">Status</div>
                                                                <div class="col-large">Tactic Description</div>
                                                                <div class="col-small">Key Message</div>
                                                                <div class="col-small">Tactic Type</div>
                                                                <div class="col-small">Delivery Type</div>
                                                                <div class="col-small">Channel</div>
                                                                <div class="col-small">Send Date</div>
                                                                <div class="col-small">End Date</div>
                                                                <!--                                                                <div class="col-small">Actions</div>-->
                                                                <!--                                                                <div>{{c['tacticlist'] | json}}</div>-->
                                                            </div>
                                                            <ng-container *ngFor="let t of c['tacticlist'];">
                                                                <div class="table-data">
                                                                    <div class="col-extra-small">
                                                                        <input type="checkbox" class="p-1" name="radio"
                                                                               [(ngModel)]="t.isSelected"
                                                                               (ngModelChange)="isTacticSelected(c['tacticlist'])"
                                                                               (click)="selectRow('tactic', t.tactic_id, data.program_id, c.campaign_id)">
                                                                        <!-- [attr.disabled]="checkParentJourney(c.campaign_id) ? true : null"-->
                                                                    </div>
                                                                    <div class="col-small">
                                                                        <img class="tactic-image"
                                                                             *ngIf="t.tactic_status === 1"
                                                                             src="assets/icons/my-campaigns/draft.svg">
                                                                        <img class="tactic-image"
                                                                             *ngIf="t.tactic_status === 3"
                                                                             src="assets/icons/my-campaigns/active_status.svg">
                                                                        <img class="tactic-image"
                                                                             *ngIf="t.tactic_status === 2"
                                                                             src="assets/icons/my-campaigns/ready_status.svg">
                                                                    </div>
                                                                    <div class="col-large p-1"
                                                                         [title]="t['tactic_description']">
                                                                        {{t['tactic_description']}}</div>
                                                                    <div class="col-small"
                                                                         [title]="t.pkmessage_name">{{t.pkmessage_name}}</div>
                                                                    <div class="col-small"
                                                                         [title]="t.tactic_code">{{t.tactic_code}}</div>
                                                                    <div class="col-small"
                                                                         [title]="t.delivery_type">{{t.waves_count > 1 ? 'Wave Send' : 'Single Send'}}</div>
                                                                    <div class="col-small"
                                                                         [title]="t['channel_name']">{{t['channel_name']}}
                                                                    </div>
                                                                    <div class="col-small"
                                                                         [title]="t.wave_send_date | date:'shortDate'">
                                                                        {{ t.wave_send_date | date:'shortDate' }}
                                                                    </div>
                                                                    <div class="col-small"
                                                                         [title]="t.wave_end_date | date:'shortDate'">
                                                                        {{ t.wave_end_date | date:'shortDate' }}
                                                                    </div>
                                                                    <!--                                                                    <div class="col-small">-->
                                                                    <!--                                                                        <div class="buttons">-->
                                                                    <!--                                                                            <div class="mx-2 d-inline-block pointer-cursor">-->
                                                                    <!--                                                                                <img class="view-svg-icon" src="assets/icons/campaign/info.svg"-->
                                                                    <!--                                                                                    (click)="openModal('tactic', c.program_id, c.campaign_id,t.tactic_id)">-->
                                                                    <!--                                                                            </div>-->
                                                                    <!--                                                                            <div class="mx-2 d-inline-block pointer-cursor">-->
                                                                    <!--                                                                                <img class="edit-svg-icon" src="assets/icons/shared/edit.svg"-->
                                                                    <!--                                                                                    (click)="openEditFromRow(t.tactic_id,'tactic',0,data,c)">-->
                                                                    <!--                                                                                <i class="fa fa-eye" aria-hidden="true" style="color: #327f97"-->
                                                                    <!--                                                                                    (click)="openEditFromRow(t.tactic_id,'tactic',0,data,c)"></i>-->
                                                                    <!--                                                                            </div>-->
                                                                    <!--                                                                        </div>-->
                                                                    <!--                                                                    </div>-->
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <!-- No Tactics Present -->
                                                        <div class="no-tactic"
                                                             *ngIf="c['tacticlist'] && c['tacticlist'].length === 0 && c['isLoading'] === false">
                                                            No Tactics Present
                                                        </div>
                                                    </div>
                                                    <!--Campaign Section-->
                                                </div>
                                                <!--Campaign Section outer div-->
                                            </div>
                                            <!--Program Section-->
                                        </div>
                                    </perfect-scrollbar>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>
