<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-container">
                    <header>
                        <img class="icon" src="assets/icons/manage-requests/Icon-EditUser-White-v1.0.svg" alt="Edit User">
                        <div>
                            <h1>Edit User</h1>
                            <img class="btn-close" src="assets/icons/shared/close.svg" alt="close" (click)="close()">
                        </div>
                    </header>

                    <main class="container-main">
                        <p>Update the following details to edit user details</p>

                        <p class="response-message" [class.success-message]="isRequestSucccess" *ngIf="message">{{message}}</p>

                        <ng-select [items]="users" placeholder="Select User"  bindLabel="name" bindValue="profile_id" [searchable]="true" [formControl]="userControl"></ng-select>

                        <app-user-form *ngIf="userControl.value" [profileId]="userControl.value" #userForm></app-user-form>

                        <div style="text-align: center;margin:45px 0 35px 0">
                            <button type="button" (click)="sendUpdateRequest()" >Update User</button>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>
