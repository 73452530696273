import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    userInfo;
    waveSnapshot;
    tacticSnapshot;

    constructor() {
    }

    // seting local storage

    setStorage(key, value) {
        localStorage.setItem(key,JSON.stringify(value));
    }

    // getting local service
    getStorage(key) {
        return JSON.parse(localStorage.getItem(key));
    }
}
