import { Component, OnInit, Inject, HostListener, EventEmitter } from '@angular/core';
import { CONTAINER_DATA } from '../../container-data';

@Component({
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html',
    styleUrls: ['./help-dialog.component.sass']
})
export class HelpDialogComponent implements OnInit {

    success = new EventEmitter<any>();
    message: string;

    isAdminUser: boolean;
    isCreativeUser: boolean;
    isCampaignManager: boolean;

    constructor(
        @Inject(CONTAINER_DATA) private data: any
    ) {
    }

    ngOnInit() {
        const role = JSON.parse(localStorage.getItem('userdata'))['allRolesId'][0];

        if (role === 1) {
            this.isCampaignManager = true;
        } else if (role === 2) {
            this.isAdminUser = true;
        } else if (role === 3) {
            this.isCreativeUser = true;
        }
    }

    // function purpose undetermined
    deactivate() {
        const payload = {status: 'rejected'};
        if (this.data.addRequestId) {
            payload['request_id'] = this.data.addRequestId;
            payload['table_id'] = 'a';
        } else if (this.data.editRequestId) {
            payload['request_id'] = this.data.editRequestId;
            payload['table_id'] = 'e';
        } else {
            return;
        }
    }

    // function to close popup
    close() {
        this.data.dialogRef.dispose();
    }

    // function to close popup
    cancel() {
        this.close();
    }

    // function close popup on Escape
    @HostListener('document:keyup', ['$event.key'])
    escHandler(key: string) {
        if (key === 'Escape') {
            this.close();
        }
    }

}
