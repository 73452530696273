import { Component, ElementRef, HostListener, OnInit, Injector, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { SharedStore } from '../../services/shared-store';
import { UtilsService } from '../../services/utils.service';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';

import { Overlay } from '@angular/cdk/overlay';
import { createInjector } from '../../container-data';
import { ComponentPortal } from '@angular/cdk/portal';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

    /** Instance variables */
    showSideMenu = false;
    showAdvanceSearch = false;
    userName: string;
    searchString = '';
    userinfo;
    searchError;
    loadCmaps = false;
    loadMdp = false;
    isAdmin = false;
    overlayRef;
    showMenu = false;
    showUserProfile = false;
    @ViewChild('toggleButton', {static: true}) foo: ElementRef;
    @ViewChild('userProfile') userProfile: ElementRef;
    menu: any;

    constructor(public router: Router, private renderer: Renderer2 ,private httpservice: HttpService, private injector: Injector, private overlay: Overlay, private sharedstore: SharedStore, private _utils: UtilsService, private elem: ElementRef) {

    }

    @HostListener('document:click', ['$event'])
    clickout() {
     this.showUserProfile = false;
     this.showMenu = false;
    }

    

    ngOnInit() {
        this.sharedstore.getSearchString().subscribe(result => {
            this.searchString = result;
        });
        const userinfo = JSON.parse(localStorage.getItem('userdata'));
        this.userinfo = userinfo;
        if (userinfo) {
            this.userName = userinfo['name'];
            const payload = {
                'userRole': userinfo['allRolesId'][0],
                'geo_id': userinfo['allGeoId'],
                'brand_id': userinfo['allBrandId'],
                'indication_id': userinfo['allIndicationId']
            };
            if (this.userinfo.allRolesName.includes('Content Librarian')) {
                this.loadCmaps = true;
            } else {
                this.loadCmaps = false;
            }
            // if (this.userinfo.allRolesName.includes('Campaign Manager') || this.userinfo.allRolesId.includes(5)) {
            //     this.loadMdp = true;
            // } else {
            //     this.loadMdp = false;
            // }
            if (this.userinfo.allRolesId.includes(2)) {
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
            }
            // not setting drop-down data, coz it may scale and issue might raise in memory of local-storage

            // if (!localStorage.getItem('tacticDetailDropdown') && userinfo['allRolesId'][0] !== 2) {
            //     this.httpservice.post('tacticDetailDropdownValues', payload).subscribe(data => {
            //         const jsondata = data['mapping'];
            //         localStorage.setItem('tacticDetailDropdown', JSON.stringify(jsondata));
            //     });
            // }
        }

        // Check the URL of the page everytime and clear the text in the search bar
        // this.router.events.takeUntil(this.ngUnsubscribe).subscribe(_ => {
        //     if (this.location.path() !== '') {
        //         if (this.location.path() !== '/search') {
        //             // this.clearFields();
        //             this.searchText = '';
        //         } else {
        //             this.searchText = this.genericService.value;
        //         }
        //         this.searchError = '';
        //     }
        // });
    }

    /** Opening side modal */
    openModal() {
        this.showSideMenu = true;
    }

    /** Closing side menu  */
    closeSideMenu(flag) {
        this.showSideMenu = flag;
    }

    openAdvanceSearch(){
        this.showAdvanceSearch = true;
    }
    /**
     * Close Advance Search
     * @param event - event
     */
    closeAdvanceSearch() {
        this.showAdvanceSearch = false;
    }
    /** Escape key  */
    @HostListener('document:keyup', ['$event'])
    escKeyEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            for (const elem of this.elem.nativeElement.querySelectorAll('.dropdown-menu')) {
                elem.classList.remove('show');
            }
            for (const elem of this.elem.nativeElement.querySelectorAll('.dropdown')) {
                elem.classList.remove('show');
            }
        }
    }

    // function to append data on clear search string
    onClearSearch() {
        if (this.searchString === '') {
            SharedStore.IfAdvanceClicked = false;
            this.sharedstore.setSearchString(this.searchString);
            return;
        } else {
            this.searchError = '';
        }
    }

    /** on search  */
    onSearch() {
        if (this.searchString !== undefined && this.searchString !== '') {
            this.router.navigate(['/metadata-view/search-results']);
            // this.fetchAdvancedSearchValue('');
            SharedStore.IfAdvanceClicked = false;
            this.sharedstore.setSearchString(this.searchString);
        } else {
            // this.searchError = 'Please enter a search text to search.';
        }
    }

    /**
     * Navigate to url
     * @param url - url
     */
    navigate(url) {
        // if (!this.loadMdp && (url.includes('calendar') || url.includes('metadata'))) {
        //     return;
        // }
        if (!this.loadCmaps && url.includes('cmaps')) {
            return;
        }
        this.router.navigateByUrl(url);
    }

    /**
     * open dialog
     */
    openHelpDialog() {
        const positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally();
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        const injector = createInjector({ dialogRef: this.overlayRef }, this.injector);

        const dialog = new ComponentPortal(HelpDialogComponent, null, injector);
        this.overlayRef.attach(dialog);
        this.overlayRef.backdropClick().subscribe(_ => this.overlayRef.dispose());
    }

    /** Logout  */
    logOut() {
        try {
            localStorage.clear();
            this._utils.removeToken();
            // Navigate to Login page
           // this.router.navigateByUrl('/auth/login');
            window.location.href = environment.authLogoutUrl;
        } catch (error) {
            console.log(error);
        }
    }
     closeHelp() {
       this.overlayRef.dispose();
   }
   showJourneyMenu(){
    this.showUserProfile = false;
    this.showMenu = !this.showMenu;
   }
   showProfile(){
    this.showMenu = false;
    this.showUserProfile = !this.showUserProfile;
   }
}
