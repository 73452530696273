<!-- Side menu -->
<div (click)="closeSideMenu();" class="outer-menu-modal" *ngIf="showSideMenu">
  
   <!-- Menu Modal -->
  <div id="menu-modal-id" class="menu-modal">
      
      <!--User information and name-->
      <div class="user-info row" *ngIf="expanded">
          <img class="profile-img col-4" src="assets/icons/header/profile.svg"/>
          <div class="name-profile col-8">
              <header>{{userName || 'Guest'}}</header>
              <div class="view-logout row">
                <a routerLink="/user/view-profile" class="col-5">
                    <span>
                        View Profile
                    </span>
                </a>
                <!-- <p class="col-1"> | </p> -->
                <a class="col-7" (click)="logOut()">| &nbsp;&nbsp;
                    <span>
                        Logout
                    </span>
                </a>
              </div>
          </div>
      </div>

      <!-- Quick Help-->
      <div class="quick-help row" *ngIf="expanded">
          <a routerLink="/help" class="col-4 image">
              <img src="assets/icons/shared/help.svg"/>
          </a>
          <a routerLink="/help" class="col-8">
            Quick Help 
          </a>
      </div>

      <!-- Training-->
      <div class="training row" *ngIf="expanded">
          <a routerLink="/training" class="col-4 image">
              <img src="assets/icons/header/left_menu_navigation/training.svg"/>
          </a>
          <a routerLink="/training" class="col-8">
              Training
          </a>
      </div>

      <!-- Contact-->
      <div class="contact row" *ngIf="expanded">
          <a routerLink="/contact" class="col-4 image">
            <img src="assets/icons/header/left_menu_navigation/contact.svg"/>
          </a>
          <a routerLink="/contact" class="col-8">
              Contact
          </a>
      </div>

      <!-- Calendar view Toggle -->
      <div class="contact row" *ngIf="expanded">
          <a routerLink="/calendar-view" *ngIf="!router.url.includes('calendar-view')" class="col-4 image" disabled>
              <img src="assets/icons/calendar.svg"/>
          </a>
          <a routerLink="/manager-view" *ngIf="router.url.includes('calendar-view')" class="col-4 image" disabled>
              <img src="assets/icons/mdp.svg"/>
          </a>
          <a (click)="navigateTo('/calendar-view/tactics/view/month')" *ngIf="!router.url.includes('calendar-view')" class="col-8" disabled>
              Calendar View
          </a>
          <a (click)="navigateTo('/campaign/activity/home')" *ngIf="router.url.includes('calendar-view')" class="col-8" disabled>
              MDP View
          </a>
      </div>
  </div>
</div>
<!-- Side menu Ends -->
