<form [formGroup]="form" >
    <ng-container formArrayName="items" *ngFor="let item of items.controls; let i=index; let isFirst=first; let isLast=last">
        <div [formGroupName]="i" [style.margin-bottom]="isLast ? '20px': '0px'">
            <div class="form-field-container">
                <label *ngIf="isFirst">Key Message</label>
                <input type="text" placeholder="Key Message" formControlName="keyMessage">
                <ng-container *ngIf="getControl(i, 'keyMessage').invalid && (getControl(i, 'keyMessage').dirty || getControl(i, 'keyMessage').touched)">
                    <span class="form-error-message" *ngIf="getControl(i, 'keyMessage').errors.required">This field is required.</span>
                    <span class="form-error-message" *ngIf="!getControl(i, 'keyMessage').errors.required && getControl(i, 'keyMessage').errors.emptyName">This field can't be empty</span>
                </ng-container>
            </div>

            <!-- <div class="form-field-container">
                <label *ngIf="isFirst">Franchise:</label>
                <ng-select placeholder="Select Franchise" [items]="franchises" appendTo="body" bindLabel="gbu_name" bindValue="gbu_id" formControlName="franchise"></ng-select>
                <ng-container *ngIf="getControl(i, 'franchise').invalid && (getControl(i, 'franchise').dirty || getControl(i, 'franchise').touched)">
                    <span class="form-error-message" *ngIf="getControl(i, 'franchise').errors.required">This field is required.</span>
                </ng-container>
            </div> -->

            <div class="actions" [style.margin-top]="isFirst ? '27px': '12px'">
                <img src="assets/icons/campaign/add_row.svg" (click)="addItem(i)">
                <img src="assets/icons/campaign/delete_row.svg" (click)="removeItem(i)" [style.visibility]="isFirst && items.controls.length == 1 ? 'hidden' : 'visible'">
            </div>
        </div>
    </ng-container>
</form>
