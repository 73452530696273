<form [formGroup]="form">
    <ng-container formArrayName="items" *ngFor="let item of items.controls; let i=index; let isFirst=first; let isLast=last">
        <div class="fbi-container" [formGroupName]="i" [style.margin-bottom]="isLast ? '20px': '0px'">
            <!-- <div class="form-field-container">
                <label *ngIf="isFirst">Franchise:</label>
                <ng-select placeholder="Franchise" [items]="franchises" [addTag]="true" [selectOnTab]="true" appendTo="body" bindLabel="gbu_name" formControlName="franchise"></ng-select>
                <ng-container *ngIf="getControl(i, 'franchise').invalid && (getControl(i, 'franchise').dirty || getControl(i, 'franchise').touched)">
                    <span class="form-error-message" *ngIf="getControl(i, 'franchise').errors.required">This field is required.</span>
                </ng-container>
            </div> -->

            <div class="form-field-container">
                <label *ngIf="isFirst">Brand:</label>
                <ng-select placeholder="Brand" [items]="brands" [addTag]="true" [selectOnTab]="true" appendTo="body" bindLabel="brand_name" formControlName="brand"></ng-select>
                <ng-container *ngIf="getControl(i, 'brand').invalid && (getControl(i, 'brand').dirty || getControl(i, 'brand').touched)">
                    <span class="form-error-message" *ngIf="getControl(i, 'brand').errors.required">This field is required.</span>
                </ng-container>
            </div>

            <div class="form-field-container">
                <label *ngIf="isFirst">Indication:</label>
                <ng-select placeholder="Indication" [items]="indications" [selectOnTab]="true" [addTag]="true" appendTo="body" bindLabel="indication_name" formControlName="indication"></ng-select>
                <ng-container *ngIf="getControl(i, 'indication').invalid && (getControl(i, 'indication').dirty || getControl(i, 'indication').touched)">
                    <span class="form-error-message" *ngIf="getControl(i, 'indication').errors.required">This field is required.</span>
                </ng-container>
            </div>

            <div class="actions" [style.margin-top]="isFirst ? '27px': '12px'">
                <img src="assets/icons/campaign/add_row.svg" (click)="addItem(i)">
                <img src="assets/icons/campaign/delete_row.svg" (click)="removeItem(i)" [style.visibility]="isFirst && items.controls.length == 1 ? 'hidden' : 'visible'">
            </div>
        </div>
    </ng-container>
</form>
