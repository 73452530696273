import { FbiLovRequestComponent } from './fbi-lov-request/fbi-lov-request.component';
import { KeyMessageLovRequestComponent } from './key-message-lov-request/key-message-lov-request.component';
import { SingleLovRequestComponent } from './single-lov-request/single-lov-request.component';
import { BiLovRequestComponent } from './bi-lov-request/bi-lov-request.component';
// Configuration used to load components dynamically
// `name` maps directly to `lov_code` as stored in database. They need to be an
// exact match
export const LOV_COMPONENTS = [
    // {
    //     name: 'geo',
    //     displayValue: 'Geography',
    //     component: SingleLovRequestComponent
    // },
    // {
    //     name: 'franchise',
    //     displayValue: 'Franchise',
    //     component: SingleLovRequestComponent
    // },
    // {
    //     name: 'brand',
    //     displayValue: 'Brand',
    //     component: SingleLovRequestComponent
    // },
    // {
    //     name: 'indication',
    //     displayValue: 'Indication',
    //     component: SingleLovRequestComponent
    // },
    {
        name: 'vendor',
        displayValue: 'VENDOR',
        component: SingleLovRequestComponent
    },
    {
        name: 'message',
        displayValue: 'PRIMARY CATEGORY',
        component: SingleLovRequestComponent
    },
    {
        name: 'offerType',
        displayValue: 'OFFER TYPE',
        component: SingleLovRequestComponent
    }
];

export interface LovTypeComponent {
    lovType: any;
    value: any;
    clear(): void;
}
