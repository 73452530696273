import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CONTAINER_DATA } from '../../../container-data';
import { LovType, Lovs } from '../../../../models/lov';
import { StorageService } from '../../../services/storage.service';
import { validateAllFormFields, emptyNameValidator, dropdownSorter } from '../../../validators';
import { StringConstants } from '../../../../../environments/StringConstants';

import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { element } from 'protractor';

@Component({
    selector: 'app-edit-lov-request',
    templateUrl: './edit-lov-request.component.html',
    styleUrls: ['./edit-lov-request.component.sass']
})
export class EditLovRequestComponent implements OnInit {

    form: FormGroup;
    multipleMappingForm: FormGroup;
    lovValues: any;
    lovTypes: LovType[];
    lovTypeControl = new FormControl();
    items: FormArray;
    lovs: Lovs;
    isRequestSucccess: boolean;
    message: string;
    lovLabel: string;
    lovMapping: any;
    lovLabelId: string;
    lovMultipleMapping = [];
    lovUpdateMapping = [];
    isMultipleMapping;
    lovType;
    cascadingMapOldValues = [];
    isDuplicateMapping = false;

    constructor(
        private fb: FormBuilder,
        @Inject(CONTAINER_DATA) private data: any,
        private storage: StorageService,
        private service: FetchApiDataService,
    ) {
        this.items = this.fb.array([this.createInput()]);
        this.form = this.fb.group({ items: this.items });
    }

    ngOnInit() {
        this.lovs = this.storage.getStorage('lovs');
        this.lovTypes = this.lovs.allLovTypes
            .sort(dropdownSorter('lov_name'));
        this.lovTypeControl.valueChanges.subscribe((val: LovType) => this.resetForm(val));

    }

    getControl(index: number, controlName: string) {
        return this.form.get(`items.${index}.${controlName}`);
    }

    // function to reset form data
    resetForm(val: LovType) {
        this.lovType = val;
        this.isMultipleMapping = val['is_mapping'];

        if (val) {
            if (this.isMultipleMapping) {
                this.lovMultipleMapping = [];

                let testMap = [];
                let lovLabel1;
                let lovId1;
                let lovMapping1;
                let selectedSplitLov = val.lov_name.split("/");
                for (let i = 0; i <= selectedSplitLov.length - 1; i++) {
                    for (const [key, val] of Object.entries(this.lovs)) {
                        let currentLovKey = key.toUpperCase().substring(3);

                        let testStr = selectedSplitLov[i].split(" ").join("");
                        if (testStr === 'INDICATION') {
                            testStr = testStr.concat('/DISEASESTATE');
                        }

                        if (testStr === currentLovKey) {
                            for (const [key1, val1] of Object.entries(val[0])) {
                                if (key1.includes('_name')) {
                                    lovLabel1 = key1;
                                    lovMapping1 = val.sort(dropdownSorter(key1));
                                }
                                if (key1.includes('_id')) {
                                    lovId1 = key1;
                                }

                            }
                        }
                    }
                    let obj = {
                        name: selectedSplitLov[i],
                        lovLabel: lovLabel1,
                        lovId: lovId1,
                        mapping: lovMapping1

                    }
                    this.lovMultipleMapping.push(obj);
                }

                this.lovUpdateMapping = JSON.parse(JSON.stringify(this.lovMultipleMapping));

                this.lovUpdateMapping.map(element => {
                    element['placeholder'] = element.name;
                    element.name = element.name.concat('_UPDATE')
                });
                this.setDynamicFormControls();

            }
            else {
                for (const [key, val] of Object.entries(this.lovs)) {
                    let currentLovKey = key.toUpperCase().substring(3);
                    let selectedLovKey = this.lovTypeControl.value.lov_name.split(" ").join("");
                    if (selectedLovKey === currentLovKey) {
                        for (const [key1, val1] of Object.entries(val[0])) {
                            if (key1.includes('_name')) {
                                this.lovLabel = key1;
                                this.lovMapping = val.sort(dropdownSorter(key1));
                            }
                            if (key1.includes('_id')) {
                                this.lovLabelId = key1;
                            }
                        }
                    }
                }

            }

            for (let i = this.items.length; i >= 1; i--) {
                this.items.removeAt(i);
            }
            setTimeout(() => this.form.reset(), 0);
        }
    }
    setDynamicFormControls() {
        this.form.reset();
        //  this.multipleMappingForm.reset();
        let controls = {};
        this.lovMultipleMapping.forEach(element => {
            controls[element.name] = [null, Validators.required];
        })
        this.lovUpdateMapping.forEach(element => {
            controls[element.name] = [null, Validators.required];
        })
        this.multipleMappingForm = this.fb.group(controls);
        const ctrs = this.multipleMappingForm.controls;
        const lovKeys = Object.keys(ctrs);
        // Add if conditions based on mutiple LOVs which require cascading.
        if (lovKeys.includes('PRODUCT')) {
            this.multipleMappingForm.controls['INDICATION'].disable();
            this.multipleMappingForm.controls['INDICATION'].setValue(null);
            this.multipleMappingForm.controls['CUSTOMER SEGMENT'].disable();
            this.multipleMappingForm.controls['CUSTOMER SEGMENT'].setValue(null);

            this.multipleMappingForm.controls['PRODUCT'].valueChanges.subscribe(prod => {
                if (prod) {
                    this.multipleMappingForm.controls['INDICATION'].enable();
                } else {
                    this.multipleMappingForm.controls['INDICATION'].disable();
                    this.multipleMappingForm.controls['INDICATION'].setValue(null);
                    this.multipleMappingForm.controls['CUSTOMER SEGMENT'].disable();
                    this.multipleMappingForm.controls['CUSTOMER SEGMENT'].setValue(null);
                }
            });

            this.multipleMappingForm.controls['INDICATION'].valueChanges.subscribe(prod => {
                if (prod) {
                    this.multipleMappingForm.controls['CUSTOMER SEGMENT'].enable();
                } else {
                    this.multipleMappingForm.controls['CUSTOMER SEGMENT'].disable();
                    this.multipleMappingForm.controls['CUSTOMER SEGMENT'].setValue(null);
                }
            });
        }
        if (lovKeys.includes('DELIVERY SOURCE TYPE')) {
            this.multipleMappingForm.controls['DELIVERY SOURCE'].disable();
            this.multipleMappingForm.controls['DELIVERY SOURCE'].setValue(null);
            this.multipleMappingForm.controls['DATA SOURCE CODE'].disable();
            this.multipleMappingForm.controls['DATA SOURCE CODE'].setValue(null);
            this.multipleMappingForm.controls['PLATFORM'].disable();
            this.multipleMappingForm.controls['PLATFORM'].setValue(null);
            this.multipleMappingForm.controls['CONTACT TYPE'].disable();
            this.multipleMappingForm.controls['CONTACT TYPE'].setValue(null);

            this.multipleMappingForm.controls['DELIVERY SOURCE TYPE'].valueChanges.subscribe(prod => {
                if (prod) {
                    this.multipleMappingForm.controls['DELIVERY SOURCE'].enable();
                } else {
                    this.multipleMappingForm.controls['DELIVERY SOURCE'].disable();
                    this.multipleMappingForm.controls['DELIVERY SOURCE'].setValue(null);
                    this.multipleMappingForm.controls['DATA SOURCE CODE'].disable();
                    this.multipleMappingForm.controls['DATA SOURCE CODE'].setValue(null);
                    this.multipleMappingForm.controls['PLATFORM'].disable();
                    this.multipleMappingForm.controls['PLATFORM'].setValue(null);
                    this.multipleMappingForm.controls['CONTACT TYPE'].disable();
                    this.multipleMappingForm.controls['CONTACT TYPE'].setValue(null);

                }
            });

            this.multipleMappingForm.controls['DELIVERY SOURCE'].valueChanges.subscribe(prod => {
                if (prod) {
                    this.multipleMappingForm.controls['DATA SOURCE CODE'].enable();
                } else {
                    this.multipleMappingForm.controls['DATA SOURCE CODE'].disable();
                    this.multipleMappingForm.controls['DATA SOURCE CODE'].setValue(null);
                    this.multipleMappingForm.controls['PLATFORM'].disable();
                    this.multipleMappingForm.controls['PLATFORM'].setValue(null);
                    this.multipleMappingForm.controls['CONTACT TYPE'].disable();
                    this.multipleMappingForm.controls['CONTACT TYPE'].setValue(null);
                }
            });

            this.multipleMappingForm.controls['DATA SOURCE CODE'].valueChanges.subscribe(prod => {
                if (prod) {
                    this.multipleMappingForm.controls['PLATFORM'].enable();
                } else {
                    this.multipleMappingForm.controls['PLATFORM'].disable();
                    this.multipleMappingForm.controls['PLATFORM'].setValue(null);
                    this.multipleMappingForm.controls['CONTACT TYPE'].disable();
                    this.multipleMappingForm.controls['CONTACT TYPE'].setValue(null);
                }
            });

            this.multipleMappingForm.controls['PLATFORM'].valueChanges.subscribe(prod => {
                if (prod) {
                    this.multipleMappingForm.controls['CONTACT TYPE'].enable();
                } else {
                    this.multipleMappingForm.controls['CONTACT TYPE'].disable();
                    this.multipleMappingForm.controls['CONTACT TYPE'].setValue(null);
                }
            });
        }
    }
    createInput(): FormGroup {
        return this.fb.group({
            currentValue: [null, Validators.required],
            newValue: [null, [Validators.required, emptyNameValidator]],
        });
    }

    // function to update form data
    sendUpdateRequest() {
        let params;

        let userData = JSON.parse(localStorage.getItem('userdata'));
        if (this.isMultipleMapping) {
            if (this.multipleMappingForm.invalid) {
                validateAllFormFields(this.multipleMappingForm);
                return;
            }
            params = {
                lovType: this.lovTypeControl.value,
                "values": this.setMultipleMapPayload(this.lovUpdateMapping),
                "oldLovIds": this.setMultipleMapPayload(this.lovMultipleMapping),
                profile_id: userData.profileId,
                name: userData.name
            };
        }
        else {
            if (this.form.invalid) {
                validateAllFormFields(this.form);
                return;
            }
            params = {
                lovType: this.lovTypeControl.value,
                //  oldLovIds: this.form.value.items.map(v => v.currentValue[this.labelMap[this.lovTypeControl.value.lov_code].id]),
                oldLovIds: this.form.value.items.map(v => v.currentValue[this.lovLabelId]),
                newLovValues: this.form.value.items.map(v => v.newValue),
                profile_id: userData.profileId,
                name: userData.name
            };
        }
        this.service.createLovEditRequest(params).subscribe((resp: any) => {
            this.isRequestSucccess = resp.success;
            if (resp.success) {
                this.message = 'Your request has been successfully submitted';
                this.sendNotification(resp['result']);
            }
            // else if(resp.message === 'Duplicate Entry, failed to create request'){
            //       this.isDuplicateMapping = true;
            // }
            else {

                if (resp.message === 'Duplicate Entry, failed to create request') {

                    this.message = 'The mapping aleady exists.Please try again'
                }
                else {
                    this.message = 'Something went wrong. Please try again later';
                }

            }
            this.lovTypeControl.setValue(null);
            setTimeout(() => {
                this.message = undefined;
                // this.form.reset();
                // this.close();
            }, 3000);
        });
    }
    setMultipleMapPayload(dataSet) {
        let multipleMapKeys = [];
        let multipleMapKey;
        let multipleMapPayload = {};
        for (let i = 0; i <= dataSet.length - 1; i++) {
            multipleMapKeys.push(this.multipleMappingForm.get(dataSet[i].name).value);
        }
        multipleMapKeys.map(mapKeys => {
            for (let key in mapKeys) {
                if (key.includes('id')) {
                    multipleMapKey = key;
                }
            }
            multipleMapPayload[multipleMapKey] = parseInt(mapKeys[multipleMapKey], 10)
            // multipleLov.push(multipleMapPayload);
        });
        return multipleMapPayload;

    }

    // function to send notification
    sendNotification(requestId) {
        const payload = {
            id: requestId,
            targetRole: StringConstants.ADMIN_ROLE,
            description: 'Edit LOV'
        };
        this.service.newRequestNotification(payload).subscribe();
    }

    // function to delete 
    sendDeleteRequest() {
        this.close();
    }

    // function to close modal
    close() {
        this.data.dialogRef.dispose();
    }

    // Close on Escape key
    @HostListener('document:keyup', ['$event.key'])
    escHandler(key: string) {
        if (key === 'Escape') {
            this.close();
        }
    }

    // function to add a item to an array
    addItem(index: number) {
        this.items.insert(index + 1, this.createInput());
    }

    // function to remove a item from an array
    removeItem(index: number) {
        this.items.removeAt(index);
    }
    cascadeMultipleLov(e, i) {
        const ctrs = this.multipleMappingForm.controls;
        const lovKeys = Object.keys(ctrs);
        let cascadingMap = this.lovs[this.lovType.alias_name];

        if (lovKeys.includes('PRODUCT')) {
            const product = this.multipleMappingForm.get('PRODUCT').value;
            const indication = this.multipleMappingForm.get('INDICATION').value;
            if (indication) {
                const mappings = cascadingMap.filter(val => parseInt(val.indication_id, 10) === parseInt(indication.indication_id, 10));
                let newMappings = [];
                mappings.map(m => {
                    this.lovUpdateMapping[2]['mapping'].map(lovMap => {
                        if ( parseInt(lovMap['customersegment_id']) === ((parseInt(m['customersegment_id']) || (parseInt(m['customer_segment_id'])) )) ) {
                            newMappings.push(lovMap);
                        }
                    });
                });
                // this.lovMultipleMapping[2]['mapping'] = newMappings;
                this.lovMultipleMapping[2]['mapping'] = newMappings.length > 0 ? this.getUniqueListBy(newMappings, this.lovMultipleMapping[i + 1]['lovId']) : [];

            } else if (product) {
                const mappings = cascadingMap.filter(val => parseInt(val.brand_id, 10) === parseInt(product.brand_id, 10));
                let newMappings = [];
                mappings.map(m => {
                    this.lovUpdateMapping[1]['mapping'].map(lovMap => {
                        if (parseInt(lovMap['indication_id']) === parseInt(m['indication_id'])) {
                            newMappings.push(lovMap);
                        }
                    });
                });
                // this.lovMultipleMapping[1]['mapping'] = newMappings;
                this.lovMultipleMapping[1]['mapping'] = newMappings.length > 0 ? this.getUniqueListBy(newMappings, this.lovMultipleMapping[i + 1]['lovId']) : [];

            }
        } else if (lovKeys.includes('DELIVERY SOURCE TYPE')) {
            // let data = this.lovUpdateMapping[i +1]['mapping'];
            const mappings = cascadingMap.filter(val => parseInt(val[this.lovMultipleMapping[i]['lovId']], 10) === parseInt(e[this.lovMultipleMapping[i]['lovId']], 10));
            // this.lovMultipleMapping[i +1]['mapping'] = mappings;
            let newMappings = [];
            this.lovUpdateMapping[i + 1]?.mapping.map(m => {
                mappings.map(lovMap => {
                    if (parseInt(lovMap[this.lovMultipleMapping[i + 1]['lovId']]) === parseInt(m[this.lovMultipleMapping[i + 1]['lovId']])) {
                        newMappings.push(m);
                    }
                });
            });
            if(this.lovUpdateMapping[i + 1]){
                this.lovMultipleMapping[i + 1]['mapping'] = newMappings.length > 0 ? this.getUniqueListBy(newMappings, this.lovMultipleMapping[i + 1]['lovId']) : [];
            }
        }

    }
    getUniqueListBy(arr, key) {
        try {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        } catch (error) {
            console.log(error)
        }
    }

}
