 <div  class="reminder-modal" *ngIf="showWarningPopup">

      <div class="reminder-modal-content">

          <div class="center-align mt-2">
               <h5>Are you Sure?</h5>

<p class="font-weight-normal info-msg">You have made some changes.If you proceed, then all your changes will be lost.</p>

              <div class=" d-flex mt-3 justify-content-center">
                     <button type="button" (click)="setWarning(true)">Yes</button>
                     <button class="ml-2" type="button" (click)="setWarning(false)">No</button>
              </div>

          </div>
      </div>
 </div>
