import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Role, Vendor, Franchise, Brand, Indication, Geography, Lovs } from '../../../../models/lov';
import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { StorageService } from '../../../services/storage.service';
import { emptyNameValidator, dropdownSorter } from '../../../validators';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.sass']
})
export class UserFormComponent implements OnInit {

    private _profileId: number;

    @Input()
    set profileId(value: number) {
        // Form is populated when a user is being edited. In this case, an user may have already assigned values
        // that the current user doesn't has access to. So, populate the dropdowns with all available values and disable
        // those that the current user doesn't has access to.
        // Not doing so, results in null values being pre-filled. This is a workaround for the same.
        this.alternativeDropdownValues();
        this._profileId = value;
        this.populateForm(value);
        // Now, populate the form.
        setTimeout(() => this.populateForm(value), 0);
    }

    get hasChanged(): boolean {
        return this.hashCode(this.initialFormValue) === this.hashCode(this.form.value);
    }

    form: FormGroup;
    vendors: Vendor[];
    roles: Role[];
    brands: Brand[];
    indications: Indication[];
    geographies: Geography[];
    franchises: Franchise[];
    initialFormValue: any = {};
    isCampaignManager = false;
    isContentLibrarian = false;
    public isViewOnly = false;
    lovData;
    lov = {
        geo: [],
        brand: []
    }
    brand = {
        cmaps: [],
        mdp: []
    }
    geo = {
        cmaps: [],
        mdp: []
    }
    geoAsPerRole: boolean;
    brandAsPerRole: boolean;
    geoClicked: boolean;
    brandclicked: boolean;
    @Output() geoValues = new EventEmitter<any>();
    @Output() brandValues = new EventEmitter<any>();
    isAdmin = false;
    userinfo;
    isAdminSelected : boolean = false;

    constructor(
        private fb: FormBuilder,
        private storage: StorageService,
        private service: FetchApiDataService
    ) {
        this.lovData = this.storage.getStorage('lovs');
        this.form = this.fb.group({
            firstName: [null, [Validators.required, Validators.pattern(/^[A-Za-z. ]+$/), emptyNameValidator]],
            lastName: [null, [Validators.required, Validators.pattern(/^[A-Za-z. ]+$/), emptyNameValidator]],
            vendors: [{ value: null, disabled: true }, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            role: [null, Validators.required],
            geographies: [{value: []}, Validators.required],
            brands: [{value: []}, Validators.required],
            indications: [{ value: null }, Validators.required]
        });
    }

    ngOnInit() {
        this.vendors = this.lovData?.allVendor?.sort(dropdownSorter('vendor_name'));
        const userinfo = JSON.parse(localStorage.getItem('userdata'));
        this.userinfo = userinfo;
        if (this.userinfo.allRolesId.includes(2)) {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        if(!this.isAdmin){
            let temp = this.lovData.allRoles.filter(r => r.role_id !== 2).sort(dropdownSorter('role_name'));
            this.roles = temp.filter(r => r.role_id !== 3).sort(dropdownSorter('role_name'));
        }else{
            this.roles = this.lovData.allRoles.filter(r => r.role_id !== 3).sort(dropdownSorter('role_name'));
        }
        // this.roles = lovs.allRoles.filter(r => r.role_id === 1);

        const userData = this.storage.getStorage('userdata');
        this.lov.geo = this.transformCmapsToVeevaName(this.lovData?.allGeo?.map(g => ({ ...g, disabled: g.is_deleted })), "Geographies").sort(dropdownSorter('geo_source'));
        this.lov.brand = this.transformCmapsToVeevaName(this.lovData?.allProduct?.map(b => ({ ...b, disabled: b.is_deleted })), "Brands")?.sort(dropdownSorter('brand_source'));
        this.indications = this.lovData['allIndication/DiseaseState'].map(i => ({ ...i, disabled: i.is_deleted }));
        if(this.isAdmin){
            // To set all option if role is selected as Admin
            this.geographies = this.lov.geo;
            this.brands = this.lov.brand;
        }
        // this.franchises = this.getLovData('gbu', userData).sort(dropdownSorter('gbu_name'));
        // this.form.get('franchises').setValue([this.franchises[0].gbu_id]);

        this.form.get('role').valueChanges.subscribe(val => {
            if (val !== 3) {
                this.form.get('vendors').setValue(null);
                this.form.get('vendors').disable();
            } else {
                this.form.get('vendors').enable();
            }
        });
        if (this._profileId) {
            this.alternativeDropdownValues();
        }
    }

    // function to capitalize the string
    capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // Function to get Lov data
    getLovData(type: string, userData: any) {
        const t = this.capitalize(type);
        const suffixes = ['name', 'id', 'code'];
        const result = [];
        for (let i = 0; i < userData[`all${t}Name`].length; i++) {
            const obj = {};
            suffixes.forEach(suffix => {
                const s = this.capitalize(suffix);
                obj[`${type}_${suffix}`] = userData[`all${t}${s}`][i];
            });
            result.push(obj);
        }
        return result;
    }

    // changes cmaps source name to Veeva
    transformCmapsToVeevaName(entity, label) {
        if(entity != undefined){
            for (let i = 0; i < entity.length; i++) {
                if(label == 'Geographies') {
    
                    if (entity[i].geo_source == 'CMAPS') {
    
                        entity[i].geo_source = 'Veeva ' + label;
    
                    }
    
                    if(entity[i].geo_source == 'MDP') {
    
                        entity[i].geo_source = 'MDP ' + label;
    
                    }
    
                } else if(label == 'Brands') {
    
                    if (entity[i].brand_source == 'CMAPS') {
    
                        entity[i].brand_source = 'Veeva ' + label;
    
                    }
    
                    if (entity[i].brand_source == 'MDP') {
    
                        entity[i].brand_source = 'MDP ' + label;
    
                    }
                }
            }
            return entity;
        }
    }


    // Gets called whenever geography is selected/changed
    geoChanged() {
        this.geoClicked = true;
        let mdpGeo = false, veevaGeo = false;
        let temp = this.geographies.filter(geo => this.form.get('geographies').value.find(formValue => geo.geo_id == formValue));
        for (let i = 0; i < temp.length; i++) {
            if (temp[i]['geo_source'] == 'MDP Geographies') {
                mdpGeo = true;
            }
            if (temp[i]['geo_source'] == 'Veeva Geographies') {
                veevaGeo = true;
            }
        }
        if (this.isCampaignManager && this.isContentLibrarian) {
            if (mdpGeo && veevaGeo) {
                this.geoAsPerRole = true;
            } else {
                this.geoAsPerRole = false;
            }
        } else if (this.isCampaignManager || this.isViewOnly) {
            if (mdpGeo) {
                this.geoAsPerRole = true;
            } else {
                this.geoAsPerRole = false;
            }
        } else if (this.isContentLibrarian) {
            if(veevaGeo) {
                this.geoAsPerRole = true;
            } else {
                this.geoAsPerRole = false;
            }
        }
        let geoVal = {
            geoAsPerRole: this.geoAsPerRole,
            geoClicked: this.geoClicked
        }
        this.geoValues.emit(geoVal);
    }

    // Gets called whenever brand is selected/changed
    brandChanged() {
        this.brandclicked = true;
        let mdpBrand = false, veevaBrand = false;
        let temp = this.brands?.filter(brand => this.form.get('brands').value.find(formValue => brand.brand_id == formValue));
        if(temp != undefined){
            for (let i = 0; i < temp.length; i++) {
                if (temp[i]['brand_source'] == 'MDP Brands') {
                    mdpBrand = true;
                }
                if (temp[i]['brand_source'] == 'Veeva Brands') {
                    veevaBrand = true;
                }
            }
        }
        if (this.isCampaignManager && this.isContentLibrarian) {
            if (mdpBrand && veevaBrand) {
                this.brandAsPerRole = true;
            } else {
                this.brandAsPerRole = false;
            }
        } else if (this.isCampaignManager || this.isViewOnly) {
            if (mdpBrand) {
                this.brandAsPerRole = true;
            } else {
                this.brandAsPerRole = false;
            }
        } else if (this.isContentLibrarian) {
            if(veevaBrand) {
                this.brandAsPerRole = true;
            } else {
                this.brandAsPerRole = false;
            }
        }
        let brandVal = {
            brandAsPerRole: this.brandAsPerRole,
            brandclicked: this.brandclicked
        }
        this.brandValues.emit(brandVal);
    }


    // Gets called whenever role is selected/changed
    roleChanged(event) {
        this.geographies = [];
        this.brands = [];
        // this.form.get('indications').disable();

        // for (var i = 0; i < event.length; i++) {
        if (event?.role_id == 1) {
            this.isCampaignManager = true;
        } else {
            this.isCampaignManager = false;
        }
        // }

        if (event?.role_id == 4) {
            this.isContentLibrarian = true;
        } else {
            this.isContentLibrarian = false;
        }

        if (event?.role_id === 5) {
            this.isViewOnly = true;
        } else {
            this.isViewOnly = false;
        }
        if(event?.role_id === 2){
            this.isAdminSelected = true;
            // To set all option if role is selected as Admin
            this.geographies = this.lov.geo;
            this.brands = this.lov.brand;
            // this.form.get('indications').enable();
            this.form.get('geographies').setValue([]);
            this.form.get('brands').setValue([]);
            this.form.get('indications').setValue(null);
        }
        if (event != null && Object.keys(event).length > 0) {
            if (this.isCampaignManager && this.isContentLibrarian) {
                this.geographies = this.lov.geo;
                this.brands = this.lov.brand;
                // this.form.get('indications').enable();
            } else if (this.isContentLibrarian) {
                this.geographies = this.lov.geo.filter(geo => geo.geo_source == 'Veeva Geographies');
                this.brands = this.lov.brand.filter(brand => brand.brand_source  == 'Veeva Brands');
                this.form.get('indications').setValue(null);
                this.getDataBasedOnRole();
            } else if (this.isCampaignManager || this.isViewOnly) {
                this.geographies = this.lov.geo.filter(geo => geo.geo_source == 'MDP Geographies');
                this.brands = this.lov.brand?.filter(brand => brand.brand_source == 'MDP Brands');
                // this.form.get('indications').enable();
                this.getDataBasedOnRole();
            }
        } else {
            this.geographies = [];
            this.brands = [];
            this.form.get('geographies').setValue([]);
            this.form.get('brands').setValue([]);
            this.form.get('indications').setValue(null);
        }
        this.geoChanged();
        this.brandChanged();
    }

    // Return data based on the selected role
    getDataBasedOnRole() {
        let temp_geo = [], temp_brand = [];
        for (let i = 0; i < this.geographies.length; i++) {
            for (let j = 0; j < this.form.value.geographies.length; j++) {
                if (this.geographies[i].geo_id == this.form.value.geographies[j]) {
                    temp_geo.push(this.geographies[i].geo_id);
                }
            }
        }
        this.form.get('geographies').setValue(temp_geo);
        if(this.brands != undefined){
            for (let i = 0; i < this.brands.length; i++) {
                for (let j = 0; j < this.form.value.brands.length; j++) {
                    if (this.brands[i].brand_id == this.form.value.brands[j]) {
                        temp_brand.push(this.brands[i].brand_id);
                    }
                }
            }
        }
        this.form.get('brands').setValue(temp_brand);
    }

    // Function to generate hash code
    hashCode(value: any): number {
        const str = JSON.stringify(value);
        /* tslint:disable */
        let hash = 0, i, chr;
        if (str.length === 0) { return hash; }
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
        /* tslint:enable */
    }

    // Function to get alternate dropdown data
    alternativeDropdownValues() {
        // get all lov values for the current user
        const userLOVs = this.storage.getStorage('userdata');
        const userValues = {
            geographyIds: new Set(userLOVs.allGeoId),
            brandIds: new Set(userLOVs.allBrandId),
            indicationIds: new Set(userLOVs.allIndicationId),
            // franchiseIds: new Set(userLOVs.allGbuId)
            // franchiseIds: new Set(userLOVs.allGbuId)

        };

        // get set of all possible lov values.
        const globalLOVs = this.storage.getStorage('lovs');

        // disable the values that doesn't belong to the current user's set.
        // adding a disabled key-value pair allows ng-select to disable a certain option.
        this.indications = globalLOVs['allIndication/DiseaseState']?.map(i => ({ ...i, disabled: !userValues.indicationIds.has(i.indication_id) }))
            .sort(dropdownSorter('indication_name'));
        // this.franchises = globalLOVs.allFranchises.map(f => ({ ...f, disabled: !userValues.franchiseIds.has(f.gbu_id) }))
        //     .sort(dropdownSorter('gbu_name'));
    }

    // convert any value to string
    convertToString(array) {
        for (let i = 0; i < array.length; i++) {
            array[i] = array[i].toString();
        }
        return array;
    }

    // Function to populate the form data
    populateForm(profileId: number) {
        this.isContentLibrarian = false; this.isCampaignManager = false;
        this.isViewOnly = false;

        this.service.getUserDetails(profileId).subscribe(resp => {
            const details = resp['result'];
            const gbis = { geographies: new Set(), brands: new Set(), indications: new Set() };
            details.gbis.forEach(gbi => {
                gbis.geographies.add(gbi.geo_id);
                gbis.brands.add(gbi.brand_id);
                gbis.indications.add(gbi.indication_id);
            });
            // let geo_ids = this.convertToString(Array.from(gbis.geographies));
            // let brand_ids = this.convertToString(Array.from(gbis.brands));
            let geo_ids = Array.from(gbis.geographies);
            let brand_ids = Array.from(gbis.brands);
            // details.cmaps.forEach(cmap => {
            //     geo_ids.push(cmap.country_id);
            //     brand_ids.push(cmap.product_id);
            // })

            if (details.basic.role_id.includes(1)) {
                this.isCampaignManager = true;
            }
            if (details.basic.role_id.includes(4)) {
                this.isContentLibrarian = true;
            }
            if (details.basic.role_id.includes(5)) {
                this.isViewOnly = true;
            }

            if (this.isCampaignManager && this.isContentLibrarian) {
                // this.form.get('indications').enable();
                this.geographies = this.lov.geo;
                this.brands = this.lov.brand;
            } else if (this.isCampaignManager || this.isViewOnly) {
                // this.form.get('indications').enable();
                this.geographies = this.lov.geo.filter(geo => geo.geo_source == 'MDP Geographies');
                this.brands = this.lov.brand.filter(brand => brand.brand_source == 'MDP Brands');
            } else if (this.isContentLibrarian) {
                this.form.get('indications').setValue(null);
                // this.form.get('indications').disable();
                this.geographies = this.lov.geo.filter(geo => geo.geo_source == 'Veeva Geographies');
                this.brands = this.lov.brand.filter(brand => brand.brand_source  == 'Veeva Brands');
            }

            const nameParts = details.basic.name.split(' ');
            const lastName = nameParts.pop();
            this.form.patchValue({
                firstName: nameParts.join(' '),
                lastName: lastName,
                email: details.basic.email,
                role: details.basic.role_id[0],
                vendors: details.vendors.map(v => v.vendor_id),
                geographies: geo_ids,
                // franchises: details.franchises.map(f => f.gbu_id),
                // franchises: [],
                brands: brand_ids,
                indications: Array.from(gbis.indications)
            });
            // NOTE: This is done after form has been populated because, some values
            // might be empty/undefined/null. Those keys will be present in the above
            // object but not if the form's value is used. This is critical because
            // a string representation of this value is used to determine if the form's
            // value has changed.
            this.initialFormValue = { ...this.form.value };
            this.geoChanged();
            this.brandChanged();
        });
    }
}
