import {Directive, ElementRef, Input, OnInit} from '@angular/core';


@Directive({
    selector: '[appFlex]',
})
export class FlexBoxModelDirective implements OnInit {
    @Input() flexDirection = 'row';
    @Input() justify = 'flex-start';
    @Input() align = 'center';

    constructor(private elRef: ElementRef) {

    }

    ngOnInit(): void {

        this.elRef.nativeElement.style.display = 'flex';

        // Set Properties
        this.elRef.nativeElement.style.flexDirection = this.flexDirection;
        this.elRef.nativeElement.style.justifyContent = this.justify;
        this.elRef.nativeElement.style.alignItems = this.align;
    }


}
