<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-container">
                    <header>
                        <img class="icon" src="assets/icons/manage-requests/Icon-AddUser-White-v1.0.svg" alt="New User">
                        <div>
                            <h1>Request New User</h1>
                            <img class="btn-close" src="assets/icons/shared/close.svg" alt="close" (click)="close()">
                        </div>
                    </header>

                    <main>
                        <p>Enter the following details to create a new user</p>

                        <p class="response-message" [class.success-message]="isRequestSucccess" *ngIf="message">{{message}}</p>

                        <app-user-form #userForm (geoValues)="geoValues($event)" (brandValues)="brandValues($event)"></app-user-form>

                        <div style="align-items: center;text-align: center;margin-top: 50px;">
                            <button type="button" (click)="sendRequest()">Send Request</button>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>