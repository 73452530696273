import { Component, OnInit,Input } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'mdp-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit {

  @Input() routes;
  
  constructor(private _router: Router) { }

  ngOnInit() {

  }

  /** Navigate To 
   * @param routeTo - routing to a particular Url
   * @param index - index of current route
  */
  navigateTo(routeTo,index){
    if(index != this.routes.length - 1){
      this._router.navigate([routeTo]);
    }
  }
}
