import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {CONTAINER_DATA} from '../../../container-data';
import {IMyDate, IMyDpOptions} from 'mydatepicker';
import {StorageService} from 'src/app/metadata-view/utilities/storage.service';
import {GlobalStateVariables} from 'src/app/shared/services/globalstate.service';
import {FetchApiDataService} from 'src/app/metadata-view/utilities/fetch-api-data.service';
import {Router} from '@angular/router';
import {HttpService} from '../../../services/http.service';
import {Subject} from 'rxjs';
import {ColumnList, MandatoryColumns} from '../../../../../environments/StringConstants';
import {UtilsService} from '../../../services/utils.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';

export interface Campaign {
    camoaign_id: number;
    tactic_id: [];
}

export interface Program {
    program_id: number;
    campaign: Campaign;
}

@Component({
    selector: 'app-bulk-export',
    templateUrl: './bulk-export.component.html',
    styleUrls: ['./bulk-export.component.sass']
})
export class BulkExportComponent implements OnInit, AfterViewInit {
    public selectedTacticList = [];
    public isLoadingPrograms = true;
    public allPrograms = [];
    public isActiveTactic = false;
    public errorMessage;
    public selDate: IMyDate = {year: 0, month: 0, day: 0};
    public selectedProgram = [];
    public selectedCampaign = [];
    public selectedTactic = [];
    public showNext = false;
    public columnList = ColumnList;
    public selectedColumns = [];
    public mandatoryColumns = MandatoryColumns;
    public filteredObject = {};
    public activeProgramInClone: any = '';
    public globalCloneData = [
        {
            program_id: '',
            editable: false,
            journeys: []
        }
    ];
    public editableProgram: any;
    // Selected Value
    public selectedEditMode;
    public selectedEditValue;
    public tactics;
    public userData;
    public userInfo;
    public isCampaignLoading = false;
    public campaigns;
    public showSlider = false;
    public detailsType = ' ';
    public modalInfo = {detailsType: ' ', pid: ' ', cid: ' ', tid: ' '};
    public filterStatusBox = [];
    isLoading;
    geography_list;
    public indication_list;
    public vendor_list;
    public department_list;
    public product_list;
    business_unit_list;
    therapy_area_list;
    customer_type_list;
    customer_seg_list;
    public brand_list = [];
    searchNames = {};
    public selectedBrand = [];
    public selectedIndication = [];
    public selectedVendor = [];
    public selectedProduct = [];
    public filterObj: Program[] = [];
    public brandId = [];
    public indicationId = [];
    public vendorId = [];
    selectedDept = [];
    public departmentId = [];
    public showSuccessMessage = false;
    public showErrorMessage = false;
    public isSelectAll = false;
    public allSelected = false;
    fileSelectFormat = 'xlsx';
    bulkExportRowSelection = false;
    textFileUrl;
    brandList = [];
    indicationList = [];
    private today = new Date();
    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'mm-dd-yyyy',
        disableSince: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1}
    };
    private ngUnsubscribe = new Subject<boolean>();
    private fromDate = '';
    private toDate = '';

    constructor(
        @Inject(CONTAINER_DATA) private data: any,
        private localStorage: StorageService,
        private globalStateService: GlobalStateVariables,
        public service: FetchApiDataService,
        public router: Router,
        private http_service: HttpService, public utilsService: UtilsService,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit() {
        // Fetch the user Info
        this.selDate = {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate()};
        this.userInfo = this.localStorage.getStorage('userdata');
        this.localStorage.setStorage('isUnderClone', false);
        const clickObserver = {
            wavePrev: false,
            offerPrev: false,
        };
        const saveSkip = {
            type: '',
            id: '',
            isCloned: false
        };
        this.localStorage.setStorage('tacticDetailDraft', false);
        this.localStorage.setStorage('saveSkip', saveSkip);
        this.localStorage.setStorage('clickObserver', clickObserver);
        this.cleanGLobalClone();
        this.selectedColumns = this.selectedColumns.concat(this.mandatoryColumns);
        this.utilsService.closeDialogBox.asObservable().subscribe(value => {
            if (value === 'close') {
                this.close();
            }
        });
        const data = JSON.parse(localStorage.getItem('userdata'));
        for (let i = 0; i < data.allBrandName.length; i++) {
            const brandObj = {'brandId': data.allBrandId[i], 'brandName': data.allBrandName[i]};
            this.brandList.push(brandObj);
        }
        for (let i = 0; i < data.allIndicationName.length; i++) {
            const indicationObj = {
                'indicationId': data.allIndicationId[i],
                'indicationName': data.allIndicationName[i]
            };
            this.indicationList.push(indicationObj);
        }

    }

    ngAfterViewInit() {
        // stuff that doesn't do view changes
        setTimeout(() => {
            this.filterList('initail', 'initial');
            this.loadAdvDropDown();
        }, 1000);
    }

    public initializeProgram(isFiltered = false) {
        try {
            // fetch the required data from the userInfo
            this.userData = {
                'userRole': this.userInfo['allRolesId'][0],
                'geo_id': this.userInfo['allGeoId'],
                'is_expired':false
            };

            // load all programs on page load
            this.isLoadingPrograms = true;
            this.service.loadAllPrograms(this.userData).subscribe(data => {
                    this.isLoadingPrograms = false;
                    if (!isFiltered) {
                        this.allPrograms = data['allprograms'];
                    } else {
                        this.allPrograms = data['allprograms'];
                        const filtered = [];
                        this.filteredObject['programInfo'].map(prog => {
                            filtered.push(this.allPrograms.find(program => program.program_id === prog.program_id));
                        });
                        this.allPrograms = filtered.filter(data => data != undefined);
                    }
                    this.allPrograms.map(program => {
                        program['isSelected'] = false;
                        program['isCampaignLoading'] = false;
                    });
                    this.allPrograms.sort((a, b) => {
                        return a.program_name > b.program_name ? 1 : a.program_name < b.program_name ? -1 : 0;
                    });
                    this.removeVisibility(this.allPrograms);
                }, error => {
                    this.isLoadingPrograms = false;
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    // function to close modal
    public close() {
        this.data.dialogRef.dispose();
    }

    isSelected() {
        this.bulkExportRowSelection = this.allPrograms.some(item => item.isSelected);
    }

    isCampSelected(camp) {
        this.bulkExportRowSelection = camp.some(item => item.isSelected);
    }

    isTacticSelected(tactic) {
        this.bulkExportRowSelection = tactic.some(item => item.isSelected);
    }

    public selectRow(mode, id, programId?, campaignId?, tacticStatus?) {
        if (mode === 'program') {
            const prog = this.allPrograms.find(program => program.program_id === id);
            prog.isSelected = !prog.isSelected;
            if (prog.campaigns) {
                prog.campaigns.map(campaign => {
                    campaign.isSelected = prog.isSelected;
                    if (campaign.tacticlist) {
                        campaign.tacticlist.map(tactic => {
                            tactic.isSelected = campaign.isSelected;

                        });
                    }
                });
            }
        }

        if (mode === 'campaign') {
            this.allPrograms.map(prog => {
                if (prog.program_id === programId && prog.campaigns) {
                    prog.campaigns.map(campaign => {
                        if (campaign.campaign_id === id) {
                            campaign.isSelected = !campaign.isSelected;
                            if (campaign.tacticlist) {
                                campaign.tacticlist.map(tactic => {
                                    tactic.isSelected = campaign.isSelected;
                                    this.selectedTacticList.push(tactic);
                                });
                            }
                        }
                    });
                }
            });

            const program = this.allPrograms.find(prog => prog.program_id === programId);
            program.isSelected = !program.campaigns.some(camp => !camp.isSelected);
        }

        if (mode === 'tactic') {
            this.allPrograms.map(prog => {
                if (prog.program_id === programId && prog.campaigns) {
                    prog.campaigns.map(campaign => {
                        if (campaign.campaign_id === campaignId) {
                            const tactic = campaign.tacticlist.find(tact => tact.tactic_id === id);
                            tactic.isSelected = !tactic.isSelected;
                            this.selectedTacticList.push(tactic);
                        }
                    });
                }
            });

            const program = this.allPrograms.find(prog => prog.program_id === programId);
            const campaign = program.campaigns.find(camp => camp.campaign_id === campaignId);
            campaign.isSelected = !campaign.tacticlist.some(tact => !tact.isSelected);
            program.isSelected = !program.campaigns.some(camp => !camp.isSelected);
        }

    }

    public generateSelectedItems() {
        this.allPrograms.map(program => {
            if (program.isSelected) {
                this.selectedProgram.push(program.program_id);
                const progInfo = this.filteredObject['programInfo'].find(prog => prog.program_id === program.program_id);
                progInfo.campaign.map(camp => {
                    this.selectedCampaign.push(camp.campaign_id);
                    camp.tactic_id.forEach(tactic => {
                        this.selectedTactic.push(tactic);
                    });
                });
            } else {
                if (program.campaigns) {
                    program.campaigns.map(campaign => {
                        if (campaign.isSelected) {
                            this.selectedProgram.push(program.program_id);
                            this.selectedCampaign.push(campaign.campaign_id);
                            const progInfo = this.filteredObject['programInfo'].find(prog => prog.program_id === program.program_id);
                            progInfo.campaign.map(camp => {
                                if (camp.campaign_id === campaign.campaign_id) {
                                    camp.tactic_id.forEach(tactic => {
                                        this.selectedTactic.push(tactic);
                                    });
                                }
                            });
                        } else {
                            if (campaign.tacticlist) {
                                campaign.tacticlist.map(tactic => {
                                    if (tactic.isSelected) {
                                        this.selectedProgram.push(program.program_id);
                                        this.selectedCampaign.push(campaign.campaign_id);
                                        this.selectedTactic.push(tactic.tactic_id);
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
    }

    public cleanGLobalClone() {
        this.globalCloneData = [
            {
                program_id: '',
                editable: false,
                journeys: []
            }
        ];
        this.selectedEditMode = undefined;
        this.selectedEditValue = undefined;
        this.activeProgramInClone = '';
        this.globalStateService.setGlobalState('cloneJson', this.globalCloneData);
    }


    public checkForEmptySelection() {
        const firstCloneObj = this.globalCloneData[0];
        if (!firstCloneObj.editable && !firstCloneObj['journeys'].length || (firstCloneObj['journeys'].length === 1 && !firstCloneObj['journeys'][0].editable &&
            !firstCloneObj.journeys[0].tactic_ids.length)) {

            this.globalCloneData = [
                {
                    program_id: '',
                    editable: false,
                    journeys: []
                }
            ];
            this.selectedEditMode = undefined;
            this.activeProgramInClone = '';
        }
        this.createStartPointForClone();
    }

    public createStartPointForClone() {
        const firstCloneObj = this.globalCloneData[0];
        const journeyIndex = this.globalCloneData[0].journeys.findIndex(x => x.editable === true);
        const tacticJourneyIndex = this.globalCloneData[0].journeys.findIndex(x => x.tactic_ids && x.tactic_ids.length > 0);
        if (firstCloneObj.editable) {
            this.selectedEditMode = 'program';
            this.selectedEditValue = firstCloneObj.program_id;
        } else if (journeyIndex > -1) {
            this.selectedEditValue = this.globalCloneData[0].journeys[journeyIndex]['journey_id'];
            this.selectedEditMode = 'campaign';
            this.localStorage.setStorage('selectedCampaign', this.globalCloneData[0].journeys[journeyIndex]['campaign']);
        } else if (tacticJourneyIndex > -1) {
            this.selectedEditValue = this.globalCloneData[0].journeys[tacticJourneyIndex]['tactic_ids'][0];
            this.selectedEditMode = 'tactic';
            this.localStorage.setStorage('selectedCampaign', this.globalCloneData[0].journeys[tacticJourneyIndex]['campaign']);
        }
        this.globalStateService.setGlobalState('cloneJson', this.globalCloneData);
        this.localStorage.setStorage('copyCloneJson', this.globalCloneData);
    }

    public selectAllTactic(campaign, journeyAlso) {
        const journeysObj = {
            journey_id: campaign.campaign_id,
            tactic_ids: [],
            campaign,
            editable: journeyAlso
        };
        this.service.loadTactics('campaign', campaign.campaign_id).subscribe(data => {
            this.tactics = data.tactics;
            // this.removeVisibility(this.tactics);
            for (let d = 0; d < this.tactics.length; d++) {
                this.tactics[d].isVisible = true;
            }
            campaign.isVisible = true;
            campaign.isLoading = false;
            campaign['tacticlist'] = [];
            const tacticidlist = [];
            for (let j = 0; j < this.tactics.length; j++) {
                const tactic_element = this.tactics[j];
                if (tacticidlist.indexOf(tactic_element['tactic_id']) < 0) {
                    campaign['tacticlist'].push(tactic_element);
                    tacticidlist.push(tactic_element['tactic_id']);
                }
                if (journeysObj.tactic_ids.indexOf(tactic_element.tactic_id) < 0) {
                    journeysObj.tactic_ids.push(tactic_element.tactic_id);
                }
            }
        });
        return journeysObj;
    }

    public expandProgram(program, pid, dynamicSelect?) {
        try {
            this.userData = {
                'program_id': pid,
                'brand_id': this.userInfo.allBrandId,
                'indication_id': this.userInfo.allIndicationId
            };

            // Close All other Programs
            // for (const prog of this.allPrograms) {
            //     prog['isVisible'] = false;
            // }
            // Turn On Selected Program Visbility
            program.isVisible = true;
            program.isCampaignLoading = true;
            this.loadCampsAndTactics(pid, 'program', false, false, false, 0, dynamicSelect);
        } catch (error) {
            this.isCampaignLoading = false;
        }
    }

    public loadCampsAndTactics(pid, type, iscampaignvisible, istacticvisible, isSpecific = false, index = 0, dynamicSelect?, tpid?) {
        try {
            const filteredCampaigns = [];
            const filteredTactics = [];
            if (Object.keys(this.filteredObject).length > 0) {
                let prog;
                if (type === 'program') {
                    prog = this.filteredObject['programInfo'].find(program => program.program_id === pid);
                } else {
                    prog = this.filteredObject['programInfo'].find(program => program.program_id === tpid);
                }
                prog.campaign.map(camp => {
                    filteredCampaigns.push(camp.campaign_id);
                    camp.tactic_id.forEach(id => {
                        filteredTactics.push(id);
                    });
                });
            }
            if (type === 'program') {
                this.campaigns = [];
                this.tactics = [];
                const program = this.allPrograms.find(prog => prog.program_id === pid);
                this.service.loadCamps(this.userData).subscribe(d => {
                    program.isCampaignLoading = false;
                    const campaignidlist = [];
                    if (this.campaigns.length === 0) {
                        for (const campaign of d['campaigns']) {
                            if (campaignidlist.indexOf(campaign['campaign_id']) < 0) {
                                campaignidlist.push(campaign['campaign_id']);
                                campaign['isLoading'] = false;
                                campaign['isVisible'] = istacticvisible;
                                campaign['isSelected'] = program.isSelected;
                                if (filteredCampaigns.length > 0) {
                                    if (filteredCampaigns.indexOf(campaign['campaign_id']) !== -1) {
                                        this.campaigns.push(campaign);
                                    }
                                } else {
                                    this.campaigns.push(campaign);
                                }
                                // this.allPrograms.map(program => {
                                //     if (program.program_id === pid) {
                                program['campaigns'] = this.campaigns;
                                //     }
                                // });
                            }
                        }
                        if (dynamicSelect) {
                            this.selectAllJourneysAndTactics();
                        }
                    }

                }, error => {
                    program.isCampaignLoading = false;
                    this.campaigns = undefined;
                });
            }
            if (type === 'campaign') {
                this.tactics = [];
                if (type !== null) {
                    this.service.loadTactics(type, pid).subscribe(data => {
                        if (filteredCampaigns.length > 0) {
                            if (filteredTactics.length > 0) {
                                const id = [];
                                data.tactics.map(item => {
                                    if (filteredTactics.indexOf(item.tactic_id) !== -1) {
                                        if (id.indexOf(item.tactic_id) === -1) {
                                            this.tactics.push(item);
                                            id.push(item.tactic_id);
                                        }
                                    }
                                });
                            } else {
                                this.tactics = [];
                            }
                        } else {
                            this.tactics = data.tactics;
                        }
                        this.allPrograms.map(program => {
                            if (program.program_id === tpid) {
                                program.campaigns.map(campaign => {
                                    if (campaign.campaign_id === pid) {
                                        this.tactics.map(tactic => {
                                            if (campaign.isSelected) {
                                                tactic['isSelected'] = true;
                                            } else {
                                                if (this.selectedTacticList.length > 0) {
                                                    this.selectedTacticList.forEach(previousSelectedTactics => {
                                                        if (previousSelectedTactics.tactic_id === tactic.tactic_id) {
                                                            if (previousSelectedTactics.isSelected === true) {
                                                                tactic.isSelected = true;
                                                            }
                                                            if (previousSelectedTactics.isSelected === false) {
                                                                tactic.isSelected = false;
                                                            }
                                                        }
                                                    });
                                                }

                                            }
                                            // } else {
                                            //     tactic['isSelected'] = false;
                                            // }
                                        });
                                        campaign['tacticlist'] = this.tactics;
                                        campaign['isLoading'] = false;
                                    }

                                });
                            }
                        });
                    });
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    public selectAllJourneysAndTactics() {
        const firstCloneObj = this.globalCloneData[0];
        firstCloneObj.journeys = [];
        for (let i = 0; i < this.campaigns.length; i++) {
            const element = this.campaigns[i];
            firstCloneObj.journeys.push(this.selectAllTactic(element, true));
        }
    }

    public removeVisibility(data) {
        data = !data ? [] : data;
        for (const d of data) {
            d['isVisible'] = false;
        }
    }

    public transformTactic(campaign, tactic) {
        for (const camp of campaign) {
            camp['tacticlist'] = [];
            const tacticidlist = [];

            for (const tact of tactic) {
                if (tact['campaign_id'] === camp['campaign_id']) {
                    if (tacticidlist.indexOf(tact['tactic_id']) < 0) {
                        camp['tacticlist'].push(tact);
                        tacticidlist.push(tact['tactic_id']);
                    }
                }
            }
        }
    }

    public collapse(data) {
        data.isVisible = false;
    }

    public addNewCampaign(program) {
        this.localStorage.setStorage('selectedProgram', program);
        this.router.navigate(['/metadata-view/create-metadata/create/campaign-form']);
    }

    public openModal(type, pid, cid, tid) {
        this.detailsType = type;
        this.modalInfo.detailsType = type;
        this.modalInfo.pid = pid;
        this.modalInfo.cid = cid;
        this.modalInfo.tid = tid;
        this.showSlider = true;
    }

    public openEditFromRow(id, type, isClone, p, c?) {
        try {
            this.cleanGLobalClone();
            if (c) {
                this.localStorage.setStorage('selectedCampaign', c);
            }
            if (p) {
                this.localStorage.setStorage('selectedProgram', p);
            }
            switch (type) {
                case 'tactic':
                    this.router.navigateByUrl('/metadata-view/create-metadata/tactic-form/edit/details/' + id + '/' + isClone);
                    break;
                case 'journey':
                    this.router.navigateByUrl('/metadata-view/create-metadata/edit/campaign-form/' + id + '/' + isClone);
                    break;
                case 'program':
                    this.router.navigateByUrl('/metadata-view/create-metadata/edit/program-form/' + +id + '/' + isClone);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    public checkTacticsOrJourneys(p, c, t?) {
        let checked = false;
        if (this.globalCloneData[0].program_id === p.program_id) {
            const index = this.globalCloneData[0].journeys.findIndex(x => x.journey_id === c.campaign_id);
            if (index > -1) {
                if (t) {
                    if (this.globalCloneData[0].journeys[index].tactic_ids.indexOf(t.tactic_id) > -1) {
                        checked = true;
                    }
                } else {
                    if (this.globalCloneData[0].journeys[index].editable) {
                        checked = true;
                    }
                }
            }
        }
        return checked;
    }

    public expandCampaign(campaign, cid, index, tpid?) {
        try {
            campaign['isVisible'] = true;
            campaign['isLoading'] = true;
            campaign['tacticlist'] = [];
            this.loadCampsAndTactics(cid, 'campaign', false, false, true, index, false, tpid);

        } catch (error) {
            console.log(error);
        }
    }

    public addTactic(campaign, program) {
        this.localStorage.setStorage('selectedCampaign', campaign);
        this.localStorage.setStorage('selectedProgram', program);
        this.router.navigate(['/metadata-view/create-metadata/tactic-form/create/details']);
    }

    public getWaveSendDate(selectedtactic) {
        const tactic_id = selectedtactic['tactic_id'];
        let wave_send_date = new Date(7000, 1, 1);
        let isdatevalid = false;

        for (const tactic of this.tactics) {
            if (tactic['tactic_id'] === tactic_id) {
                if (tactic['wave_send_date']) {
                    const wave_send_date_sel = new Date(tactic['wave_send_date']);
                    if (wave_send_date_sel < wave_send_date) {
                        wave_send_date = wave_send_date_sel;
                    }
                    isdatevalid = true;
                }
            }
        }

        if (isdatevalid) {
            return wave_send_date.getMonth() + 1 + '/' + wave_send_date.getDate() + '/' + wave_send_date.getFullYear();
        } else {
            return 'NA';
        }
    }

    public getWaveEndDate(selectedtactic) {
        const tactic_id = selectedtactic['tactic_id'];
        let wave_end_date = new Date(1000, 1, 1);
        let isdatevalid = false;

        for (const tactic of this.tactics) {
            if (tactic['tactic_id'] === tactic_id) {
                if (tactic['wave_end_date']) {
                    const wave_send_date_sel = new Date(tactic['wave_end_date']);
                    if (wave_send_date_sel > wave_end_date) {
                        wave_end_date = wave_send_date_sel;
                    }
                    isdatevalid = true;
                }

            }
        }

        if (isdatevalid) {
            return wave_end_date.getMonth() + 1 + '/' + wave_end_date.getDate() + '/' + wave_end_date.getFullYear();
        } else {
            return 'NA';
        }
    }

    public loadAdvDropDown() {
        const data = JSON.parse(localStorage.getItem('userdata'));
        const lovData = this.localStorage.getStorage('lovs');
        this.brand_list = data.allBrandName;
        this.indication_list = data.allIndicationName;
        this.vendor_list = lovData.allVendor;
        this.product_list = data.allProductName;
        this.department_list = lovData.allDepartment;
    }

    public filterList(field, value) {
        const allDropdownData = JSON.parse(localStorage.getItem('userdata'));

        switch (field) {
            case 'brand_list':
                if (value.length > 0) {
                    this.brandId = value;
                } else {
                    this.brandId = [];
                }
                break;
            case 'indication_list':
                if (value.length > 0) {
                    this.indicationId = value;
                } else {
                    this.indicationId = [];
                }
                break;
            case 'vendor_list':
                if (value.length > 0) {
                    this.vendorId = value;
                } else {
                    this.vendorId = [];
                }
                break;

            case 'department':
                if (value.length > 0) {
                    this.departmentId = value;
                } else {
                    this.departmentId = [];
                }
                break;
            case 'product_list':
                break;
            case 'from_date':
                this.fromDate = value.formatted;
                break;
            case 'to_date':
                this.toDate = value.formatted;
                break;

        }
        let getAll = true;
        if (this.brandId.length > 0 || this.indicationId.length > 0 || this.vendorId.length > 0 || this.departmentId.length > 0) {
            getAll = false;
        }
        if(this.selectedTacticList.length == 0){
            this.bulkExportRowSelection = false;
        }
        if (this.brandId.length == 0 && this.indicationId.length == 0 && this.vendorId.length == 0 && this.departmentId.length == 0) {
            this.bulkExportRowSelection = false;
        }
        const payload = {
            brand_id: this.brandId,
            indication_id: this.indicationId,
            vendor_id: this.vendorId,
            department_id: this.departmentId,
            queryAll: getAll
        };
        this.service.queryAllData(payload).subscribe(data => {
            this.filteredObject = data.data;
            this.initializeProgram(true);
        }, error => {
            console.log(error);
        });
    }

    public exportData(callApi = false) {
        this.showNext = true;

        if (callApi) {
            this.showErrorMessage = false;
            this.showSuccessMessage = false;
            this.selectedProgram = [];
            this.selectedCampaign = [];
            this.selectedTactic = [];
            this.generateSelectedItems();
            const column = [];
            this.columnList.splice(54,1);
            this.columnList.forEach(item => {
                if (this.selectedColumns.indexOf(item) !== -1) {
                    column.push(item);
                }
            });
            const payload = {
                program_id: this.selectedProgram,
                campaign_id: this.selectedCampaign,
                tactic_id: this.selectedTactic,
                selected_columns: column,
                file_format: this.fileSelectFormat
            };
            this.service.downloadExtractData(payload).subscribe(data => {
                if (this.fileSelectFormat === 'xlsx') {
                    window.open(data.data['url'], '_self');
                    this.showSuccessMessage = true;
                } else if (this.fileSelectFormat === 'txt') {
                    const textExtract = document.createElement('a');
                    const txtFile = new Blob([data.data], {type: 'text/plain'});
                    textExtract.href = URL.createObjectURL(txtFile);
                    const currentDate = new Date();
                    let transformedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
                    transformedDate = transformedDate.split('-').join('_');
                    textExtract.download = 'export_data_' + transformedDate;
                    textExtract.click();
                    this.showSuccessMessage = true;
                }
            }, error => {
                this.showErrorMessage = true;
            });
        }
    }

    public columnSelected(event, item) {
        this.showSuccessMessage = false;
        this.showErrorMessage = false;
        if (this.selectedColumns.includes(item)) {
            this.selectedColumns.splice(this.selectedColumns.indexOf(item), 1);
        } else {
            this.selectedColumns.push(item);
        }

        if (this.selectedColumns.length === this.columnList.length) {
            this.allSelected = true;
        } else {
            this.allSelected = false;
        }
    }

    public selectAll(event) {
        this.isSelectAll = event.target.checked;
        this.allSelected = event.target.checked;
        this.selectedColumns = [];
        // // this.selectedColumns = this.selectedColumns.concat(this.mandatoryColumns);
        if (this.isSelectAll) {
            this.selectedColumns = this.selectedColumns.concat(this.columnList);
        } else {
            this.selectedColumns = this.selectedColumns.concat(this.mandatoryColumns);
        }
    }


    public isMandatoryColumn(item) {
        return this.mandatoryColumns.includes(item);
    }
}
