import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of , mergeMap } from 'rxjs';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {
  preloadedModules: string[] = [];

  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay
        ? timer(300).pipe(mergeMap(_ => load()))
        : load();
    return route.data && route.data.preload 
        ? loadRoute(route.data.delay)
        : of(null);
  }
}