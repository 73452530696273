<div class="login" *ngIf="!userCanNavigate">
    <div class="logo-header">
    <div class="header-lhs">
        <div class="zs-logo-img">
            <!-- [routerLink]="['/homepage']" -->
            <img src="assets/icons/shared/zs-logo.png"/>
            <!-- assets/icons/shared/zs-logo.png -->
        </div>
        <p class="header-text">Metadata Portal</p>
    </div>
</div>
<div class="loading" *ngIf="!userCanNavigate">Loading . . .</div>
</div><router-outlet *ngIf="userCanNavigate"></router-outlet>
