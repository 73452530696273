/** All API calls will come here */
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../../shared/services/http.service';

/**  Import all models */

/**  */

@Injectable({
    providedIn: 'root'
})
export class FetchApiDataService {

    /** current selected day in month and week view  */
    public static selectedDay = new Subject();


    constructor(private _http: HttpService) {

    }


    loadAllPrograms(data: any) {
        return this._http.post('loadAllPrograms', data);
    }

    queryAllData(data: any) {
        return this._http.post('queryAllData', data);
    }

    downloadExtractData(data: any) {
        return this._http.post('downloadExtractData', data);
    }

    getUserData() {
        return this._http.get('getUserData');
    }


    loadCamps(data) {
        return this._http.post('getCampaignsByProgramIDandUserInfo', data);
    }

    loadTactics(entity_type, cid) {
        if (entity_type === 'program') {
            return this._http.get('getTacticsByProgramID', cid);
        } else if (entity_type === 'campaign') {
            return this._http.get('getTacticByCampaignID', cid);
        } else if (entity_type === 'tactic') {
            return this._http.get('getTacticByTacticID', cid);
        }
    }

    getProgramByProgramID(pid) {
        return this._http.get('getprogrambyprogramid', pid);
    }

    getCampaignsByProgramIDandUserInfo(data) {
        return this._http.post('getCampaignsByProgramIDandUserInfo', data);
    }

    getCampaignByCampaignID(id): Observable<any> {
        return this._http.get('getCampaignByCampaignID', id);
    }


    deleteProgram(data) {
        return this._http.post('deleteProgramData', data);
    }


    deleteCampaign(data) {
        return this._http.post('deleteCampaignData', data);
    }


    deleteTactic(data) {
        return this._http.post('deleteTacticData', data);
    }

    loadAllData(data) {
        return this._http.post('loadAllData', data);
    }

    loadAdvSearchData(data) {
        return this._http.post('loadAllData', data);
    }


    getDraftsData(data) {
        return this._http.post('getDraftsData', data);
    }

    saveTacticDetail(data) {
        return this._http.post('saveTacticDetails', data);
    }

    loadDropdowns(data) {
        return this._http.post('getAllTacticDropdownValues', data);
    }
    getTacticDetailsMapping() {
        return this._http.get('getTacticDetailsMapping');
    }
    getWaveDetail(waveId): Observable<any> {
        return this._http.get('getWaveDetailByTacticId', waveId);
    }

    deleteWaveDetail(data) {
        return this._http.post('deleteWaveDetailData', data);
    }

    saveWaveDetailData(data) {
        return this._http.post('addWaveDetails', data);
    }


    saveExposureDetail(data) {
        return this._http.post('addwaveofferdetails', data);
    }

    getExposureDetail(id) {
        return this._http.get('getExposureDetail', id);
    }

    createProgram(data) {
        return this._http.post('addProgram', data);
    }


    /** Create Campaign API Call */
    saveNewCampaign(data) {
        return this._http.post('saveNewCampaign', data);
    }

    // -----==========  Ends Here -----================ */

    loadAllTilesData(data) {
        return this._http.post('loadAllTilesData', data);
    }

    getExtracts(params) {
        return this._http.post('getExtracts', params);
    }

    getExpCodesInTacticId(params) {
        return this._http.post('getExpCodesInTacticId', params);
    }

    loadAllNotification() {
        return this._http.get('getNotifications');
    }

    deleteNotification(data) {
        return this._http.post('deleteNotification', data);
    }

    setSeenNotifications(data) {
        return this._http.post('setSeenNotifications', data);
    }

    getBulkUploadPreference() {
        return this._http.get('getBulkUploadPreference');
    }

    saveBulkUploadPreference(data) {
        return this._http.post('saveBulkUploadPreference', data);
    }

    stopDataExport(data) {
        return this._http.post('stopDataExport', data);
    }

    getMyprogramFilters(data){
        return this._http.post('programFilters',data);
    }
    getApproveidOnSearch(data){
        // getApproveIdSearch
        return this._http.post('getApproveIdSearch',data);
    }
    saveProgramSequence(data){
        return this._http.post('myprogramsPinSequence',data);
    }
    getTacticCodes(data){
        return this._http.post('getTacticExposureCode',data);
    }
}
