import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './shared/services/auth-gaurd.service';
import { HttpService } from './shared/services/http.service';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { UtilsService } from './shared/services/utils.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpInterceptorService } from './shared/services/httpinterceptor.service';
import { DatePipe } from '@angular/common';
import { SharedModule } from './shared';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        DragAndDropModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        RouterModule,
        FontAwesomeModule,
        NgbModule,
        NoopAnimationsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA],
    providers: [
        AuthGuard,
        HttpService,
        UtilsService,
        CookieService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
