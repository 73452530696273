import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent implements OnInit {
  @Input() totalrecords; //Number of total records
  @Output() offsetchange = new EventEmitter(); //event for changing the page. 

  // Constants
  @Input() totalItemsInAPage = 10;  //total items per page, default it will be 10
  @Input() selectedPage = 1; // The page number being selected currently
  @Input() showDetail = true; // Flag for showing details
  @Input() gridKey;

  maxDisplayedPages = 5;


  // Page Variables
  totalPages;
  pageNumbers = [];

  // last/first page of the current group
  prevPageDisabler = this.maxDisplayedPages;


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedPage === undefined || this.selectedPage === 0) {
      this.selectedPage = 1;
    }
      this.totalPages = Math.ceil(this.totalrecords / this.totalItemsInAPage);
    this.selectPageNumbersToDisplay();
  }

  ngOnInit() {
    if (this.selectedPage === undefined || this.selectedPage === 0) {
      this.selectedPage = 1;
    }
    this.selectPageNumbersToDisplay();
  }

  /**
   * Function for displaying page number legends.
   */
  selectPageNumbersToDisplay() {
    let pageStartIndex;
    let pageEndIndex;

    const remainder = this.selectedPage % this.maxDisplayedPages;

    if (remainder === 0) {
      pageEndIndex = this.selectedPage + 1;
      pageStartIndex = this.selectedPage - this.maxDisplayedPages + 1;

    } else {
      pageStartIndex = Math.floor(this.selectedPage / this.maxDisplayedPages) * 5 + 1;

      if (this.totalPages - pageStartIndex >= this.maxDisplayedPages) {
        pageEndIndex = pageStartIndex + this.maxDisplayedPages;
      } else {
        pageEndIndex = this.totalPages + 1;
      }
    }


    this.pageNumbers = [];
    for (let i = pageStartIndex; i < pageEndIndex; i++) {
      this.pageNumbers.push(i);
    }

    this.getPageNumbers();
  }

  /**
   * Function invokes when user select one page.
   * 
   * @param pageNumber User selected page number
   */

  selectPage(pageNumber) {
    if (this.selectedPage != pageNumber) {
      this.selectedPage = pageNumber;
      let dataToEmit = {
        showingFrom: this.showingFrom,
        showingTo: this.showingTo,
        selectedPage: this.selectedPage,
        gridKey: this.gridKey
      }
      this.offsetchange.emit(dataToEmit);
    }
  }

  /**
   * goto previous page.
   */
  prevPages() {
    if (this.pageNumbers[0] > 1) {
      const temparr = this.pageNumbers;
      this.pageNumbers = [];

      for (let i = this.maxDisplayedPages; i > 0; i--) {
        this.pageNumbers.push(temparr[0] - i);
      }
    }
    this.selectPage(this.pageNumbers[this.pageNumbers.length - 1]);
    this.getPageNumbers();
  }

  /**
   * goto next page.
   */

  nextPages() {
    // Check if Last Page has been reached
    if (this.pageNumbers[this.pageNumbers.length - 1] < this.totalPages) {
      const temparr = this.pageNumbers;
      this.pageNumbers = [];

      // Loop through Existing Pages
      for (let i = 0; i < temparr.length; i++) {
        // Check if Last Page has been reached else Break
        if (temparr[i] + this.maxDisplayedPages - 1 < this.totalPages) {
          this.pageNumbers.push(temparr[i] + this.maxDisplayedPages);
        } else {
          break;
        }
      }
    }
    this.selectPage(this.pageNumbers[0]);
    this.getPageNumbers();

  }

  /**
   * goto last page
   */

  lastPage() {
    if (this.pageNumbers[this.pageNumbers.length - 1] === this.totalPages) {
      return;
    } else {
      if (this.pageNumbers[this.pageNumbers.length - 1] < this.totalPages) {
        const temparr = this.pageNumbers;
        this.pageNumbers = [];

        // Loop through Existing Pages
        for (let i = 0; i < temparr.length; i++) {
          // Check if Last Page has been reached else Break
          if (temparr[i] + this.maxDisplayedPages - 1 < this.totalPages) {
            this.pageNumbers.push(temparr[i] + this.maxDisplayedPages);
          } else {
            break;
          }
        }
      }
      this.lastPage();
    }
    this.selectPage(this.pageNumbers[this.pageNumbers.length - 1]);
    this.getPageNumbers();
  }

  /**
   * goto first page.
   */

  firstPage() {
    if (this.totalPages > this.maxDisplayedPages) {
      this.pageNumbers = [];
      for (let i = 1; i <= this.maxDisplayedPages; i++) {
        this.pageNumbers.push(i);
      }
    } else {
      this.pageNumbers = [];
      for (let i = 1; i <= this.totalPages; i++) {
        this.pageNumbers.push(i);
      }
    }
    this.selectPage(1);
    this.getPageNumbers();
  }


  // to enable/disable next prev buttons
  getPageNumbers() {
    this.prevPageDisabler = this.pageNumbers[this.pageNumbers.length - 1];
  }

  //showing pagination details
  get showingFrom() {
    if (this.selectedPage === 1) {
      return 1;
    } else {
      return (this.selectedPage - 1) * this.totalItemsInAPage + 1;
    }
  }

  //showing pagination details
  get showingTo() {
    return this.selectedPage * this.totalItemsInAPage > this.totalrecords ? this.totalrecords : this.selectedPage * this.totalItemsInAPage;
  }
}
