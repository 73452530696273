import { FormArray, FormControl, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 *  Validation function for all form fileds
 */
export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormArray) {
            for (let i = 0; i < control.length; i++) {
                validateAllFormFields(<FormGroup>control.at(i));
            }
        } else if (control instanceof FormGroup) {
            validateAllFormFields(control);
        }
    });
}

/**
 * Function to empty all form fields 
 */

export function emptyNameValidator(control: AbstractControl): { [key: string]: any } | null {
    return control.value && control.value.trim().length <= 0 ? { emptyName: { value: control.value } } : null;
}

/**
 * A Validator that ensures that all controls have different values
 */
export function differentValueValidator(controlNames: string[]): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
        const uniqueValues = new Set();
        const allValues = [];
        controlNames.forEach(name => {
            const v = group.get(name).value;
            uniqueValues.add(v);
            allValues.push(v);
        });
        return uniqueValues.size !== allValues.length ? { differentValue: { values: allValues } } : null;
    };
}

/**
 * Returns a function which to be passed to sort() which then sorts list of
 * objects with the give key. Assumes the value accessed by the key is a string
 * NOTE: move this function to utils module after refactoring.
 */
export function dropdownSorter(key) {
    return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
}
