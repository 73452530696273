import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CONTAINER_DATA } from '../../../container-data';
import { FetchApiDataService } from '../utilities/fetch-api-data.service';
import { StringConstants } from '../../../../../environments/StringConstants';
import { validateAllFormFields, dropdownSorter } from '../../../validators';
import { StorageService } from '../../../services/storage.service';

@Component({
    selector: 'app-edit-user-request',
    templateUrl: './edit-user-request.component.html',
    styleUrls: ['./edit-user-request.component.sass']
})
export class EditUserRequestComponent implements OnInit {

    @ViewChild('userForm') userForm: any;

    userControl = new FormControl();
    users: any[];
    message: string;
    isRequestSucccess: boolean;
    lov = {
        geo: [],
        brand: []
    }
    mdp = {
        geo: [],
        brand: []
    }
    cmaps = {
        geo: [],
        brand: []
    }

    constructor(
        @Inject(CONTAINER_DATA) private data: any,
        private service: FetchApiDataService,
        private localstorage: StorageService
    ) { }

    convertToString(array) {
        for (let i = 0; i < array.length; i++) {
            array[i] = array[i].toString();
        }
        return array;
    }

    ngOnInit() {

        const userinfo = this.localstorage.getStorage('userdata');
        const lovs = this.localstorage.getStorage('lovs');
        this.transformCmapsToVeevaName(lovs.allGeo, 'Geographies');
        this.transformCmapsToVeevaName(lovs.allProduct, 'Brands');
        this.lov.geo = lovs.allGeo;
        this.lov.brand = lovs.allProduct;

        const payload = {
            geo: userinfo.allGeoId,
            // gbu: userinfo.allGbuId,
            brands: userinfo.allBrandId,
            indications: userinfo.allIndicationId
        };

        this.service.getAllUsers(payload).subscribe(resp => {
            this.users = [];
            for (let i = 0; i < resp['result'].allNames.length; i++) {
                this.users.push({
                    name: resp['result'].allNames[i],
                    profile_id: resp['result'].allProfileId[i]
                });
            }
            this.users = this.users.sort(dropdownSorter('name'));
        });
    }

    transformCmapsToVeevaName(entity, label) {
        if( entity != undefined ){
            for (let i = 0; i < entity.length; i++) {
                if(label == 'Geographies') { 
                    if (entity[i].geo_source == 'CMAPS') {
                        entity[i].geo_source = 'Veeva ' + label;
                    }
                    if(entity[i].geo_source == 'MDP') {
                        entity[i].geo_source = 'MDP ' + label;
                    }
                } else if(label == 'Brands') {
                    if (entity[i].brand_source == 'CMAPS') {
                        entity[i].brand_source = 'Veeva ' + label;
                    }
                    if(entity[i].brand_source == 'MDP') {
                        entity[i].brand_source = 'MDP ' + label;
                    }
                }
            }
            return entity;

        }
    }

    // Segregate Veeva geographies from MDP geographies
    seperateBrand(data) {
        this.cmaps.brand = [];
        this.mdp.brand = [];
        for (let i = 0; i < this.lov.brand.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data[j] == this.lov.brand[i].brand_id) {
                    if (this.lov.brand[i].brand_source == 'Veeva Brands') {
                        this.cmaps.brand.push(data[j]);
                    } else {
                        this.mdp.brand.push(data[j]);
                    }
                }
            }
        }
    }

    // Segregate Veeva Brands from MDP brands
    seperateGeo(data) {
        this.cmaps.geo = [];
        this.mdp.geo = [];
        for (let i = 0; i < this.lov.geo.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data[j] == this.lov.geo[i].geo_id) {
                    if (this.lov.geo[i].geo_source == 'Veeva Geographies') {
                        this.cmaps.geo.push(data[j]);
                    } else {
                        this.mdp.geo.push(data[j]);
                    }
                }
            }
        }
    }

    // function to update form data
    sendUpdateRequest() {
        const form = this.userForm.form;
        this.seperateGeo(form.value.geographies);
        this.seperateBrand(form.value.brands);
        if (this.userForm.hasChanged) {
            this.message = 'Please change atleast one field.';
            setTimeout(() => this.message = undefined, 3000);
            return;
        }

        if (form.invalid) {
            validateAllFormFields(form);
            return;
        }


        let userData = JSON.parse(localStorage.getItem('userdata'));
        const payload = {
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            email: form.value.email,
            role: form.value.role,
            mdp_geo: this.mdp.geo,
            cmaps_geo: this.cmaps.geo,
            mdp_brand: this.mdp.brand,
            cmaps_brand: this.cmaps.brand,
            indications: form.value.indications,
            profileId: this.userControl.value,
            profile_id: userData.profileId,
            name: userData.name
        }

        // const payload = { ...form.value, profileId: this.userControl.value };
        this.service.createUserEditRequest(payload).subscribe(resp => {
            this.isRequestSucccess = resp['success'];
            if (resp['success']) {
                this.message = 'Your request has been successfully submitted.';
                const notificationParams = {
                    id: resp['result'],
                    targetRole: StringConstants.ADMIN_ROLE,
                    description: 'Edit User'
                };
                this.service.newRequestNotification(notificationParams).subscribe();
                this.userForm.initialFormValue = { ...this.userForm.form.value };
            } else {
                this.message = 'Something went wrong. Please try again later.';
            }
            this.userControl.setValue(null);
            setTimeout(() => {
                this.message = undefined;
                // this.close();
            }, 4000);
        });
    }

    // function to close modal
    close() {
        this.data.dialogRef.dispose();
    }

    // function to close modal using Escape key
    @HostListener('document:keyup', ['$event.key'])
    escHandler(key: string) {
        if (key === 'Escape') {
            this.close();
        }
    }

}
