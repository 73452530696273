import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent implements OnInit {

    @Input() showSideMenu: boolean = false;
    @Input() userName: string;

    @Output() closeEmitter = new EventEmitter();

    expanded: boolean = false;

    constructor(public router: Router, private _utils: UtilsService) {
    }

    ngOnInit() {
    }

    /** Ng on changes on every changes in showSideMenu  */
    ngOnChanges(changes) {
        try {
            this.showSideMenu = changes.showSideMenu.currentValue;
            if (changes.showSideMenu.currentValue) {
                setTimeout(() => {
                    var modal = document.getElementById('menu-modal-id');
                    modal.classList.add('dynam');
                    this.expanded = true;
                }, 100);
            } else {
                this.expanded = false;
                var modal = document.getElementById('menu-modal-id');
                modal.classList.remove('dynam');
            }
        } catch (error) {
            console.log(error);
        }
    }

    /** Close side menu */
    closeSideMenu() {
        try {
            this.showSideMenu = false;
            this.closeEmitter.emit(true);
        } catch (error) {
            console.log(error);
            this.closeEmitter.emit(true);
        }
    }

    /** Logout  */
    logOut() {
        try {
            localStorage.clear();
            this._utils.removeToken();
            // Navigate to Login page
            this.router.navigateByUrl('/auth/login');
        } catch (error) {
            console.log(error);
        }
    }

    /** Escape key  */
    @HostListener('document:keyup', ['$event'])
    escKeyEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.closeSideMenu();
        }
    }

    /** Outside Click */
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (event.target.classList.contains('outer-menu-modal')) {
            this.closeSideMenu();
        }
    }

    // Navigation function
    navigateTo(url){
        this.router.navigateByUrl(url);
    }
}
