import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {urlconstants} from '../../../environments/constants';
import {environment} from '../../../environments/environment';
import {UtilsService} from './utils.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    baseUrl;
    type = '';

    constructor(
        private http: HttpClient,
        public _utils: UtilsService
    ) {
    }

    // Delete function
    delete(urlkey: string, type = 'live'): Observable<any> {
        this.type = type;
        return this.http.delete(this.parseAPIUrl(urlkey), {observe: 'response'}).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError)
        );
    }

    // TODO - prepend app url
    get(urlkey: string, urlArgument = '', type = 'live'): Observable<any> {
        this.type = type;
        return this.http.get(this.parseAPIUrl(urlkey, urlArgument), {observe: 'response'}).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError.bind(this))
        );
    }

    post(urlkey: string, payload: any, urlArgument = undefined, type = 'live'): Observable<any> {
        this.type = type;
        return this.http.post(this.parseAPIUrl(urlkey, urlArgument), payload, {observe: 'response'}).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError.bind(this))
        );
    }

    put(urlkey: string, payload: any, type = 'live'): Observable<any> {
        this.type = type;
        return this.http.put(this.parseAPIUrl(urlkey), payload, {observe: 'response'}).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError.bind(this))
        );
    }

    /** handle error */
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        let str = window.location.href;
        const url = str = str.split('#')[0];
        if (error.status === 401) {
            console.log('401');
            this._utils.userTimeout();
        } else if (error.status === 500) {
            if (window.location.href.includes('manage-requests')) {
                window.location.href = url + '#/manage-requests/home';
            } else {
                window.location.href = url + '#/metadata-view/activity/home';
            }
        } else {
            return throwError(errorMessage);
        }
    }

    /** */
    handleResponse(resp) {
        if (resp.status === 200 || resp.status === 201 || resp.status === 202) {
            return resp.body;
        } else if(resp.status === 401) {
            console.log("401");
        }   else {
            window.alert(resp.statusText);
        }
    }

    public parseAPIUrl(urlkey, urlArgument = '') {
        let baseurl = 'http://localhost:5000';

        if (environment.mode === 'Client') {
            baseurl = 'http://localhost:5000';
        } else if (environment.mode === 'QA') {
            baseurl = 'https://api-verso-lilly.dev.zsservices.com';
        } else if (environment.mode === 'Stage') {
            baseurl = 'https://api-verso-lilly.dev.zsservices.com';
        } else if (environment.mode === 'Prod') {
            baseurl = 'https://api-verso-lilly.zsservices.com';
        } else {
            baseurl = 'http://localhost:5000';
        }

        this.baseUrl = baseurl;

        let url = baseurl;
        if (this.type === 'live') {
            url = url + urlconstants[urlkey];
        } else {
            url = 'http://localhost:4300/' + urlkey;
        }
        if (urlArgument) {
            url = url + '/' + urlArgument;
        }
        return url;
    }
}
