<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body" >
                <div class="modal-container" >
                    <header >
                        <img class="icon" src="assets/icons/manage-requests/Icon-LOVs-White-v1.0.svg" alt="LOV">
                        <div>
                            <h1>Request New LOV</h1>
                            <img class="btn-close" src="assets/icons/shared/close.svg" alt="close" (click)="close()">
                        </div>
                    </header>

                    <main style="height: 505px">
                        <p style="margin-top: 12px">Please enter the following details to request LOV</p>
                        <p class="response-message" [class.success-message]="isRequestSucccess" *ngIf="message">{{message}}</p>
                        <div class="type-container col-12 row">
                            <ng-select style="width: 100% !important"
                            placeholder="Select LOV Type" class="ng-select custom mt-1"
                            [items]="lovTypes" bindLabel="lov_name"  [formControl]="lovTypeControl" [searchable]="false"
                            >
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <span title="{{item.lov_name}}">{{item.lov_name}}</span>
                            </ng-template>
                        </ng-select>
                        </div>
                        <div class="col-12 row">
                            <ng-template appFormHost #singleLovContainer></ng-template>
                        </div>
                        <div class="col-12 row">
                            <div style="text-align: center;position: fixed;">
                                <button type="button" (click)="sendRequest()" [disabled]="!currentFormRef">Send Request</button>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>
