import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-save-modal',
    templateUrl: './save-modal.component.html',
    styleUrls: ['./save-modal.component.sass']
})
export class SaveModalComponent implements OnInit {

    @Input() show:boolean = false;
    @Input() entity_type: string;
    @Output() closeModal = new EventEmitter();


    constructor() {
    }

    ngOnInit() {
    }


    /*closeOpenModal() {
        this.closeModal.emit();
    }*/

}
