import { Component, OnInit, Input } from '@angular/core';
import {CommonMethods} from '../../../campaign-calendar/utilities/common-methods';

@Component({
  selector: 'week-calendar-tooltip-popover',
  templateUrl: './tooltip-popover.component.html',
  styleUrls: ['./tooltip-popover.component.sass']
})
export class TooltipPopoverComponent implements OnInit {

  @Input() openTooltip;
  @Input() tooltipInfo;
  @Input() clientX;
  @Input() tooltipWidth;
  @Input() tooltipHeight;
  @Input() clientY;
  @Input() arrowClientY;

  constructor(public _commonMethods: CommonMethods) { }

  ngOnInit() {
    // console.log('clientY is ', this.clientY);
    // console.log('this.arrowClientY is ',this.arrowClientY)
  }
}
