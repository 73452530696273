import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lovs } from '../../../../../models/lov';
import { StorageService } from '../../../../../metadata-view/utilities/storage.service';
import { LovTypeComponent } from '../lov-fields';
import { emptyNameValidator } from '../../../../validators';

@Component({
    selector: 'app-key-message-lov-request',
    templateUrl: './key-message-lov-request.component.html',
    styleUrls: ['./key-message-lov-request.component.sass']
})
export class KeyMessageLovRequestComponent implements OnInit, LovTypeComponent {

    @Input() lovType: any;

    form: FormGroup;
    items: FormArray;

    get value() {
        return this.form.value.items;
    }

    constructor(
        private fb: FormBuilder,
        private storage: StorageService
    ) {
        this.items = this.fb.array([this.createKeyMessage()]);
        this.form = this.fb.group({ items: this.items });
    }

    ngOnInit() {
         //variable purpose is undetermined
        const lovs: Lovs = this.storage.getStorage('lovs');
    }

    createKeyMessage(): FormGroup {
        return this.fb.group({
            keyMessage: [null, [Validators.required, emptyNameValidator]]
        });
    }

    getControl(index: number, controlName: string) {
        return this.form.get(`items.${index}.${controlName}`);
    }

    clear() {   // called from NewLovRequestComponent
        this.form.reset();
        setTimeout(() => {
            for (let i = this.items.length - 1; i > 0; i--) {
                this.items.removeAt(i);
            }
        }, 0);
    }

    addItem(index: number) {
        this.items.insert(index + 1, this.createKeyMessage());
    }

    removeItem(index: number) {
        this.items.removeAt(index);
    }

}
