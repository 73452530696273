import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {AuthGuard} from './shared/services/auth-gaurd.service';
import {SelectivePreloadingStrategyService} from './app-loading-strategy';

const routes: Routes = [
    // {
    //     path: 'auth',
    //     loadChildren: './auth/auth.module#AuthModule',
    // },

    {
        path: 'metadata-view',
        loadChildren: () => import('./metadata-view/metadata-view.module').then(x => x.MetadataViewModule),
        canActivate: [AuthGuard],
        data: {preload: true}
    },
    {
        path: 'calendar-view',
        loadChildren: () => import('./campaign-calendar/campaign-calendar.module').then(x => x.CampaignCalendarModule),
        canActivate: [AuthGuard],
        data: {preload: true, delay: true}
    },
    {
        path: 'manage-requests',
        loadChildren: () => import('./manage-requests/manage-requests.module').then(x => x.ManageRequestsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(x => x.UserModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(x => x.HelpModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(x => x.ContactModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'training',
        loadChildren: () => import('./training/training.module').then(x => x.TrainingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'cmaps',
        loadChildren: () => import('./cmaps/cmaps.module').then(x => x.CmapsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'home',
        loadChildren: () => import('./landing/landing.module').then(x => x.LandingModule),
        canActivate: [AuthGuard]
    },
    {path: 'error-info', 
    loadChildren: () => import('./error-info/error-info.module').then(x => x.ErrorInfoModule)},
    { path: '**', redirectTo: 'home', pathMatch: 'full'},
    { path: '', redirectTo: 'home', pathMatch: 'full'},
];

const routingConfig: ExtraOptions = {
    useHash: true,
    onSameUrlNavigation: 'reload',
    preloadingStrategy: SelectivePreloadingStrategyService
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routingConfig)],
    exports: [RouterModule],
    providers: [SelectivePreloadingStrategyService]
})
export class AppRoutingModule {
}
