import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Lovs, Brand, Indication } from '../../../../../models/lov';
import { StorageService } from '../../../../../metadata-view/utilities/storage.service';
import { dropdownSorter } from '../../../../validators';

@Component({
  selector: 'app-bi-lov-request',
  templateUrl: './bi-lov-request.component.html',
  styleUrls: ['./bi-lov-request.component.sass']
})
export class BiLovRequestComponent implements OnInit {
  @Input() lovType: any;

    form: FormGroup;
    brands: Brand[];
    indications: Indication[];
    items: FormArray;

    get value() {
        return this.form.value.items.map(item => ({
            brand: item.brand.brand_id || item.brand.brand_name,
            indication: item.indication.indication_id || item.indication.indication_name,
        }));
    }

    constructor(
        private fb: FormBuilder,
        private storage: StorageService
    ) {
        this.items = this.fb.array([this.createFbiInput()]);
        this.form = this.fb.group({ items: this.items });
    }

    ngOnInit() {
        const lovData: Lovs = this.storage.getStorage('lovs');
        console.log('lov data is ', lovData);
        this.brands = lovData.allBrands.sort(dropdownSorter('brand_name'));
        this.indications = lovData.allIndications.sort(dropdownSorter('indication_name'));
    }


    //function to declare input filelds
    createFbiInput(): FormGroup {
        return this.fb.group({
            brand: [null, Validators.required],
            indication: [null, Validators.required]
        });
    }

     //function purpose is undetermined
    getControl(index: number, controlName: string) {
        return this.form.get(`items.${index}.${controlName}`);
    }

     //function to reset form data
    clear() {
        this.form.reset();
        setTimeout(() => {
            for (let i = this.items.length - 1; i > 0; i--) {
                this.items.removeAt(i);
            }
        }, 0);
    }

     //function to add items to an array
    addItem(index: number) {
        this.items.insert(index + 1, this.createFbiInput());
    }

     //function to remove items from an array
    removeItem(index: number) {
        this.items.removeAt(index);
    }
}