import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-alert-box',
  templateUrl: './common-alert-box.component.html',
  styleUrls: ['./common-alert-box.component.sass']
})
export class CommonAlertBoxComponent implements OnInit {

  @Input() message;
  @Output() okEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
    console.log(this.message);
  }

  ok() {
    this.okEmitter.emit(true);
  }
}
