import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Brand, Indication, Lovs, Vendor} from '../../../../models/lov';
import {FetchApiDataService} from '../utilities/fetch-api-data.service';
import {StorageService} from '../../../services/storage.service';
import {dropdownSorter} from '../../../validators';
import {IMyDpOptions} from 'mydatepicker';
import {CommonMethods} from '../utilities/common-methods';

;

@Component({
    selector: 'app-misc-request-form',
    templateUrl: './misc-request-form.component.html',
    styleUrls: ['./misc-request-form.component.sass']
})
export class MiscRequestFormComponent implements OnInit {

    @Output() populateDataEmitter = new EventEmitter<any>();
    Weekly = 'Weekly';
    Daily = 'Daily';
    _profileId: number;

    @Input()
    set profileId(value: number) {
        // Form is populated when a user is being edited. In this case, an user may have already assigned values
        // that the current user doesn't has access to. So, populate the dropdowns with all available values and disable
        // those that the current user doesn't has access to.
        // Not doing so, results in null values being pre-filled. This is a workaround for the same.
        this.alternativeDropdownValues();
        this._profileId = value;
        this.populateForm(value);
        // Now, populate the form.
        setTimeout(() => this.populateForm(value), 0);
    }

    get hasChanged(): boolean {
        return this.hashCode(this.initialFormValue) === this.hashCode(this.form.value);
    }

    form: FormGroup;
    vendors: Vendor[];
    selectedVendor;
    selectedBrands;
    selectedIndications;
    brands: Brand[];
    indications: Indication[];
    initialFormValue: any = {};
    programName: string;
    selectedCampaign;
    programId;
    campaigns: [];
    startDate;
    endDate;
    frequency = 'Weekly';
    userInfo;
    public startDateOptions: IMyDpOptions;
    public endDateOptions: IMyDpOptions;

    constructor(private storage: StorageService, private service: FetchApiDataService, private genericService: CommonMethods) {
    }

    ngOnInit() {
        const lovs: Lovs = this.storage.getStorage('lovs');
        this.vendors = lovs.allVendors.sort(dropdownSorter('vendor_name'));
        const userData = this.storage.getStorage('userdata');
        this.brands = this.getLovData('brand', userData).sort(dropdownSorter('brand_name'));
        this.indications = this.getLovData('indication', userData).sort(dropdownSorter('indication_name'));
        this.getCampaignDetails(userData);
    }

    /**
     * Campaign Selection
     * @param event - event
     */
    campaginSelection(event) {
        if (event) {
            this.campaigns.forEach((item) => {
                if (item['campaign_id'] === this.selectedCampaign.campaign_id) {
                    this.selectedVendor = {'vendor_name': item['vendor_name'], 'vendor_id': item['vendor_id']};
                    this.selectedBrands = {'brand_id': item['brand_id'], 'brand_name': item['brand_name']};
                    this.selectedIndications = {'indication_id': item['indication_id'], 'indication_name': item['indication_name']};
                    this.programName = item['program_name'];
                    this.programId = item['program_id'];
                    this.startDate = this.genericService.mapDate(item['campaign_start_date']);
                    this.endDate = this.genericService.mapDate(item['campaign_end_date']);
                    const dateOptions = this.genericService.setOptions(item['campaign_start_date'], item['campaign_end_date']);
                    this.startDateOptions = dateOptions['startDateOptions'];
                    Object.assign(this.startDateOptions, {showClearDateBtn: false, editableDateField: false});
                    this.endDateOptions = dateOptions['endDateOptions'];
                    Object.assign(this.endDateOptions, {showClearDateBtn: false, editableDateField: false});
                }
            });
            this.populateData();
        }
    }

    /** Get campaign details */
    getCampaignDetails(payload) {
        this.service.getAllCampaigns(payload).subscribe(result => {
            this.campaigns = result['data'];
        });
    }

    // function to create date format
    fromDte(date, type) {
        if (type === 'startDate') {
            if (date.date.year || date.date.month || date.date.date) {
                this.startDate = {
                    date: {
                        year: date['date']['year'],
                        month: date['date']['month'],
                        day: date['date']['day']
                    }
                };
            }
            this.endDateOptions = {
                dateFormat: 'mm-dd-yyyy',
                disableUntil: {
                    year: this.startDate['date']['year'],
                    month: this.startDate['date']['month'],
                    day: this.startDate['date']['day']
                },
                minYear: new Date().getFullYear(),
                maxYear: new Date().getFullYear() + 40,
                showClearDateBtn: false,
                editableDateField: false
            };
        } else {
            if (date.date.year || date.date.month || date.date.date) {
                this.endDate = {
                    date: {
                        year: date['date']['year'],
                        month: date['date']['month'],
                        day: date['date']['day']
                    },
                    jsdate: date['jsdate']
                };
            }
            if (!this.startDate.hasOwnProperty('jsdate')) {
                Object.assign(this.startDate, {
                    jsdate: new Date(this.startDate.date.year, this.startDate.date.month - 1, this.startDate.date.day)
                });
            }
            if (!this.endDate.hasOwnProperty('jsdate')) {
                Object.assign(this.endDate, {
                    jsdate: new Date(this.endDate.date.year, this.endDate.date.month - 1, this.endDate.date.day)
                });
            }

            const dateOptions = this.genericService.setOptions(this.startDate, this.endDate);
            const _startDateOption = this.startDateOptions;
            this.startDateOptions = dateOptions['startDateOptions'];
            this.startDateOptions['disableUntil'] = _startDateOption['disableUntil'];
            Object.assign(this.startDateOptions, {showClearDateBtn: false, editableDateField: false});
        }
    }

    // function to capitalize a string
    capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // function to get LOV Data
    getLovData(type: string, userData: any) {
        const t = this.capitalize(type);
        const suffixes = ['name', 'id', 'code'];
        const result = [];
        for (let i = 0; i < userData[`all${t}Name`].length; i++) {
            const obj = {};
            suffixes.forEach(suffix => {
                const s = this.capitalize(suffix);
                obj[`${type}_${suffix}`] = userData[`all${t}${s}`][i];
            });
            result.push(obj);
        }
        return result;
    }

    hashCode(value: any): number {
        const str = JSON.stringify(value);
        /* tslint:disable */
        let hash = 0, i, chr;
        if (str.length === 0) {
            return hash;
        }
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
        /* tslint:enable */
    }

    radioBox(event) {
       
    }

    // function to populate form data
    populateData() {
        const finalData = {
            program_id: this.programId,
            programName: this.programName,
            campaign_id: this.selectedCampaign.campaign_id,
            campaign_name: this.selectedCampaign.campaign_name,
            start_date: this.genericService.reduceDate(this.startDate),
            end_date: this.genericService.reduceDate(this.endDate),
            frequency: this.frequency
        };
        this.populateDataEmitter.emit({
            formData: finalData
        });
    }


    // function to get alternate dropdown data
    alternativeDropdownValues() {
        // get all lov values for the current user
        const userLOVs = this.storage.getStorage('userdata');
        const userValues = {
            brandIds: new Set(userLOVs.allBrandId),
            indicationIds: new Set(userLOVs.allIndicationId),
        };
        // get set of all possible lov values.
        const globalLOVs = this.storage.getStorage('lovs');
        // disable the values that doesn't belong to the current user's set.
        // adding a disabled key-value pair allows ng-select to disable a certain option.
        this.brands = globalLOVs.allBrands.map(b => ({...b, disabled: !userValues.brandIds.has(b.brand_id)}))
            .sort(dropdownSorter('brand_name'));
        this.indications = globalLOVs.allIndications.map(i => ({...i, disabled: !userValues.indicationIds.has(i.indication_id)}))
            .sort(dropdownSorter('indication_name'));
    }

    populateForm(profileId: number) {
        this.service.getUserDetails(profileId).subscribe(resp => {
            const details = resp['result'];
            const gbis = {geographies: new Set(), brands: new Set(), indications: new Set()};
            details.gbis.forEach(gbi => {
                gbis.brands.add(gbi.brand_id);
                gbis.indications.add(gbi.indication_id);
            });
            const nameParts = details.basic.name.split(' ');
            this.form.patchValue({
                campaignName: nameParts.join(' '),
                vendors: details.vendors.map(v => v.vendor_id),
                brands: Array.from(gbis.brands),
                indications: Array.from(gbis.indications)
            });
            // NOTE: This is done after form has been populated because, some values
            // might be empty/undefined/null. Those keys will be present in the above
            // object but not if the form's value is used. This is critical because
            // a string representation of this value is used to determine if the form's
            // value has changed.
            this.initialFormValue = {...this.form.value};
        });
    }

    // function to deselect campaign
    deselectCampaign() {
        this.selectedVendor = undefined;
        this.startDate = undefined;
        this.endDate = undefined;
        this.selectedBrands = undefined;
        this.selectedIndications = undefined;
    }
}
