<div>
    <!-- <div class="form-row row program-details">
        <p>{{programName}}</p>
    </div> -->

    <!-- Campaign -->
    <div class="form-row row">
        <div class="form-field-container col-6">
            <label for="">Campaign Name: </label>
            <ng-select [items]="campaigns" [closeOnSelect]="true" [(ngModel)]="selectedCampaign" (change)="campaginSelection($event)" placeholder="Select Campaign"  bindLabel="campaign_name" (clear)="deselectCampaign()"></ng-select>
        </div>
        <div class="form-field-container col-6">
            <label for="">Vendor:</label>
            <ng-select [items]="vendors" placeholder="Select Vendor"  [(ngModel)]="selectedVendor" (ngModelChange)="populateData()" bindLabel="vendor_name" class="disable_field"></ng-select>
        </div>
    </div>

    <!-- Time Period -->
    <div class="form-row row">
        <div class="form-field-container col-6">
            <label>Start Date:</label>
            <my-date-picker
                [(ngModel)]="startDate"
                (ngModelChange)="populateData()"
                (dateChanged)="fromDte($event,'startDate')"
                [options]="startDateOptions"
                [disabled]="true">
            </my-date-picker>
        </div>
        <div class="form-field-container col-6">
            <label>End Date:</label>
            <my-date-picker
                [(ngModel)]="endDate"
                (ngModelChange)="populateData()"
                (dateChanged)="fromDte($event,'endDate')"
                [options]="endDateOptions"
                [disabled]="true">
            </my-date-picker>
<!--            <my-date-picker-->
<!--                [(ngModel)]="endDate"-->
<!--                (ngModelChange)="populateData()"-->
<!--                (dateChanged)="fromDte($event,'endDate')"-->
<!--                [options]="endDateOptions"-->
<!--                [disabled]="!selectedCampaign">-->
<!--            </my-date-picker>-->
        </div>
    </div>

    <!-- Brands and Indications -->
    <div class="form-row row">
        <div class="form-field-container col-6 disable_field">
            <label>Brands:</label>
            <ng-select [items]="brands" [(ngModel)]="selectedBrands" (ngModelChange)="populateData()" placeholder="Select Brand"  bindLabel="brand_name"  [closeOnSelect]="false" class="disable_field"></ng-select>
        </div>
        <div class="form-field-container col-6 disable_field">
            <label>Indications:</label>
            <ng-select [items]="indications" [(ngModel)]="selectedIndications" (ngModelChange)="populateData()" placeholder="Select Indication"  bindLabel="indication_name"  [closeOnSelect]="false" class="disable_field"></ng-select>
        </div>
    </div>

    <!-- Frequency -->
<!--    <div class="form-row row">-->
<!--        <div class="form-field-container col-6">-->
<!--            <div>-->
<!--                <label>Frequency</label>-->
<!--            </div>-->
<!--            -->
<!--            <div class="checkboxes">-->
<!--                <div class="radio-button">-->
<!--                    <input type="radio" name="freq" [(ngModel)]="frequency" [value]="Weekly" (change)='populateData()'/>-->
<!--                    <p>Weekly</p>-->
<!--                </div>-->

<!--                <div class="radio-button">-->
<!--                    <input type="radio" name="freq" [(ngModel)]="frequency" [value]="Daily" (change)="populateData()"/>-->
<!--                    <p>Daily</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <br/>
</div>
