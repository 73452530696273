<ng-container *ngIf='pageNumbers.length!=0'>
    <div class='d-flex pagination-container'>

        <div *ngIf="showDetail" style="margin-right: 28px;
        margin-top: 8px;">
            <span>Showing records {{showingFrom}} to {{showingTo}} of {{totalrecords}}</span>
        </div>

        <ng-container *ngIf='!(prevPageDisabler<=maxDisplayedPages)'>
            <img (click)='firstPage()' src="assets/icons/Back_Arrow.svg" title="First page" width="8px">
        </ng-container>

        <ng-container *ngIf='!(prevPageDisabler<=maxDisplayedPages);'>
            <img (click)='prevPages()' src="assets/icons/Back_Single_Arrow.svg" title="Previous page" width="5.5px">
        </ng-container>
        <div *ngFor="let pageNumber of pageNumbers; let i=index">
            <button class="pagination-button" [ngClass]="{'active-page': pageNumber === selectedPage}" (click)='selectPage(pageNumber)'>{{pageNumber}}</button>
        </div>

        <ng-container *ngIf='!(prevPageDisabler===totalPages)'>
            <img (click)='nextPages()' src='assets/icons/Next_Single_Arrow.svg' title="Next page" width="5.5px">
        </ng-container>

        <ng-container *ngIf='! (prevPageDisabler===totalPages)'>
            <img (click)='lastPage()' src='assets/icons/Next_Arrow.svg' title="Last page" width="8px">
        </ng-container>

    </div>
</ng-container>