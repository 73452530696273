import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {HttpService} from './shared/services/http.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    userCanNavigate: boolean;
    errorMessage: string;
    user: { username: string; password: string; };
    successfulLogin: boolean;
    loader: boolean;
    userInfo: any;

    constructor(private fetchAPIData: HttpService, private router: Router) {
    }

    ngOnInit() {
         if (environment.production) {
            this.fetchAPIData.get('getUserInfo').subscribe((data) => {
                if (data) {
                    this.userCanNavigate = true;
                    if (data && data.status_code === 200) {
                        this.errorMessage = 'Signed in Successfully';
                        this.user = {
                            username: '',
                            password: ''
                        };
                        this.successfulLogin = true;
                        this.loader = true;

                        this.userInfo = data;
                        this.userInfo['data']['expiration'] = new Date();

                        localStorage.setItem('userdata', JSON.stringify(this.userInfo['data']));
                        localStorage.setItem('tacticStatusArray', "[1,2,3,4]");
                         if (this.userInfo['data']['allRolesId'][0] === 5) {
                                    localStorage.setItem('isViewOnly', 'true');
                        } else {
                                localStorage.setItem('isViewOnly', 'false');
                        }
                        // if (this.userInfo['data']['allRolesId'][0] === 2) {
                        //     this.router.navigateByUrl('/manage-requests/home');
                        // } else 
                        if (this.userInfo['data']['allRolesId'][0] === 3) {
                            this.router.navigateByUrl('/metadata-view/activity/my-campaigns');
                        } else {
                            // this.router.navigateByUrl('/metadata-view/activity/home');
                            // this.router.navigateByUrl('/home');
                        }
                    } else {
                        this.errorMessage = data['message'] || 'Service Error! Contact Administrator';
                        this.successfulLogin = false;
                    }
                }
            }, error => {
                console.log('error part');
                this.userCanNavigate = false;
            });
        } 
        else {
            this.userCanNavigate = true;
            localStorage.setItem('userdata', '{"allBrandCode":["Propellium","Compellium","Lectrazine"],"allBrandId":[1,2,3],"allBrandName":["Propellium","Compellium","Lectrazine"],"allCountryId":["00C000000000657","00C000000000662","00C000000000801","00C000000000653","00C000000000668","00C000000000650","00C000000000659","00C000000000663","00C000000000655","00C000000000654","00C000000000651","00C000000000664","00C000000000661","00C000000000667","00C000000000665","00C000000000652","00C000000000656","00C000000000658","00C000000000666","00C000000000000","00C000000000649","00C000000000701","00C000000000660"],"allCountryName":["United States","France","Russia","China","Finland","Germany","Austria","Japan","Switzerland","Sweden","Global","Belgium","Spain","Denmark","Ireland","Canada","United Kingdom","Italy","Norway","None","Australia","India","Netherlands"],"allGeoCode":["USA"],"allGeoId":[1],"allGeoName":["USA"],"allIndicationCode":["NSCLC","MPAC","MEL","MBC","Lung"],"allIndicationId":[5,2,4,3,1],"allIndicationName":["NSCLC","MPAC","MEL","MBC","Lung"],"allProductId":["00P000000000401","00P000000000301","00P000000000000","00P000000000201"],"allProductName":["Sample 1","Lectrazine","None","Axanol"],"allRolesId":[4,1],"allRolesName":["Content Librarian","Campaign Manager"],"allVendorCode":[null],"allVendorId":[null],"allVendorName":[null],"email_address":"purnika.verma@zs.com","name":"Purnika Verma","profileId":11,"user_name":"purnika.verma@zs.com","expiration":"2020-10-23T07:54:50.772Z"}');
            const lovString = JSON.stringify({
                'allBrands': [{
                    'brand_code': 'TRU',
                    'brand_id': '1',
                    'brand_name': 'Trulicity',
                    'brand_source': 'MDP',
                    'is_deleted': false
                }, {
                    'brand_code': 'VER',
                    'brand_id': '2',
                    'brand_name': 'Verzenio',
                    'brand_source': 'MDP',
                    'is_deleted': false
                }, {
                    'brand_code': 'All',
                    'brand_id': '3',
                    'brand_name': 'All',
                    'brand_source': 'MDP',
                    'is_deleted': false
                }],
                'allFranchises': [{
                    'gbu_code': 'Oncology',
                    'gbu_id': 1,
                    'gbu_name': 'Oncology',
                    'is_deleted': false
                }, {'gbu_code': 'Diabetes', 'gbu_id': 2, 'gbu_name': 'Diabetes', 'is_deleted': false}],
                'allGeo': [{
                    'geo_code': 'USA',
                    'geo_id': '1',
                    'geo_name': 'USA',
                    'geo_source': 'MDP',
                    'is_deleted': false
                }],
                'allIndications': [{
                    'indication_code': 'DBM',
                    'indication_id': 1,
                    'indication_name': 'Diabetes Mellitus',
                    'is_deleted': false
                }, {
                    'indication_code': 'BCM',
                    'indication_id': 2,
                    'indication_name': 'Breast Cancer - Metastatic',
                    'is_deleted': false
                }],
                'allKeymessages': [{
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 121,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 118,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 112,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 106,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 103,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 100,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 94,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 88,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 82,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 76,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 70,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 64,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 58,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 54,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 52,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 50,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 48,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 109,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 115,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 46,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 97,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 34,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 31,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 28,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Access',
                    'keymessage_id': 2,
                    'keymessage_name': 'Access'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Disease',
                    'keymessage_id': 4,
                    'keymessage_name': 'Disease'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 91,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Dosing',
                    'keymessage_id': 5,
                    'keymessage_name': 'Dosing'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Efficacy',
                    'keymessage_id': 6,
                    'keymessage_name': 'Efficacy'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 85,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 67,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 73,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 79,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Patient Support',
                    'keymessage_id': 7,
                    'keymessage_name': 'Patient Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Safety',
                    'keymessage_id': 8,
                    'keymessage_name': 'Safety'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Initiation',
                    'keymessage_id': 17,
                    'keymessage_name': 'Initiation'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Unmet Need',
                    'keymessage_id': 11,
                    'keymessage_name': 'Unmet Need'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 117,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Treatment Landscapes',
                    'keymessage_id': 10,
                    'keymessage_name': 'Treatment Landscapes'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 114,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 111,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Study Design',
                    'keymessage_id': 1,
                    'keymessage_name': 'Study Design'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 61,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Resources',
                    'keymessage_id': 9,
                    'keymessage_name': 'Resources'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 105,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 55,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Adherence',
                    'keymessage_id': 13,
                    'keymessage_name': 'Adherence'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Real World Evidence',
                    'keymessage_id': 3,
                    'keymessage_name': 'Real World Evidence'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 99,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 96,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 33,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Identification',
                    'keymessage_id': 22,
                    'keymessage_name': 'Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 93,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Customer support',
                    'keymessage_id': 23,
                    'keymessage_name': 'Customer support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 90,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 51,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 87,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 102,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Mechanism of Action',
                    'keymessage_id': 18,
                    'keymessage_name': 'Mechanism of Action'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 101,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 44,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 56,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 98,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 120,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 49,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Ease of Administration',
                    'keymessage_id': 15,
                    'keymessage_name': 'Ease of Administration'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 26,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 69,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 27,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 66,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 47,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 75,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 74,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 30,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Operational Logistics',
                    'keymessage_id': 19,
                    'keymessage_name': 'Operational Logistics'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Patient Affordability',
                    'keymessage_id': 20,
                    'keymessage_name': 'Patient Affordability'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 35,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 36,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 38,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 39,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 43,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 62,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 63,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 65,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 68,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 71,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 72,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 41,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 45,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 29,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 32,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 37,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 80,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Patient Type',
                    'keymessage_id': 21,
                    'keymessage_name': 'Patient Type'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 92,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 78,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 25,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Diagnostic',
                    'keymessage_id': 14,
                    'keymessage_name': 'Diagnostic'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 59,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 95,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 89,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 86,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 83,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Formulary',
                    'keymessage_id': 12,
                    'keymessage_name': 'Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 104,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 108,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 110,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 113,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 116,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 119,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 122,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 84,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 53,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 81,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Product Attributes',
                    'keymessage_id': 24,
                    'keymessage_name': 'Product Attributes'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 77,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'access or formulary',
                    'keymessage_id': 40,
                    'keymessage_name': 'Access Or Formulary'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient or customer support',
                    'keymessage_id': 107,
                    'keymessage_name': 'Patient Or Customer Support'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 60,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 42,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 57,
                    'keymessage_name': 'Patient Type Or Identification'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'Guidelines',
                    'keymessage_id': 16,
                    'keymessage_name': 'Guidelines'
                }, {
                    'is_deleted': false,
                    'keymessage_code': 'patient type or identification',
                    'keymessage_id': 123,
                    'keymessage_name': 'Patient Type Or Identification'
                }],
                'allLovTypes': [{'lov_code': 'geo', 'lov_id': 1, 'lov_name': 'Geography'}, {
                    'lov_code': 'vendor',
                    'lov_id': 3,
                    'lov_name': 'Vendor'
                }, {'lov_code': 'offerType', 'lov_id': 4, 'lov_name': 'Offer Type'}, {
                    'lov_code': 'brand',
                    'lov_id': 5,
                    'lov_name': 'Brand'
                }, {'lov_code': 'franchise', 'lov_id': 6, 'lov_name': 'Franchise'}, {
                    'lov_code': 'indication',
                    'lov_id': 7,
                    'lov_name': 'Indication'
                }, {'lov_code': 'message', 'lov_id': 9, 'lov_name': 'Key Message'}],
                'allOffertypes': [{
                    'is_deleted': false,
                    'offer_code': 'Vid',
                    'offer_id': 2,
                    'offer_name': 'Video Link'
                }, {
                    'is_deleted': false,
                    'offer_code': 'KOL',
                    'offer_id': 3,
                    'offer_name': 'Kol Connect'
                }, {
                    'is_deleted': false,
                    'offer_code': 'Bro',
                    'offer_id': 4,
                    'offer_name': 'Brochure/Presentation'
                }, {
                    'is_deleted': false,
                    'offer_code': 'Rep',
                    'offer_id': 5,
                    'offer_name': 'Reprints'
                }, {
                    'is_deleted': false,
                    'offer_code': 'Cop',
                    'offer_id': 6,
                    'offer_name': 'Copay/Saving'
                }, {
                    'is_deleted': false,
                    'offer_code': 'DDG',
                    'offer_id': 1,
                    'offer_name': 'Doctor Discussion Guide'
                }, {
                    'is_deleted': false,
                    'offer_code': 'RAR',
                    'offer_id': 7,
                    'offer_name': 'Request A Rep'
                }, {
                    'is_deleted': false,
                    'offer_code': 'RPR',
                    'offer_id': 8,
                    'offer_name': 'Sample Request Forms/Samples'
                }, {'is_deleted': false, 'offer_code': 'SYT', 'offer_id': 9, 'offer_name': 'Symptom Tracker'}],
                'allRoles': [{'role_id': 1, 'role_name': 'Campaign Manager'}, {
                    'role_id': 2,
                    'role_name': 'Admin User'
                }, {'role_id': 3, 'role_name': 'Creative User'}],
                'allVendors': [{
                    'is_deleted': false,
                    'vendor_code': 'ADM',
                    'vendor_id': 1,
                    'vendor_name': 'Admission'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'ALE',
                    'vendor_id': 2,
                    'vendor_name': 'Alert Marketing'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'ALL',
                    'vendor_id': 3,
                    'vendor_name': 'Allmedx'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'BIO',
                    'vendor_id': 4,
                    'vendor_name': 'Biopharm'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'BUL',
                    'vendor_id': 5,
                    'vendor_name': 'Bulletin Healthcare'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'CMI',
                    'vendor_id': 6,
                    'vendor_name': 'Cmi'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'DIS',
                    'vendor_id': 7,
                    'vendor_name': 'Display'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'DOX',
                    'vendor_id': 8,
                    'vendor_name': 'Doximity'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'EPO',
                    'vendor_id': 9,
                    'vendor_name': 'Epocrates'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'EVE',
                    'vendor_id': 10,
                    'vendor_name': 'Everydayhealth'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'FRO',
                    'vendor_id': 11,
                    'vendor_name': 'Frontline'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'HAY',
                    'vendor_id': 12,
                    'vendor_name': 'Haymarket'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'HEA',
                    'vendor_id': 13,
                    'vendor_name': 'Healio'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'HEA',
                    'vendor_id': 14,
                    'vendor_name': 'Healthcasts'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'HIB',
                    'vendor_id': 15,
                    'vendor_name': 'Hibbert'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'HMP',
                    'vendor_id': 16,
                    'vendor_name': 'Hmp'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'INT',
                    'vendor_id': 17,
                    'vendor_name': 'Intellisphere'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'JOU',
                    'vendor_id': 18,
                    'vendor_name': 'Journal'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'LEA',
                    'vendor_id': 19,
                    'vendor_name': 'Leap'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'LIL',
                    'vendor_id': 20,
                    'vendor_name': 'Lilly'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'MDL',
                    'vendor_id': 21,
                    'vendor_name': 'Mdlinx'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'MED',
                    'vendor_id': 22,
                    'vendor_name': 'Medpage Today'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'MED',
                    'vendor_id': 23,
                    'vendor_name': 'Medscape'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'NUM',
                    'vendor_id': 24,
                    'vendor_name': 'Numedis'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'OMD',
                    'vendor_id': 25,
                    'vendor_name': 'Omd'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'ONC',
                    'vendor_id': 26,
                    'vendor_name': 'Onclive'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'OPT',
                    'vendor_id': 27,
                    'vendor_name': 'Optimizerx'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'OPU',
                    'vendor_id': 28,
                    'vendor_name': 'Opus'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'PAT',
                    'vendor_id': 29,
                    'vendor_name': 'Patientpoint'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'PDR',
                    'vendor_id': 30,
                    'vendor_name': 'Pdr'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'PEE',
                    'vendor_id': 31,
                    'vendor_name': 'Peerdirect'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'PHA',
                    'vendor_id': 32,
                    'vendor_name': 'Pharma Connect'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'PHY',
                    'vendor_id': 33,
                    'vendor_name': 'Physiciansweekly'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'POI',
                    'vendor_id': 34,
                    'vendor_name': 'Point Of Care Network'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'REA',
                    'vendor_id': 35,
                    'vendor_name': 'Reachmd'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'SAM',
                    'vendor_id': 36,
                    'vendor_name': 'Samplemd'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'TLA',
                    'vendor_id': 37,
                    'vendor_name': 'Tlac'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'TRI',
                    'vendor_id': 38,
                    'vendor_name': 'Triplefin'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'UBM',
                    'vendor_id': 39,
                    'vendor_name': 'Ubm'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'UNK',
                    'vendor_id': 40,
                    'vendor_name': 'Unknown'
                }, {
                    'is_deleted': false,
                    'vendor_code': 'VEE',
                    'vendor_id': 41,
                    'vendor_name': 'Veeva'
                }, {'is_deleted': false, 'vendor_code': 'VUM', 'vendor_id': 42, 'vendor_name': 'Vumedi'}]
            });
            localStorage.setItem('lovs', lovString);
            localStorage.setItem('isViewOnly', 'false');
        }
    }

}
