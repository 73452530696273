import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Subject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private fetchAPIData: HttpService,
    ) {
    }

    userInfo;
    
    getUserInfo() {
        this.fetchAPIData.get('getUserInfo').subscribe((data) => {
            if (data) {
                this.userInfo = data;
                this.userInfo['data']['expiration'] = new Date();

                localStorage.setItem('userdata', JSON.stringify(this.userInfo['data']));
                
                } 
        }, (error: any) => {
            console.log('error part');
        });
    }

}