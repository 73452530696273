import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgSelectModule } from '@ng-select/ng-select';
import { NewLovRequestModule } from './new-lov-request/new-lov-request.module';

import { MyDatePickerModule } from 'mydatepicker';

import { EditLovRequestComponent } from './edit-lov-request/edit-lov-request.component';
import { EditUserRequestComponent } from './edit-user-request/edit-user-request.component';
import { NewRequestMenuComponent } from './new-request-menu/new-request-menu.component';
import { NewUserRequestComponent } from './new-user-request/new-user-request.component';
import { RestoreDeletedRequestComponent } from './restore-deleted-request/restore-deleted-request.component';

import { UserFormComponent } from './user-form/user-form.component';
import { NewMiscRequestComponent } from './new-misc-request/new-misc-request.component';
import { MiscRequestFormComponent } from './misc-request-form/misc-request-form.component';
import { BulkExportComponent } from './bulk-export/bulk-export.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { EditMultipleLovComponent } from './edit-multiple-lov/edit-multiple-lov.component';

const components = [
    EditLovRequestComponent,
    EditUserRequestComponent,
    NewRequestMenuComponent,
    NewUserRequestComponent,
    RestoreDeletedRequestComponent,
    NewMiscRequestComponent,
    BulkExportComponent
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        OverlayModule,
        NgSelectModule,
        NewLovRequestModule,
        MyDatePickerModule,
        PerfectScrollbarModule

    ],
    declarations: [
        components,
        UserFormComponent,
        NewMiscRequestComponent,
        MiscRequestFormComponent,
        BulkExportComponent,
        EditMultipleLovComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
    entryComponents: components,
    exports: components
})
export class NewRequestModule { }
