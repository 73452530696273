export interface Brand {
    brand_id: number;
    brand_code: string;
    brand_name: string;
}

export interface Role {
    role_id: number;
    role_name: string;
}

export interface Franchise {
    gbu_id: number;
    gbu_code: string;
    gbu_name: string;
}

export interface Geography {
    geo_id: number;
    geo_code: string;
    geo_name: string;
}

export interface Indication {
    indication_id: number;
    indication_code: string;
    indication_name: string;
}

export interface KeyMessage {
    keymessage_id: number;
    keymessage_code: string;
    keymessage_name: string;
}

export interface LovType {
    lov_id: number;
    lov_code: string;
    lov_name: string;
}

export interface OfferType {
    offer_id: number;
    offer_code: string;
    offer_name: string;
}

export interface Vendor {
    vendor_id: number;
    vendor_code: string;
    vendor_name: string;
}

export interface Lovs {
    allBrands: Brand[];
    allFranchises: Franchise[];
    allGeo: Geography[];
    allIndications: Indication[];
    allKeymessages: KeyMessage[];
    allLovTypes: LovType[];
    allOffertypes: OfferType[];
    allVendors: Vendor[];
    allRoles: Role[];
}
