<!-- The Modal -->
<div class="modal fade" style="z-index: 99999;" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span aria-hidden="true" aria-label="Close" class="close" data-dismiss="modal" (click)="ok()">
                            <img class="cross" src="assets/icons/shared/close2.svg">
                        </span>
                <!-- <header>{{message}}</header> -->
                <header>This tactic request cannot be implemented because it is beyond the expiration date</header>
                <br/>
                <p></p>
                <p><b>Expiration Date:</b>{{message.EndDate}}</p>
                <br/>
                <p><b>Current Deployment Date:</b>{{message.StartDate}}</p>
                <br/>
                <p><b>Requested Update Date:</b>{{message.NewStartDate}}</p>
            </div>
            <div class="modal-footer">
                <p (click)="ok()"><a>Cancel</a></p>
                <button data-dismiss="modal" class="btn btn-primary" (click)="ok()">OK</button>
            </div>
        </div>
    </div>
</div>