import {NgModule ,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ContenteditableModelDirective} from './directives/contenteditable';
import {FlexBoxModelDirective} from './directives/flexbox';
import {FormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import {SideMenuComponent} from './components/header/side-menu/side-menu.component';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {SharedStore} from './services/shared-store';
import { UserService } from './services/user.service'
import {GlobalStateVariables} from './services/globalstate.service';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {ViewProfileComponent} from './components/view-profile/view-profile.component';
import {AdvanceSearchComponent} from './components/advance-search/advance-search.component';
import {AppliedFilterTagComponent} from './components/applied-filter-tag/applied-filter-tag.component';

import {ContactComponent} from './components/contact/contact.component';
import {TrainingComponent} from './components/training/training.component';
import {HelpComponent} from './components/help/help.component';
import {SaveModalComponent} from './components/save-modal/save-modal.component';
import {CommonModalComponent} from './components/common-modal/common-modal.component';

import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';

import {RouterModule} from '@angular/router';
import {NewRequestModule, NewRequestMenuComponent} from './components/new-request';
import {NavigatorComponent} from './components/navigator/navigator.component';

import {HelpDialogComponent} from './components/help-dialog/help-dialog.component';
import {CommonAlertBoxComponent} from './components/common-alert-box/common-alert-box.component';

import {NgSelectModule} from '@ng-select/ng-select';
import {MyDatePickerModule} from 'mydatepicker';
import {TooltipPopoverComponent} from './components/tooltip-popover/tooltip-popover.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {WarningPopupComponent} from './components/warning-popup/warning-popup.component';

@NgModule({
    declarations: [
        ContenteditableModelDirective,
        FlexBoxModelDirective,
        HeaderComponent,
        SideMenuComponent,
        DeleteModalComponent,
        ViewProfileComponent,
        AdvanceSearchComponent,
        AppliedFilterTagComponent,
        ContactComponent,
        BreadcrumbComponent,
        HelpDialogComponent,
        TrainingComponent,
        HelpComponent,
        SaveModalComponent,
        CommonModalComponent,
        NavigatorComponent,
        CommonAlertBoxComponent,
        TooltipPopoverComponent,
        PaginationComponent,
        WarningPopupComponent
    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        HttpClientModule,
        FormsModule,
        MyDatePickerModule,
        NewRequestModule,
        RouterModule,
        NgSelectModule
    ],
    exports: [
        ContenteditableModelDirective,
        FlexBoxModelDirective,
        FormsModule,
        HeaderComponent,
        ViewProfileComponent,
        DeleteModalComponent,
        BreadcrumbComponent,
        AdvanceSearchComponent,
        AppliedFilterTagComponent,
        SaveModalComponent,
        CommonModalComponent,
        NavigatorComponent,
        PaginationComponent,
        CommonAlertBoxComponent,
        TooltipPopoverComponent,
        WarningPopupComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    entryComponents: [
        HelpDialogComponent
    ],
    providers: [
        SharedStore,
        GlobalStateVariables,
        UserService
    ]
})
export class SharedModule {
}
