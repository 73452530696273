<!-- Confirmation Box -->
<div class="modal fade" style="z-index: 99999;" *ngIf="show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <header>Are you sure you want to delete the {{deletetype}}?</header>
            </div>
            <div class="modal-footer">
                <button (click)="setAffirmation(true)">Proceed</button>
                <button (click)="setAffirmation(false)">Cancel</button>
            </div>
        </div>
    </div>
</div>