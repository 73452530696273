import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], searchText: string): any[] {

        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter((it) => this.applyFilter(it, searchText));
    }

    applyFilter(it, filter) {

        let date = it['modified_date'];
        let day;
        let month;
        let year;
        date = new Date(date);
        day = date.getDate();
        month = date.getMonth();
        month += 1;
        year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        date = month + '/' + day + '/' + year;
        if ( filter.toLowerCase() === 'journey' || filter.toLowerCase() === 'campaign') {
            if (((it['name'] || '').toString().toLowerCase()).includes('campaign')) {
                return true;
            } else if (((it['description'] || '').toString().toLowerCase()).includes('campaign')) {
                return true;
            } else if (((it['geo_name'] || '').toString().toLowerCase()).includes('campaign')
                || ((it['geo_code'] || '').toString().toLowerCase()).includes('campaign')) {
                return true;
            } else if (((it['type'] || '').toString().toLowerCase()).includes('campaign')) {
                return true;
            } else if (date.indexOf(filter) !== -1) {
                return true;
            } else {
                return false;
            }
        } else {
            if (((it['name'] || '').toString().toLowerCase()).includes(filter.toLowerCase())) {
                return true;
            } else if (((it['description'] || '').toString().toLowerCase()).includes(filter.toLowerCase())) {
                return true;
            } else if (((it['geo_name'] || '').toString().toLowerCase()).includes(filter.toLowerCase())
                || ((it['geo_code'] || '').toString().toLowerCase()).includes(filter.toLowerCase())) {
                return true;
            } else if (((it['type'] || '').toString().toLowerCase()).includes(filter.toLowerCase())) {
                return true;
            } else if (((it['execution_path'] || '').toString().toLowerCase()).includes(filter.toLowerCase())) {
                return true;
            } else if (date.indexOf(filter) !== -1) {
                return true;
            } else {
                return false;
            }
        }
    }
}
