export const StringConstants = {
    'MANAGER_ROLE': 1,
    'ADMIN_ROLE': 2,
    'CREATIVE_ROLE': 3,
    'UPDATE_USER_MESSAGE': 'user updated successfully!',
    'APPROVE': 'approved',
    'REJECT': 'rejected'
};
export const errorMessages = {
    'InputLimit': 'Please use 500 characters only',
    'TextAreaLimit': 'Please use  500 characters only',
    'ClearErrors': 'There are some errors, please fix them before continuing.'
};

export const ColumnList = ['Program', 'Geography', 'Business Unit', 'Program Description', 'Program Objective', 'Department',
    'Program Start Date', 'Program End Date', 'Program Code', 'Campaign', 'Campaign Code',
    'Campaign Description', 'Campaign Goal', 'Campaign Start Date', 'Campaign End Date', 'Execution Path',
    'Product', 'Indication/Disease State', 'Vendor',
    'Customer Type',  'Mode of Delivery',
    'Tactic Description', 'Data Source Code', 'Delivery Source', 'Contact Type', 'Delivery Source Type',
    'Platform', 'Approved Job Id', 'AEM Tactic ID',
    'Expiration Date', 'Retirement Date', 'Document Status', 'Primary Category', 'Primary Subcategory',
    'Secondary Category', 'Secondary Subcategory', 'Tactic Resend', 'Content Testing',
    'At What Level', 'Vendor Tactic Flag', 'Subject Line', 'Pre Header', 'Population',
    'Tactic Id', 'Vendor Tactic Id', 'Tactic Start Date', 'Tactic End Date', 'Customer Segments',
    'Tactic Status', 'Offer', 'Link', 'Link Location', 'Content Tag', 'UTM Tag','Group','Send Type'];

export const MandatoryColumns = ['Program', 'Geography', 'Franchise', 'Campaign', 'Customer Type',
    'Vendor', 'Brand', 'Indication', 'Customer Segment', 'Customer Specialty', 'Tactic Type', 'Channel', 'Channel Category',
    'Approved Job Id', 'AEM Tactic ID', 'Document Status', 'Primary Category', 'Primary Subcategory', 'Secondary Category',
    'Secondary Subcategory', 'Subject Line', 'Exposure Code', 'Tactic Start Date',
    'Tactic End Date', 'Offer','Group','Send Type'
];
