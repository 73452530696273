import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    userInfo: any;
    waveSnapshot: any;
    tacticSnapshot: any;

    constructor() {
    }

    // function to set local storage
    setStorage(key: string, value: string | number | boolean | any[] | {}) {
        localStorage.setItem(key,JSON.stringify(value));
    }

    // function to get local storage
    getStorage(key: string) {
        return JSON.parse(localStorage.getItem(key));
    }
}
