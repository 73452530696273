import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as PptxGenJS from 'pptxgenjs-angular';
import {Injectable} from '@angular/core';

// declare let canvg: any;
const pptx = new PptxGenJS();
const slide = pptx.addNewSlide();

@Injectable({providedIn: 'root'})
export class CommonMethods {

    /** download pdf */
    downloadPdf() {
        try {

            const elem = document.getElementById('month-view-snap-days');
            html2canvas(elem, {logging: true, useCORS: true}).then((_canvas) => {
                // Few necessary setting options
                const imgWidth = 208;
                const imgHeight = _canvas.height * imgWidth / _canvas.width;
                const contentDataURL = _canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
                const pdf = new jspdf('p', 'mm', 'a4', true); // A4 size page of PD
                const position = 0;

                /** saving to pdf  */
                pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight);
                pdf.save('Calendar View.pdf'); // Generated PDF
            });
        } catch (error) {
            console.log(error);
        }
    }

    /** download ppt  */
    downloadPPT() {
        try {
            const elem = document.getElementById('month-view-snap-days');
            html2canvas(elem, {allowTaint: false}).then((canvas) => {
                // const imgWidth = 208;
                // const imgHeight = canvas.height * imgWidth / canvas.width;
                const contentDataURL = canvas.toDataURL('image/png', 1.0);

                /** saving in ppt form  */
                slide.addText('Calendar View ', {x: 0, y: 0, w: '100%', h: 1, align: 'c', color: '0088CC', fill: 'F1F1F1', fontSize: 24});
                slide.addImage({data: contentDataURL, x: 0, y: 1, w: 10.0, h: 5.0});
                pptx.save('Demo-Images');
            });
        } catch (error) {
            console.log(error);
        }
    }

    /** format date in different formats
     * @param type - format type
     * @param inputDate - date to be formatted
     */
    formatDate(type, inputDate) {
        try {
            const date = new Date(inputDate);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let mn = month + '', dt = date.getDate() + '';
            month < 10 ? mn = '0' + month : '';
            day < 10 ? dt = '0' + day : '';
            if (type === 'mm-dd-yyyy') {
                return mn + '-' + dt + '-' + date.getFullYear();
            }
            if (type === 'dd-mm-yyyy') {
                return dt + '-' + mn + '-' + date.getFullYear();
            }
            if (type === 'yyyy-mm-dd') {
                return date.getFullYear() + '-' + mn + '-' + dt;
            }
            if (type === 'dd-mon-yyyy') {
                const mlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                return day + ' ' + mlist[month - 1] + ' ' + date.getFullYear();
            }
        } catch (error) {
            console.log(error);
        }
    }

}
