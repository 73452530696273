import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { NewLovRequestComponent, FormHostDirective } from './new-lov-request.component';
import { FbiLovRequestComponent } from './fbi-lov-request/fbi-lov-request.component';
import { KeyMessageLovRequestComponent } from './key-message-lov-request/key-message-lov-request.component';
import { SingleLovRequestComponent } from './single-lov-request/single-lov-request.component';
import { BiLovRequestComponent } from './bi-lov-request/bi-lov-request.component';

const components = [
    NewLovRequestComponent,
    FbiLovRequestComponent,
    KeyMessageLovRequestComponent,
    SingleLovRequestComponent,
    BiLovRequestComponent
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
    ],
    declarations: [
        components,
        FormHostDirective,
        BiLovRequestComponent,
    ],
    exports: [
        components,
        FormHostDirective,
    ],
    entryComponents: components
})
export class NewLovRequestModule { }
