<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document"    >
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-container">
                    <header>
                        <img class="icon" src="assets/icons/manage-requests/Icon-EditLOV-White-v1.0.svg" alt="Edit LOV">
                        <div>
                            <h1>Request LOV Edit</h1>
                            <img class="btn-close" src="assets/icons/shared/close.svg" alt="close" (click)="close()">
                        </div>
                    </header>

                    <main [ngStyle]="lovTypeControl.value ? {'height': '90vh'} : {'height': '380px'}">
                       
                        <div class="col-12 row">
                            <ng-select  style="width: 60% !important" [items]="lovTypes" class="ng-select custom mt-1" placeholder="Select LOV Type" [bindLabel]="'lov_name'" [searchable]="true" [formControl]="lovTypeControl">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <span title="{{item.lov_name}}">{{item.lov_name}}</span>
                                </ng-template>
                            </ng-select>
                            <p class="response-message"  style="margin-top : 8px" [class.success-message]="isRequestSucccess" *ngIf="message">
                                {{message}}</p>

                        </div>
                        <div *ngIf="lovTypeControl.value && lovTypeControl.value.is_mapping">
                           
                            <form [formGroup]="multipleMappingForm">
                                <p>Old LOVs</p>
                                <div  style="display:flex;flex-wrap: wrap;margin: 0 20px;">
                                    <div *ngFor="let item of lovMultipleMapping;let i = index" style="width: 25%;margin-top:5px">
                                        <label>{{ item.name | titlecase}}:</label>
                                        <ng-select [placeholder]="item.name" [items]="item.mapping" [selectOnTab]="true" [bindLabel]="item.lovLabel" (change)="cascadeMultipleLov($event, i)"
                                             [formControlName]="item.name"></ng-select>
                                        <ng-container
                                            *ngIf="multipleMappingForm.get(item.name).invalid && (multipleMappingForm.get(item.name).dirty || 
                                            multipleMappingForm.get(item.name).touched) && !message">
                                            <span class="form-error-message" *ngIf="multipleMappingForm.get(item.name).errors.required">This field is
                                                required.</span>
                                        </ng-container>
                                    </div>
                                </div>
                                  
                                <p>New LOVs</p>
                                <div  style="display:flex;flex-wrap: wrap;margin: 0 20px;">
                                    
                                    <div *ngFor="let item of lovUpdateMapping;let i = index" style="width: 25%;margin-top:5px">
                                        <label>{{ item.placeholder | titlecase}}:</label>
                                        <ng-select [placeholder]="item.placeholder" [items]="item.mapping" [selectOnTab]="true" [bindLabel]="item.lovLabel" 
                                             [formControlName]="item.name"></ng-select>
                                        <ng-container
                                            *ngIf="multipleMappingForm.get(item.name).invalid && (multipleMappingForm.get(item.name).dirty || 
                                            multipleMappingForm.get(item.name).touched) && !message">
                                            <span class="form-error-message" *ngIf="multipleMappingForm.get(item.name).errors.required">This field is
                                                required.</span>
                                        </ng-container>
                                    </div>
                                </div>
                               
                            </form>
                        </div>

                        <form [formGroup]="form" *ngIf="lovTypeControl.value && !lovTypeControl.value.is_mapping" class="m-0" style="min-height: 260px;
                        overflow: auto;">
                            <ng-container formArrayName="items"
                                *ngFor="let item of items.controls; let i=index; let isFirst=first; let isLast=last">
                                <div [formGroupName]="i">
                                    <div class="form-field-container">
                                        <label *ngIf="isFirst">Select LOV:</label>
                                        <ng-select [items]="lovMapping"
                                            [placeholder]="lovTypeControl.value.lov_name | titlecase"
                                            [bindLabel]="lovLabel" formControlName="currentValue"></ng-select>
                                        <ng-container
                                            *ngIf="getControl(i, 'currentValue').invalid && (getControl(i, 'currentValue').dirty || getControl(i, 'currentValue').touched)">
                                            <span class="form-error-message b-block"
                                                *ngIf="getControl(i, 'currentValue').errors.required">This field is
                                                required.</span>
                                        </ng-container>
                                    </div>

                                    <div class="form-field-container ad-form-field">
                                        <label *ngIf="isFirst">New Value:</label>
                                        <input type="text" placeholder="New Value" formControlName="newValue"
                                            style="margin-bottom:12px;width:90%">
                                        <ng-container
                                            *ngIf="getControl(i, 'newValue').invalid && (getControl(i, 'newValue').dirty || getControl(i, 'newValue').touched)">
                                            <span class="form-error-message"
                                                *ngIf="getControl(i, 'newValue').errors.required">This field is
                                                required.</span>
                                            <span class="form-error-message"
                                                *ngIf="!getControl(i, 'newValue').errors.required && getControl(i, 'newValue').errors.emptyName">This
                                                field can't be empty</span>
                                        </ng-container>
                                    </div>

                                    <div class="actions  d-inline-block" [style.margin-top]="isFirst ? '15px': '0px'">
                                        <img src="assets/icons/campaign/add_row.svg" (click)="addItem(i)">
                                        <img src="assets/icons/campaign/delete_row.svg" (click)="removeItem(i)"
                                            [style.visibility]="isFirst && items.controls.length == 1 ? 'hidden' : 'visible'">
                                    </div>
                                </div>
                            </ng-container>
                        </form>

                        <div class="col-12 row">
                            <button type="button" (click)="sendUpdateRequest()">Update
                                LOV</button>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>