<div appFlex class="orangeborder"></div>
<div class="container-fluid">
    <div appFlex [justify]="'space-between'" class="header row">
        <div appFlex class="header-left col-lg-3 col-md-4 col-sm-3">
            <!--<div class="menu" (click)="openModal()">-->
            <!--<img src="assets/icons/header/menu.svg" height="20" width="20">-->
            <!--</div>-->

            <div class="zslogo">
                <img src="assets/icons/header/lilly-logo.png" [routerLink]="['/home']" class="logoSize">
            </div>

            <div class="title">
                <!-- <header *ngIf="router.url.includes('cmaps')" class="titleName2">Content Tagging</header> -->
                <header *ngIf="router.url.includes('calendar-view')" class="titleName1">Campaign Calendar</header>
                <header *ngIf="router.url.includes('metadata-view') || router.url.includes('user') || router.url.includes('manage-requests') && !router.url.includes('calendar-view')" class="titleName1">
                    Campaign Metadata Management Portal
                </header>
            </div>
        </div>

        <div appFlex class="header-right col-lg-9 col-md-8 col-sm-8 row" [justify]="'flex-end'">
            <!-- <p class="search-error" *ngIf="searchError">{{searchError}}</p> -->
            <div class="header-right-item pb-1" style="width: 280px" *ngIf="router.url.includes('metadata-view')">
                <div appFlex class="input-container">
                    <input type="search" (keyup.enter)="onSearch()" (change)="onClearSearch()" placeholder="Search ..." [(ngModel)]="searchString">
                    <div class="search-icon" appFlex [justify]="'center'" [align]="'center'">
                        <img src="assets/icons/header/search.svg" (click)="onSearch();$event.stopPropagation()">
                    </div>
                </div>
                <div class="text col-12 py-1">

                    <span (click)="openAdvanceSearch();flag = 1;" class="advanced-search">Advanced Search</span>
                </div>
            </div>
            <!--<div class="header-right-item">-->
            <!--<img src="assets/icons/header/notifications.svg">-->
            <!--</div>-->
            <!-- Advance Search -->
            <app-mdp-advance-search *ngIf="showAdvanceSearch" [show]="showAdvanceSearch" (closeEmitter)="closeAdvanceSearch($event)">
            </app-mdp-advance-search>
            <!-- Advance Search Ends -->

            <div class="profileWrap">
                <div #toggleButton class="profileContainer"  [ngClass]="{'menuBorder' : showUserProfile}"  (click)="$event.stopPropagation();showProfile();closeHelp()">
                    <img src="assets/icons/header/profile_man.svg" width="20" height="25">
                    <header class="userName">{{userName}}</header>
                </div>
                <div #userProfile class="profileMenu" *ngIf="showUserProfile">
                    <div class="menuItem"  (click)="navigate('/user/view-profile')">View Profile</div>
                    <div class="menuItem"  (click)="logOut()">Logout</div>
                </div>
            </div>
            <!-- *ngIf="userinfo && userinfo['allRolesId'][0] !== 2" -->
            <div  class="profileWrap" style="min-width: 180px;" >
                <div  class="profileContainer" [ngClass]="{'menuBorder' : showMenu}" (click)="$event.stopPropagation();showJourneyMenu();closeHelp()">
                    <img src="assets/icons/header/menu.svg" width="20" height="20">
                    <header >VERSO CAP</header>
                </div>
                <div  class="profileMenu" style="width: 180px;" *ngIf="showMenu">
                    <!-- dropdown of the enabled tile -->
                    <div class="menuItem" *ngIf="isAdmin" [ngClass]="{'selected-dropdown-item': router.url.includes('/manage-requests')}" (click)="navigate('/manage-requests/home')" appFlex>Admin Dashboard
                    </div>

                    <div class="menuItem"  [ngClass]="{'selected-dropdown-item': router.url.includes('/calendar-view')}" (click)="navigate('/calendar-view/tactics/view/month')" appFlex>Campaign Calendar
                    </div>
                    <div style="word-break:break-all;height: 33px;" class="menuItem" [ngClass]="{'selected-dropdown-item': router.url.includes('/metadata-view/')}" (click)="navigate('/metadata-view/activity/home')" appFlex>Campaign Metadata <br> Management Portal</div>

                    <!-- Hiding Campaign Designer from menu dropdown -->
                    <!-- <div class="dropdown-item" appFlex style="opacity: 0.3">Campaign Designer</div> -->

                    <!-- <div class="dropdown-item" [ngClass]="{'selected-dropdown-item': router.url.includes('/cmaps/'), 'disabled': !loadCmaps}" (click)="navigate('/cmaps/content-search')" appFlex>Content Tagging</div> -->
                </div>
            </div>
            <div class="header-menu-button col-1 col-md-1 col-sm-1 padd" appFlex (click)="openHelpDialog()">
                <img src="assets/icons/header/help.svg">
                <header>Help</header>
            </div>
        </div>
    </div>
</div>

<!-- Side Menu -->
<app-side-menu *ngIf="showSideMenu" [showSideMenu]="showSideMenu" [userName]="userName" (closeEmitter)="closeSideMenu($flag)">
</app-side-menu>
