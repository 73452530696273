import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpSentEvent,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError, EMPTY } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor() {}

  /**
   * Method for customize request.
   * @param request http request
   */
  addCommonHttpOptions(request): HttpRequest<any> {
    try {
      const req = request.clone({});
      req.withCredentials = true;
      return req;
    } catch (error) {
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    const custom_request = this.addCommonHttpOptions(req);
    return next.handle(custom_request).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.status === 401) {
          if (environment.production) {
            const url = "https%3A%2F%2Fverso-lilly.dev.zsservices.com%2F%23%2Fhome";   // Dev
            window.location.href = environment.authLoginUrl + url;
          }
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }
}
