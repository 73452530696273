import { Component, OnInit , Output , EventEmitter , Input} from '@angular/core';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.sass']
})
export class WarningPopupComponent implements OnInit {
  @Output()  warningFlag = new EventEmitter();
  @Input() showWarningPopup ;
   setWarning(flag) {
        this.warningFlag.emit(flag);
        this.showWarningPopup = false;
    }
  constructor() { }

  ngOnInit() {
  }

}
