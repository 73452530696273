<form [formGroup]="form" >
    <div class="form-row row">
        <div class="form-field-container col-3">
            <label for="">First Name:</label>
            <input type="text" placeholder="First Name" formControlName="firstName">
            <ng-container *ngIf="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)">
                <span class="form-error-message" *ngIf="form.get('firstName').errors.required">This field is required.</span>
                <span class="form-error-message" *ngIf="!form.get('firstName').errors.required && (form.get('firstName').errors.emptyName || form.get('firstName').errors.pattern)">Invalid Name</span>
            </ng-container>
        </div>
        <div class="col-1">

        </div>
        <div class="form-field-container col-3">
            <label for="">Last Name:</label>
            <input type="text" placeholder="Last Name" formControlName="lastName">
            <ng-container *ngIf="form.get('lastName').invalid && (form.get('lastName').dirty || form.get('lastName').touched)">
                <span class="form-error-message" *ngIf="form.get('lastName').errors.required">This field is required.</span>
                <span class="form-error-message" *ngIf="!form.get('lastName').errors.required && (form.get('lastName').errors.emptyName || form.get('lastName').errors.pattern)">Invalid Name</span>
            </ng-container>
        </div>
        <div class="col-1">

        </div>
        <div class="form-field-container col-4">
            <label for="">Email:</label>
            <input type="email" placeholder="Email" formControlName="email">
            <ng-container *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)">
                <span class="form-error-message" *ngIf="form.get('email').errors.required">This field is required.</span>
                <span class="form-error-message" *ngIf="!form.get('email').errors.required && form.get('email').errors.email">Invalid email.</span>
            </ng-container>
        </div>
    </div>

    <div class="form-row row">
        <div class="form-field-container col-12">
            <label for="">Role:</label>
            <ng-select [items]="roles" placeholder="Select Role" class="custom" [multiple]="false"  bindLabel="role_name" bindValue="role_id" [searchable]="false" formControlName="role" [closeOnSelect]="false" [ngClass]="{'selected-val' : (isCampaignManager && isContentLibrarian)}" (change)="roleChanged($event)"></ng-select>
            <ng-container *ngIf="form.get('role').invalid && (form.get('role').dirty || form.get('role').touched)">
                <span class="form-error-message" *ngIf="form.get('role').errors.required">This field is required.</span>
            </ng-container>
        </div>

        <!-- To be enabled for Creative User only -->
        <!-- <div class="col-1">

        </div>
        <div class="form-field-container col-5">
            <label for="">Vendor:</label>
            <ng-select [items]="vendors" placeholder="Select Vendor" appendTo="body" bindLabel="vendor_name" bindValue="vendor_id" multiple="true" maxSelectedItems="1" formControlName="vendors"></ng-select>
            <ng-container *ngIf="form.get('vendors').invalid && (form.get('vendors').dirty || form.get('vendors').touched)">
                <span class="form-error-message" *ngIf="form.get('vendors').errors.required">This field is required.</span>
            </ng-container>
        </div> -->
    </div>

    <div class="form-row row">
        <div class="form-field-container col-12">
            <label>Geographies:</label>
            <ng-select [items]="geographies" groupBy="lov_typecd" [selectableGroup]="true" [selectableGroupAsModel]="false" labelForId="geography" placeholder="Select Geography" bindLabel="geo_name" bindValue="geo_id" multiple="true" [closeOnSelect]="false" class="custom" formControlName="geographies" [ngClass]="{'selected-val' : geoAsPerRole}" (change)="geoChanged()">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <span >All</span>
                </ng-template>
            </ng-select>
            <ng-container *ngIf="form.get('geographies').invalid && (form.get('geographies').dirty || form.get('geographies').touched)">
                <span class="form-error-message" *ngIf="form.get('geographies').errors.required">This field is required.</span>
            </ng-container>
            <ng-container *ngIf="(isCampaignManager || isContentLibrarian) && form.get('geographies').touched && !geoAsPerRole">
                <span class="form-error-message">
                    Atleast one geography is required for each selected role
                </span>
            </ng-container>
        </div>
    </div>
    <!-- <div class="form-row row">
        <div class="form-field-container col-12">
            <label>Franchise:</label>
            <ng-select [items]="franchises" placeholder="Select Franchise" appendTo="body" bindLabel="gbu_name" bindValue="gbu_id" multiple="true" maxSelectedItems="1" formControlName="franchises"></ng-select>
            <ng-container *ngIf="form.get('franchises').invalid && (form.get('franchises').dirty || form.get('franchises').touched)">
                <span class="form-error-message" *ngIf="form.get('franchises').errors.required">This field is required.</span>
            </ng-container>
        </div>
    </div> -->

    <div class="form-row row">
        <div class="form-field-container col-12">
            <label>Products:</label>
            <!-- [ngClass]="{'selected-val' : brandAsPerRole}" -->
            <ng-select [items]="brands" class="selected-val custom" groupBy="lov_typecd" [selectableGroup]="true" [selectableGroupAsModel]="false" placeholder="Select Product" bindLabel="brand_name" bindValue="brand_id" multiple="true"
                       [closeOnSelect]="false" formControlName="brands"  (change)="brandChanged()">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <span >All</span>
                    </ng-template>
            </ng-select>
            <ng-container *ngIf="form.get('brands').invalid && (form.get('brands').dirty || form.get('brands').touched)">
                <span class="form-error-message" *ngIf="form.get('brands').errors.required">This field is required.</span>
            </ng-container>
            <ng-container *ngIf="(isCampaignManager || isContentLibrarian) && form.get('brands').touched && !brandAsPerRole">
                <span class="form-error-message">
                    Atleast one brand is required for each selected role
                </span>
            </ng-container>
        </div>
    </div>

    <div class="form-row row">
        <div class="form-field-container col-12">
            <label>Indication/Disease State:</label>
            <ng-select class="selected-val custom" [items]="indications" groupBy="lov_typecd" [selectableGroup]="true" [selectableGroupAsModel]="false" [placeholder]="'Select Indication/Disease State'" [bindLabel]="'indication_name'" [bindValue]="'indication_id'"
                       [multiple]="true" [closeOnSelect]="false" [formControlName]="'indications'">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <span >All</span>
                </ng-template>        
            </ng-select>
            <ng-container *ngIf="form.get('indications').invalid && (form.get('indications').dirty || form.get('indications').touched)">
                <span class="form-error-message" *ngIf="form.get('indications').errors.required">This field is required.</span>
            </ng-container>
        </div>
    </div>
</form>
